<div class="dashboard_content_wrap">
  <div class="dashboard_content Properties_AddDashFix">
    <div class="dash-head">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="page-titles">Properties</h1>
        </div>
        <div class="col-auto">
          <div class="left-head">
            <a [routerLink]="['/properties/add-property']" class="btn-style1"><i class="far fa-plus me-2"></i>Add
              New</a>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-container addStep_From">
      <div class="container">
        <div class="theme-card p-0">
          <div class="theme-table">
            <div class="table-header">
              <div class="row">
                <div class="col-12 col-md-auto">
                  <div class="table-search">
                    <i class="far fa-search"></i>
                    <input placeholder="Search here..." type="text" name="" id=""
                      (keyup)="onFilterByKeyword($event.target.value)">
                  </div>
                </div>
                <div class="col-auto ms-auto">
                  <div class="tablefilter-btn">

                    <a class="btn btnicon ms-2" [routerLink]="['/properties/user-columns']">
                      <i class="fas fa-plus"></i>
                      Column
                    </a>
                    <!-- Filter Dropdown -->
                    <!-- <div class="dropdown ms-2">
                      <button class="btn btnicon dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-filter"></i>
                        Filter
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                      </ul>
                    </div> -->
                    <!-- Export Button -->
                    <!-- <button class="btn btnicon ms-2">
                      <i class="fas fa-cloud-download"></i>
                      Export
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th *ngFor="let singleRecord of selectedColumnsValues; let i = index"
                      (click)="sortDataByField(selectedColumnsValues[i], currentOrderType)">

                      {{selectedColumnsValues[i].replace('_',' ').replace('_',' ') | titlecase}}

                      <i class="fas fa-sort-desc" aria-hidden="true"
                        *ngIf="currentOrderType == 'DESC' && selectedColumnsValues[i] == currentOrderField"></i>
                      <i class="fas fa-sort-asc" aria-hidden="true"
                        *ngIf="currentOrderType == 'ASC' && selectedColumnsValues[i] == currentOrderField"></i>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <!-- <thead>
                  <tr>
                    <th>City</th>
                    <th>Sale Price</th>
                    <th>Doors</th>
                    <th>Deal Type</th>
                    <th>Stage</th>
                    <th>Total Investment</th>
                    <th></th>
                  </tr>
                </thead> -->
                <tbody>
                  <tr *ngFor="let singleRecord of allRecords; let i = index">
                    <td *ngFor="let singleRecordD of selectedColumnsValues; let iD = index">
                      {{singleRecord[selectedColumnsValues[iD]]}}
                    </td>
                    <!-- <td>
                      {{singleRecord.city}}
                    </td>
                    <td>
                      {{singleRecord.sale_price}}
                    </td>
                    <td>
                      {{singleRecord.number_of_doors}}
                    </td>
                    <td>
                      {{singleRecord.deal_type_name}}
                    </td>
                    <td>
                      {{singleRecord.stage_name}}
                    </td>
                    <td>
                      {{singleRecord.total_investment}}
                    </td> -->
                    <td>
                      <div class="table-action">
                        <a *ngIf="singleRecord.deal_type_id != 4" class="btn btn-square btn-square-edit"
                          [routerLink]="['/properties/add-property-step1', singleRecord.unique_id]">
                          <i class="fas fa-pencil"></i>
                        </a>
                        <a *ngIf="singleRecord.deal_type_id != 4" class="btn btn-square btn-square-edit"
                          [routerLink]="['/properties/view-property-step1', singleRecord.unique_id]">
                          <i class="fas fa-eye"></i>
                        </a>
                        <a *ngIf="singleRecord.deal_type_id === 4" class="btn btn-square btn-square-edit"
                          [routerLink]="['/properties/wholesale-property-step1', singleRecord.unique_id]">
                          <i class="fas fa-pencil"></i>
                        </a>
                        <a class="btn btn-square btn-square-edit" (click)="openDeletePopup(singleRecord.id)">
                          <i class="fas fa-trash"></i>
                        </a>
                        <a class="btn btn-square btn-square-edit"
                          (click)="openSharePropertyPopup(singleRecord?.unique_id, singleRecord?.propertyName)">
                          <i class="fa fa-share-alt" aria-hidden="true"></i>
                        </a>
                        <!-- <a class="btn btn-square btn-square-edit" (click)="downloadReportPdf(singleRecord.unique_id)">
                          <i class="fas fa-download"></i>
                        </a> -->
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="allRecords?.length == 0">
                    <td colspan="9">
                      <div class="alert alert-danger">
                        <strong>Nothing here!</strong>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item disabled">
                <a class="page-link" href="#!" aria-label="Previous">
                  <span aria-hidden="true"><i class="far fa-angle-left"></i></span>
                </a>
              </li>
              <li class="page-item active"><a class="page-link" href="#!">1</a></li>
              <li class="page-item"><a class="page-link" href="#!">2</a></li>
              <li class="page-item"><a class="page-link" href="#!">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#!" aria-label="Next">
                  <span aria-hidden="true"><i class="far fa-angle-right"></i></span>
                </a>
              </li>
            </ul>
          </nav> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal right fade tire-details show buy_course logout-confirm" id="delete-model" tabindex="-1" role="dialog"
  aria-labelledby="right_modal_sm" aria-modal="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <a href="javascript:void(0);" (click)="closeDeletePopUp()" class="close-panel ripplelink">
        <img src="assets/img/close_icon.png">
      </a>
      <div class="top_sec">
        <p class="mb-0 mt-4">Are you sure do you want to delete? </p>
      </div>
      <div class="bottom_btnsec text-center">
        <a href="javascript:void(0);" (click)="deleteRecord()" class="btn btnstyle-1 me-2 ">Delete</a>
        <a href="javascript:void(0);" (click)="closeDeletePopUp()" class="btn btnstyle-1">Cancel</a>
      </div>
    </div>
  </div>
</div>

<div class="modal right fade tire-details show buy_course logout-confirm share_listModal" id="sharing-model"
  tabindex="-1" role="dialog" aria-labelledby="right_modal_sm" aria-modal="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modal_contGapmodal">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Share Property: {{sharePropertyName}}</h5>
        <button (click)="closeSharingPopUp()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="far fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal_content">

          <div class="block_cont">
            <div class="row ">
              <div class="col-md-4"><label> Deal Summary:</label>
              </div>
              <div class="col-md-8">


                <!-- <div class="enable-link">
                  <div class="heading_h5 checkFlex_new">
                    Enable shareable link
                    
                    <input type="checkbox" class="toggleSwitch" />
                  </div>
                  <div class="toggle_checkbox">
                  </div>

                </div> -->
                <div class="shareUrl_input">
                  <input type="text" class="form-control" value="{{sharingURL}}" readonly>
                  <span style="display:none;" id="copyaddress">{{sharingURL}}</span>
                  <div class="copy_linking">
                    <a [routerLink]="['/shared', shareUniqueId]" target="_blank"><i class="fa fa-external-link"
                        aria-hidden="true"></i></a>
                    <button (click)="copyInputMessage('#copyaddress')">Copy</button>
                  </div>
                </div>


                <div class="share_social">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" (click)="shareLinkOnSocialMedia('facebook')">
                        <img src="assets/img/fb.png" alt="">
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="shareLinkOnSocialMedia('twitter')">
                        <img src="assets/img/twitter.png" alt="">
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="shareLinkOnSocialMedia('linkedin')">
                        <img src="assets/img/linkedin.png" alt="">
                      </a>
                    </li>
                    <!-- <li>
              <a href="javascript:void(0);" (click)="shareLinkOnSocialMedia('facebook')">
                <img src="assets/img/emo.png" alt="">
              </a>
            </li> -->
                    <!-- <li>
              <a href="javascript:void(0);" (click)="shareLinkOnSocialMedia('facebook')">
                <img src="assets/img/message.png" alt="">
              </a>
            </li> -->
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div class="block_cont">
            <div class="row align-items-center">
              <div class="col-md-4"><label> Investor Package:</label>
              </div>
              <div class="col-md-8">
                <div class="btn_groupmodal">
                  <button type="button" class="btn modal_downbtn" (click)="downloadReportPdf()">
                    <span> <i class="fas fa-eye"></i></span>
                    View</button>

                  <button type="button" class="btn modal_downbtn" (click)="downloadReportPdf()">
                    <span> <i class="fas fa-cloud-upload"></i></span>
                    Download</button>
                </div>
              </div>
            </div>
          </div>
          <div class="block_cont">
            <div class="row align-items-center">
              <div class="col-md-4"><label> Report Settings:</label>
              </div>
              <div class="col-md-8">
                <div class="btn_groupmodal">
                  <button [routerLink]="['/properties/add-property-step1', shareUniqueId]" type="button"
                    class="btn modal_downbtn">
                    <span>
                      <i class="fas fa-eye"></i>
                    </span>
                    Customize
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>