import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateGuard } from './_guard/private.guard';
import { GuestGuard } from './_guard/guest.guard';
import { DashboardComponent } from './_components/_dashboard/dashboard.component';
import { SharedComponent } from './_components/_shared/shared.component';
import { PlansComponent } from './_components/_plans/plans.component';
import { LoginComponent } from './user-pages/login/login.component';
import { SignupComponent } from './user-pages/signup/signup.component';
import { ForgotPasswordComponent } from './user-pages/forgot-password/forgot-password.component';
import { MyProfileComponent } from './_components/_my-profile/my-profile.component';
import { UserColumnsComponent } from './_components/_user-columns/user-columns.component';
import { ContactsListComponent } from './_components/_contacts/contacts-list/contacts-list.component';
import { AddContactsComponent } from './_components/_contacts/add-contacts/add-contacts.component';
import { UpdateContactsComponent } from './_components/_contacts/update-contacts/update-contacts.component';
import { ViewContactsComponent } from './_components/_contacts/view-contacts/view-contacts.component';
import { EmailVerificationComponent } from './user-pages/email-verification/email-verification.component';
import { ResetPasswordComponent } from './user-pages/reset-password/reset-password.component';

import { AddPropertyComponent } from './_components/_properties/add-property/add-property.component';
import { AddPropertyStep1Component } from './_components/_properties/add-property-step1/add-property-step1.component';
import { AddPropertyStep2Component } from './_components/_properties/add-property-step2/add-property-step2.component';
import { AddPropertyStep3Component } from './_components/_properties/add-property-step3/add-property-step3.component';
import { AddPropertyStep4Component } from './_components/_properties/add-property-step4/add-property-step4.component';

import { AddPropertyStep5Component } from './_components/_properties/add-property-step5/add-property-step5.component';

import { AddPropertyStep6Component } from './_components/_properties/add-property-step6/add-property-step6.component';
import { AddPropertyStep7Component } from './_components/_properties/add-property-step7/add-property-step7.component';
import { AddPropertyStep8Component } from './_components/_properties/add-property-step8/add-property-step8.component';
import { PropertyListingComponent } from './_components/_properties/property-listing/property-listing.component';

import { WholesalePropertyStep1Component } from './_components/_wholesale/wholesale-property-step1/wholesale-property-step1.component';
import { WholesalePropertyStep2Component } from './_components/_wholesale/wholesale-property-step2/wholesale-property-step2.component';
import { WholesalePropertyStep3Component } from './_components/_wholesale/wholesale-property-step3/wholesale-property-step3.component';
import { WholesalePropertyStep4Component } from './_components/_wholesale/wholesale-property-step4/wholesale-property-step4.component';

import { PaymentSuccessComponent } from './_components/_payment-success/payment-success.component';
import { PaymentCancelComponent } from './_components/_payment-cancel/payment-cancel.component';
import { MembershipsComponent } from './_components/_memberships/memberships.component';



import { ViewPropertyStep1Component } from './_components/_view-properties/view-property-step1/view-property-step1.component';
import { ViewPropertyStep2Component } from './_components/_view-properties/view-property-step2/view-property-step2.component';
import { ViewPropertyStep3Component } from './_components/_view-properties/view-property-step3/view-property-step3.component';
import { ViewPropertyStep4Component } from './_components/_view-properties/view-property-step4/view-property-step4.component';
import { ViewPropertyStep5Component } from './_components/_view-properties/view-property-step5/view-property-step5.component';
import { ViewPropertyStep6Component } from './_components/_view-properties/view-property-step6/view-property-step6.component';
import { ViewPropertyStep7Component } from './_components/_view-properties/view-property-step7/view-property-step7.component';
import { ViewPropertyStep8Component } from './_components/_view-properties/view-property-step8/view-property-step8.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [PrivateGuard],
  },
  { path: 'shared/:uniqueId', component: SharedComponent },
  { path: 'plans', component: PlansComponent, canActivate: [PrivateGuard] },
  {
    path: 'memberships',
    component: MembershipsComponent,
    canActivate: [PrivateGuard],
  },
  { path: 'user/login', component: LoginComponent, canActivate: [GuestGuard] },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: 'payment-cancel',
    component: PaymentCancelComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: 'email-verification/:verification_string',
    component: EmailVerificationComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'user/signup',
    component: SignupComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'user/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'reset-password/:string',
    component: ResetPasswordComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: 'contacts/list',
    component: ContactsListComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: 'contacts/add',
    component: AddContactsComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: 'contacts/update/:id',
    component: UpdateContactsComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: 'contacts/view/:id',
    component: ViewContactsComponent,
    canActivate: [PrivateGuard],
  },

  {
    path: 'properties/list',
    component: PropertyListingComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/user-columns',
    component: UserColumnsComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/add-property',
    component: AddPropertyComponent,
    canActivate: [PrivateGuard],
  },

  {
    path: 'properties/add-property-step1/:uniqueId',
    component: AddPropertyStep1Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/add-property-step2/:uniqueId',
    component: AddPropertyStep2Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/add-property-step3/:uniqueId',
    component: AddPropertyStep3Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/add-property-step4/:uniqueId',
    component: AddPropertyStep4Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/add-property-step5/:uniqueId',
    component: AddPropertyStep5Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/add-property-step6/:uniqueId',
    component: AddPropertyStep6Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/add-property-step7/:uniqueId',
    component: AddPropertyStep7Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/add-property-step8/:uniqueId',
    component: AddPropertyStep8Component,
    canActivate: [PrivateGuard],
  },

  {
    path: 'properties/view-property-step1/:uniqueId',
    component: ViewPropertyStep1Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/view-property-step2/:uniqueId',
    component: ViewPropertyStep2Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/view-property-step3/:uniqueId',
    component: ViewPropertyStep3Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/view-property-step4/:uniqueId',
    component: ViewPropertyStep4Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/view-property-step5/:uniqueId',
    component: ViewPropertyStep5Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/view-property-step6/:uniqueId',
    component: ViewPropertyStep6Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/view-property-step7/:uniqueId',
    component: ViewPropertyStep7Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/view-property-step8/:uniqueId',
    component: ViewPropertyStep8Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/wholesale-property-step1/:uniqueId',
    component: WholesalePropertyStep1Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/wholesale-property-step2/:uniqueId',
    component: WholesalePropertyStep2Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/wholesale-property-step3/:uniqueId',
    component: WholesalePropertyStep3Component,
    canActivate: [PrivateGuard],
  },
  {
    path: 'properties/wholesale-property-step4/:uniqueId',
    component: WholesalePropertyStep4Component,
    canActivate: [PrivateGuard],
  },

  {
    path: 'error',
    loadChildren: () =>
      import('./error-pages/error-pages.module').then(
        (m) => m.ErrorPagesModule
      ),
  },
  { path: '**', redirectTo: '/error/404' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
