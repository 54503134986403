<div class="dashboard_content ">
  <div class="dash-head">
    <div class="row align-items-center">
      <div class="col-6">
        <h1 class="page-titles">Subscription</h1>
      </div>
    </div>
  </div>
  <div class="dashboard-container ">
    <div class="pricing-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
                <div id='card' class="subScribCard">
                  <div id='upper-side' class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                    </svg>
                    <h3 id='status'>
                      Success
                    </h3>
                  </div>
                  <div id='lower-side'>
                    <p id='message'>
                      Congratulations, your subscription has been successfully created.
                    </p>
                    <a href="javascript:void(0);" class="btn-style1 d-inline-block">Continue</a>
                  </div>
                </div>
                
          </div>
        </div>
      </div>
    </div>
  </div>
</div>