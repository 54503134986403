<div class="dashboard_content Properties_AddDashFix">
    <form method="post" id="email-form" [formGroup]="addPropertyStep4Form" (ngSubmit)="onAddPropertyStep4FormSubmit()"
        class="addStep_From">
        <div class="dash-head">
            <div class="row align-items-center">
                <div class="col">
                    <h1 class="page-titles">Properties /<span> Add Property</span></h1>
                </div>
                <div class="col-auto">
                    <div class="left-head">
                        <a (click)="clickToBack()" class="btn-style1 me-2"><i
                                class="far fa-arrow-left me-2"></i> Back</a>
                        <button type="submit" class="btn-style1">Save & Next</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-container">
            <div class="container">
                <div class="customtabs_btns position-relative">
                    <ul class="list-unstyled mb-10">
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step1', uniqueId]">
                                <span class="tabcount">1</span>
                                Property
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step2', uniqueId]">
                                <span class="tabcount">2</span>
                                Financing
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step3', uniqueId]">
                                <span class="tabcount">3</span>
                                Investment
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step4', uniqueId]">
                                <span class="tabcount">4</span>
                                Income
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">5</span>
                                Expenses
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">6</span>
                                Payback
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">7</span>
                                Performance
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">8</span>
                                Summary
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="">
                    <div class="stepcard">
                        <div class="stepform-style1">
                            <div class="form-group d-none d-lg-flex">
                                <label></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="incomehead">
                                                Monthly
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="incomehead">
                                                Yearly
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Gross Rents <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative">
                                                <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                                    class="view_proInputNum income_field_number" type="text"
                                                    formControlName="gross_rent_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'gross_rent_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative">
                                                <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                                    class="view_proInputNum income_field_number" type="text"
                                                    formControlName="gross_rent_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Laundry</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative">
                                                <wj-input-number [isRequired]="false" format="n2"
                                                    class="view_proInputNum income_field_number" type="number"
                                                    formControlName="laundry_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'laundry_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative">
                                                <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                                    disabled class="view_proInputNum income_field_number" type="text"
                                                    formControlName="laundry_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Parking</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative">
                                                <wj-input-number [isRequired]="false" format="n2"
                                                    class="view_proInputNum income_field_number" type="number"
                                                    formControlName="parking_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'parking_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative">
                                                <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                                    class="view_proInputNum income_field_number" type="text"
                                                    formControlName="parking_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Storage</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative">
                                                <wj-input-number [isRequired]="false" format="n2"
                                                    class="view_proInputNum income_field_number" type="number"
                                                    formControlName="storage_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'storage_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative">
                                                <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                                    disabled class="view_proInputNum income_field_number" type="text"
                                                    formControlName="storage_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group Nofield">
                                <label for="">Additional Income</label>
                                <div class="flexwrap-box repeatedCreateDesign">
                                    <div class="form-group d-none d-lg-flex">
                                        <div class="w-100">
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Name
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Amount Monthly
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Amount Yearly
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div formArrayName="incomeItems" class="repeatedCreate"
                                        *ngFor="let property of addPropertyStep4Form.get('incomeItems')['controls']; let i = index;">
                                        <div [formGroupName]="i">
                                            <div class="mb-3 position-relative mt-4 mt-lg-0 flotingsquare">
                                                <div class="row">
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Name</div>
                                                        <div class="position-relative">
                                                            <input placeholder="Enter Item Name" value=""
                                                                class="form-control1" type="text"
                                                                formControlName="item_name">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative">
                                                            <wj-input-number [isRequired]="false" format="n2"
                                                                placeholder="Enter Item Amount Monthly" value=""
                                                                class="view_proInputNum withOut_space" type="number"
                                                                formControlName="item_amount_monthly"
                                                                (keyup)="calculateYearlyAmount($event.target.value, i)">
                                                            </wj-input-number>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative">
                                                            <wj-input-number readonly disabled="" [isRequired]="false"
                                                                format="n2" placeholder="Enter Item Amount Yearly"
                                                                value="" class="view_proInputNum withOut_space" type="number"
                                                                formControlName="item_amount_yearly"></wj-input-number>
                                                        </div>
                                                    </div>
                                                    <a (click)="removeProperty(i)"
                                                        class="squarebtn removeBlock btn btn-danger">
                                                        <i class="far fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" (click)="addProperty()" class="createAddBtn addOnBtn">
                                        <i class="far fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="amtblock">
                            <label>Total Income</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="ttamt">${{totalIncomeMonthlyAmount | number : '1.2-2'}} </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="ttamt">${{totalIncomeYearlyAmount | number : '1.2-2'}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>