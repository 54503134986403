import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view-property-step5',
  templateUrl: './view-property-step5.component.html',
  styleUrls: ['./view-property-step5.component.css'],
})
export class ViewPropertyStep5Component implements OnInit {
  public fmt: any = 'n2';
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep5Form: FormGroup;
  dealTypeId: number;
  details: {};
  otherDetails: {};
  public property_management_percent: any = 0;
  public propertyManagementYearlyAmount: any = 0;
  public furnance_yearlyAmount: any = 0;
  public hot_water_tank_yearlyAmount: any = 0;
  public air_conditionaire_yearlyAmount: any = 0;
  public repairsMaintenanceYearlyAmount: any = 0;
  public bookkeepingYearlyAmount: any = 0;
  public property_tax_yearlyAmount: any = 0;
  public insurance_yearlyAmount: any = 0;
  public water_yearlyAmount: any = 0;
  public electricity_yearlyAmount: any = 0;
  public heating_yearlyAmount: any = 0;
  public snow_removal_yearlyAmount: any = 0;
  public lawn_maintenance_yearlyAmount: any = 0;
  public garbage_disposal_yearlyAmount: any = 0;
  public cleaner_yearlyAmount: any = 0;
  public totalExpensesMonthlyAmount: any = 0;
  public totalExpensesYearlyAmount: any = 0;
  public propertyManagementMonthlyAmount: any = 0;
  public repairsMaintenanceMonthlyAmount: any = 0;
  public bookkeepingMonthlyAmount: any = 0;
  public property_tax_monthlyAmount: any = 0;
  public insurance_monthlyAmount: any = 0;
  public water_monthlyAmount: any = 0;
  public electricity_monthlyAmount: any = 0;
  public heating_monthlyAmount: any = 0;
  public snow_removal_monthlyAmount: any = 0;
  public lawn_maintenance_monthlyAmount: any = 0;
  public garbage_disposal_monthlyAmount: any = 0;
  public cleaner_monthlyAmount: any = 0;
  public totalOperatingExpensesMonthlyAmount: any = 0;
  public totalOperatingExpensesYearlyAmount: any = 0;
  public furnance_monthlyAmount: any = 0;
  public hot_water_tank_monthlyAmount: any = 0;
  public air_conditionaire_monthlyAmount: any = 0;
  public total_rental_equipment_monthlyAmount: any = 0;
  public totalRentalEquipmentMonthlyAmount: any = 0;
  public purchase_price: any = 0;
  public totalRentalEquipmentYearlyAmount: any = 0;
  public repairs_maintenance_percent: any = 0;
  public total_income_monthly: any = 0;
  public total_income_yearly: any = 0;
  operatingCostItems: any;
  operatingItemsArray: any = [];
  operatingItems: FormArray;

  rentalEquipmentCostItems: any;
  rentalEquipmentItemsArray: any = [];
  rentalEquipmentItems: FormArray;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getAllOperatingExpensesRecordsByUniqueId();
    this.getAllRentalEquipmentRecordsByUniqueId();
    this.getSingleRecordOtherDetails(this.uniqueId);
    this.getSingleRecord(this.uniqueId);

    this.addPropertyStep5Form = this.formBuilder.group({
      property_management_monthly: ['', Validators.required],
      property_management_yearly: ['', Validators.required],
      repairs_maintenance_monthly: ['', Validators.required],
      repairs_maintenance_yearly: ['', Validators.required],
      bookkeeping_monthly: ['', Validators.required],
      bookkeeping_yearly: ['', Validators.required],
      property_tax_monthly: ['', Validators.required],
      insurance_monthly: ['', Validators.required],
      water_monthly: ['', Validators.required],
      electricity_monthly: ['', Validators.required],
      heating_monthly: ['', Validators.required],
      snow_removal_monthly: ['', Validators.required],
      lawn_maintenance_monthly: ['', Validators.required],
      garbage_disposal_monthly: ['', Validators.required],
      cleaner_monthly: ['', Validators.required],
      property_tax_yearly: ['', Validators.required],
      insurance_yearly: ['', Validators.required],
      water_yearly: ['', Validators.required],
      electricity_yearly: ['', Validators.required],
      heating_yearly: ['', Validators.required],
      total_holding_months: ['', Validators.required],
      total_operating_expenses: ['', Validators.required],
      total_rental_equipment: ['', Validators.required],
      total_expense_holding_costs: ['', Validators.required],
      snow_removal_yearly: ['', Validators.required],
      lawn_maintenance_yearly: ['', Validators.required],
      garbage_disposal_yearly: ['', Validators.required],
      cleaner_yearly: ['', Validators.required],
      operatingCostsData: ['', Validators.required],
      operatingItems: this.formBuilder.array([]),
      rentalEquipmentCostsData: ['', Validators.required],
      rentalEquipmentItems: this.formBuilder.array([]),
      unique_id: [this.uniqueId, Validators.required],
      user_id: [this.user.id, Validators.required],
      total_expenses_monthly: ['', Validators.required],
      total_expenses_yearly: ['', Validators.required],
      total_operating_expenses_monthly: ['', Validators.required],
      total_operating_expenses_yearly: ['', Validators.required],
      furnance_monthly: ['', Validators.required],
      hot_water_tank_monthly: ['', Validators.required],
      air_conditionaire_monthly: ['', Validators.required],
      total_rental_equipment_monthly: ['', Validators.required],
      furnance_yearly: ['', Validators.required],
      hot_water_tank_yearly: ['', Validators.required],
      air_conditionaire_yearly: ['', Validators.required],
      total_rental_equipment_yearly: ['', Validators.required],
      property_management_percent: ['', Validators.required],
      repairs_maintenance_percent: ['', Validators.required],
    });
  }

  getAllOperatingExpensesRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllExpensesItemsRecordsByUniqueId(this.uniqueId, 'Operating_Expenses')
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.operatingCostItems = response;
            let operatingItemsArray = [];
            for (let i = 0; i <= this.operatingCostItems.data.length; i++) {
              operatingItemsArray.push(this.operatingCostItems.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep5Form.controls['operatingItems']
            );
            if (this.operatingCostItems.data.length > 0) {
              for (let i = 0; i < this.operatingCostItems.data.length; i++) {
                let form = this.formBuilder.group({
                  item_name: [this.operatingCostItems.data[i].item_name],
                  item_amount_monthly: [
                    this.operatingCostItems.data[i].item_amount_monthly,
                  ],
                  item_amount_yearly: [
                    this.operatingCostItems.data[i].item_amount_yearly,
                  ],
                });
                control.push(form);
              }
            }
            this.operatingItems = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getAllRentalEquipmentRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllExpensesItemsRecordsByUniqueId(this.uniqueId, 'Rental_Equipment')
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.rentalEquipmentCostItems = response;
            let rentalEquipmentItemsArray = [];
            for (
              let i = 0;
              i <= this.rentalEquipmentCostItems.data.length;
              i++
            ) {
              rentalEquipmentItemsArray.push(
                this.rentalEquipmentCostItems.data[i]
              );
            }
            let control = <FormArray>(
              this.addPropertyStep5Form.controls['rentalEquipmentItems']
            );
            if (this.rentalEquipmentCostItems.data.length > 0) {
              for (
                let i = 0;
                i < this.rentalEquipmentCostItems.data.length;
                i++
              ) {
                let form = this.formBuilder.group({
                  item_name: [this.rentalEquipmentCostItems.data[i].item_name],
                  item_amount_monthly: [
                    this.rentalEquipmentCostItems.data[i].item_amount_monthly,
                  ],
                  item_amount_yearly: [
                    this.rentalEquipmentCostItems.data[i].item_amount_yearly,
                  ],
                });
                control.push(form);
              }
            }
            this.rentalEquipmentItems = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  addProperty(): void {
    this.operatingItems = this.addPropertyStep5Form.get(
      'operatingItems'
    ) as FormArray;
    this.operatingItems.push(this.createProperty());
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      item_amount_monthly: '',
      item_name: '',
      item_amount_yearly: '',
    });
  }

  removeProperty(rowIndex: number) {
    this.operatingItems.removeAt(rowIndex);
    this.calculateTotalOperatingExpensesMonthly();
  }

  removeRental(rowIndex: number) {
    this.rentalEquipmentItems.removeAt(rowIndex);
    this.calculateTotalRentalEquipmentMonthly();
  }

  addRental(): void {
    this.rentalEquipmentItems = this.addPropertyStep5Form.get(
      'rentalEquipmentItems'
    ) as FormArray;
    this.rentalEquipmentItems.push(this.createRental());
  }

  createRental(): FormGroup {
    return this.formBuilder.group({
      item_amount_monthly: '',
      item_name: '',
      item_amount_yearly: '',
    });
  }

  calculatePropertyManagement(percent) {
    this.property_management_percent = percent;
    var total_income_monthly =
      (this.property_management_percent * this.total_income_monthly) / 100;
    var property_management_monthly = total_income_monthly;
    this.propertyManagementMonthlyAmount = property_management_monthly;

    var total_income_yearly =
      (this.property_management_percent * this.total_income_yearly) / 100;
    var property_management_yearly = total_income_yearly;
    this.propertyManagementYearlyAmount = property_management_yearly;

    this.addPropertyStep5Form.patchValue({
      property_management_monthly: property_management_monthly,
      property_management_yearly: property_management_yearly,
    });

    this.calculateTotalOperatingExpensesMonthly();
  }

  calculateRepairsAndMaintenance(percent) {
    this.repairs_maintenance_percent = percent;

    var purchase_price =
      (this.repairs_maintenance_percent * this.purchase_price) / 1200;
    var repairs_maintenance_monthly = purchase_price.toFixed(2);
    this.repairsMaintenanceMonthlyAmount = repairs_maintenance_monthly;

    var purchase_price_2 =
      (this.repairs_maintenance_percent * this.purchase_price) / 100;
    var repairs_maintenance_yearly = purchase_price_2.toFixed(2);
    this.repairsMaintenanceYearlyAmount = repairs_maintenance_yearly;

    this.addPropertyStep5Form.patchValue({
      repairs_maintenance_monthly: repairs_maintenance_monthly,
      repairs_maintenance_yearly: repairs_maintenance_yearly,
    });
    this.calculateTotalOperatingExpensesMonthly();
  }

  getSingleRecordOtherDetails(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordOtherDetailsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.otherDetails = response.data;
            this.addPropertyStep5Form.patchValue(response.data);

            this.total_income_monthly = response.data.total_income_monthly
              ? response.data.total_income_monthly
              : 0;
            this.total_income_yearly = response.data.total_income_yearly
              ? response.data.total_income_yearly
              : 0;

            this.propertyManagementYearlyAmount = response.data
              .property_management_yearly
              ? response.data.property_management_yearly
              : 0;
            this.repairsMaintenanceYearlyAmount = response.data
              .repairs_maintenance_yearly
              ? response.data.repairs_maintenance_yearly
              : 0;
            this.bookkeepingYearlyAmount = response.data.bookkeeping_yearly
              ? response.data.bookkeeping_yearly
              : 0;
            this.property_tax_yearlyAmount = response.data.property_tax_yearly
              ? response.data.property_tax_yearly
              : 0;
            this.insurance_yearlyAmount = response.data.insurance_yearly
              ? response.data.insurance_yearly
              : 0;
            this.water_yearlyAmount = response.data.water_yearly
              ? response.data.water_yearly
              : 0;
            this.electricity_yearlyAmount = response.data.electricity_yearly
              ? response.data.electricity_yearly
              : 0;
            this.heating_yearlyAmount = response.data.heating_yearly
              ? response.data.heating_yearly
              : 0;
            this.snow_removal_yearlyAmount = response.data.snow_removal_yearly
              ? response.data.snow_removal_yearly
              : 0;
            this.lawn_maintenance_yearlyAmount = response.data
              .lawn_maintenance_yearly
              ? response.data.lawn_maintenance_yearly
              : 0;
            this.garbage_disposal_yearlyAmount = response.data
              .garbage_disposal_yearly
              ? response.data.garbage_disposal_yearly
              : 0;
            this.cleaner_yearlyAmount = response.data.cleaner_yearly
              ? response.data.cleaner_yearly
              : 0;

            this.propertyManagementMonthlyAmount =
              response.data.property_management_monthly;
            console.log(
              'response.data.property_management_monthly ---->',
              response.data.property_management_monthly
            );
            this.addPropertyStep5Form.patchValue({
              property_management_monthly:
                response.data.property_management_monthly,
              property_management_yearly:
                response.data.property_management_yearly,
            });

            console.log('---', this.propertyManagementMonthlyAmount);
            this.repairsMaintenanceMonthlyAmount =
              response.data.repairs_maintenance_monthly;
            this.bookkeepingMonthlyAmount = response.data.bookkeeping_monthly
              ? response.data.bookkeeping_monthly
              : 0;
            this.property_tax_monthlyAmount = response.data.property_tax_monthly
              ? response.data.property_tax_monthly
              : 0;
            this.insurance_monthlyAmount = response.data.insurance_monthly
              ? response.data.insurance_monthly
              : 0;
            this.water_monthlyAmount = response.data.water_monthly
              ? response.data.water_monthly
              : 0;
            this.electricity_monthlyAmount = response.data.electricity_monthly
              ? response.data.electricity_monthly
              : 0;
            this.heating_monthlyAmount = response.data.heating_monthly
              ? response.data.heating_monthly
              : 0;
            this.snow_removal_monthlyAmount = response.data.snow_removal_monthly
              ? response.data.snow_removal_monthly
              : 0;
            this.lawn_maintenance_monthlyAmount = response.data
              .lawn_maintenance_monthly
              ? response.data.lawn_maintenance_monthly
              : 0;
            this.garbage_disposal_monthlyAmount = response.data
              .garbage_disposal_monthly
              ? response.data.garbage_disposal_monthly
              : 0;
            this.cleaner_monthlyAmount = response.data.cleaner_monthly
              ? response.data.cleaner_monthly
              : 0;
            this.totalOperatingExpensesYearlyAmount = response.data
              .total_operating_expenses_yearly
              ? response.data.total_operating_expenses_yearly
              : 0;
            this.totalOperatingExpensesMonthlyAmount = response.data
              .total_operating_expenses_monthly
              ? response.data.total_operating_expenses_monthly
              : 0;

            this.furnance_yearlyAmount = response.data.furnance_yearly
              ? response.data.furnance_yearly
              : 0;
            this.hot_water_tank_yearlyAmount = response.data
              .hot_water_tank_yearly
              ? response.data.hot_water_tank_yearly
              : 0;
            this.air_conditionaire_yearlyAmount = response.data
              .air_conditionaire_yearly
              ? response.data.air_conditionaire_yearly
              : 0;

            this.furnance_monthlyAmount = response.data.furnance_monthly
              ? response.data.furnance_monthly
              : 0;
            this.hot_water_tank_monthlyAmount = response.data
              .hot_water_tank_monthly
              ? response.data.hot_water_tank_monthly
              : 0;
            this.air_conditionaire_monthlyAmount = response.data
              .air_conditionaire_monthly
              ? response.data.air_conditionaire_monthly
              : 0;
            this.totalRentalEquipmentMonthlyAmount = response.data
              .total_rental_equipment_monthly
              ? response.data.total_rental_equipment_monthly
              : 0;
            this.totalRentalEquipmentYearlyAmount = response.data
              .total_rental_equipment_yearly
              ? response.data.total_rental_equipment_yearly
              : 0;

            this.calculateTotalOperatingExpensesMonthly();
            this.calculateTotalRentalEquipmentMonthly();
            this.calculatePropertyManagement(
              response.data.property_management_percent
            );
            stopLoader();
          } else {
            checkTosterResponse(response.message, 'Error');
            stopLoader();
          }
          //stopLoader();
        },
        (error) => {
          //stopLoader();
        }
      );
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.details = response.data;
            this.recordId = response.data.id;
            this.dealTypeId = response.data.deal_type_id;
            this.purchase_price = response.data.purchase_price;
            this.calculateTotalOperatingExpensesMonthly();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  onAddPropertyStep5FormSubmit() {}

  updateMonthlyToYearlyAmount(amount, type) {
    if (type == 'property_management_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.propertyManagementYearlyAmount = amountF;
        this.propertyManagementMonthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          property_management_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'repairs_maintenance_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.repairsMaintenanceYearlyAmount = amountF;
        this.repairsMaintenanceMonthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          repairs_maintenance_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'bookkeeping_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.bookkeepingYearlyAmount = amountF;
        this.bookkeepingMonthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          bookkeeping_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'property_tax_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.property_tax_yearlyAmount = amountF;
        this.property_tax_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          property_tax_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'insurance_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.insurance_yearlyAmount = amountF;
        this.insurance_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          insurance_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'water_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.water_yearlyAmount = amountF;
        this.water_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          water_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'electricity_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.electricity_yearlyAmount = amountF;
        this.electricity_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          electricity_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'heating_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.heating_yearlyAmount = amountF;
        this.heating_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          heating_yearly: amountF.toFixed(3),
        });
      }
    } else if (type == 'snow_removal_monthly') {
      if (amount > 0) {
        var amountF = 12 * amount;
        this.snow_removal_yearlyAmount = amountF;
        this.snow_removal_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          snow_removal_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'lawn_maintenance_monthly') {
      if (amount) {
        var amountF = 12 * amount;
        this.lawn_maintenance_yearlyAmount = amountF;
        this.lawn_maintenance_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          lawn_maintenance_yearly: amountF.toFixed(3),
        });
      }
    } else if (type == 'garbage_disposal_monthly') {
      if (amount) {
        var amountF = 12 * amount;
        this.garbage_disposal_yearlyAmount = amountF;
        this.garbage_disposal_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          garbage_disposal_yearly: amountF.toFixed(2),
        });
      }
    } else if (type == 'cleaner_monthly') {
      if (amount) {
        var amountF = 12 * amount;
        this.cleaner_yearlyAmount = amountF;
        this.cleaner_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          cleaner_yearly: amountF.toFixed(2),
        });
      }
    }
    this.calculateTotalOperatingExpensesMonthly();
  }

  updateMonthlyToYearlyAmountRental(amount, type) {
    if (type == 'furnance_monthly') {
      if (amount) {
        var amountF = 12 * amount;
        this.furnance_yearlyAmount = amountF;
        this.furnance_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          furnance_yearly: amountF.toFixed(3),
        });
      }
    } else if (type == 'hot_water_tank_monthly') {
      if (amount) {
        var amountF = 12 * amount;
        this.hot_water_tank_yearlyAmount = amountF;
        this.hot_water_tank_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          hot_water_tank_yearly: amountF.toFixed(3),
        });
      }
    } else if (type == 'air_conditionaire_monthly') {
      if (amount) {
        var amountF = 12 * amount;
        this.air_conditionaire_yearlyAmount = amountF;
        this.air_conditionaire_monthlyAmount = amount;
        this.addPropertyStep5Form.patchValue({
          air_conditionaire_yearly: amountF.toFixed(3),
        });
      }
    }
    this.calculateTotalRentalEquipmentMonthly();
  }

  calculateTotalRentalEquipmentMonthly() {
    var totalRentalEquipmentMonthlyAmount =
      parseFloat(this.furnance_monthlyAmount) +
      parseFloat(this.hot_water_tank_monthlyAmount) +
      parseFloat(this.air_conditionaire_monthlyAmount);

    let totalPurcahseCostAmountValue = totalRentalEquipmentMonthlyAmount;
    var rentalEquipmentItemsCostData =
      this.addPropertyStep5Form.value.rentalEquipmentItems;
    for (const singleRecord of rentalEquipmentItemsCostData) {
      totalPurcahseCostAmountValue =
        totalPurcahseCostAmountValue +
        parseFloat(singleRecord.item_amount_monthly);
    }
    console.log(
      'calculateTotalRentalEquipmentMonthly',
      totalPurcahseCostAmountValue
    );
    this.totalRentalEquipmentMonthlyAmount = totalPurcahseCostAmountValue;
    // this.totalRentalEquipmentYearlyAmount = 12 * this.totalRentalEquipmentMonthlyAmount;
    this.addPropertyStep5Form.patchValue({
      total_rental_equipment: totalPurcahseCostAmountValue,
    });
    this.addPropertyStep5Form.patchValue({
      total_rental_equipment_monthly: totalPurcahseCostAmountValue,
    });

    var totalRentalEquipmenYearlyAmount =
      parseFloat(this.furnance_yearlyAmount) +
      parseFloat(this.hot_water_tank_yearlyAmount) +
      parseFloat(this.air_conditionaire_yearlyAmount);
    let totalPurcahseCostAmountValueDD = totalRentalEquipmenYearlyAmount;
    var rentalEquipmentItemsCostData =
      this.addPropertyStep5Form.value.rentalEquipmentItems;
    for (const singleRecord of rentalEquipmentItemsCostData) {
      totalPurcahseCostAmountValueDD =
        totalPurcahseCostAmountValueDD +
        parseFloat(singleRecord.item_amount_yearly);
    }
    console.log(
      'totalRentalEquipmentYearlyAmount',
      totalPurcahseCostAmountValueDD
    );
    this.totalRentalEquipmentYearlyAmount = totalPurcahseCostAmountValueDD;

    this.addPropertyStep5Form.patchValue({
      total_rental_equipment_yearly: this.totalRentalEquipmentYearlyAmount,
    });

    this.calculateTotalExpenses();
  }

  calculateTotalOperatingExpensesMonthly() {
    console.log(
      'this.propertyManagementMonthlyAmount',
      this.propertyManagementMonthlyAmount
    );
    console.log(
      'this.repairsMaintenanceMonthlyAmount',
      this.repairsMaintenanceMonthlyAmount
    );
    console.log('this.bookkeepingMonthlyAmount', this.bookkeepingMonthlyAmount);
    console.log(
      'this.property_tax_monthlyAmount',
      this.property_tax_monthlyAmount
    );
    console.log('this.insurance_monthlyAmount', this.insurance_monthlyAmount);
    console.log('this.water_monthlyAmount', this.water_monthlyAmount);
    console.log(
      'this.electricity_monthlyAmount',
      this.electricity_monthlyAmount
    );
    console.log('this.heating_monthlyAmount', this.heating_monthlyAmount);
    console.log(
      'this.snow_removal_monthlyAmount',
      this.snow_removal_monthlyAmount
    );
    console.log(
      'this.lawn_maintenance_monthlyAmount',
      this.lawn_maintenance_monthlyAmount
    );
    console.log(
      'this.garbage_disposal_monthlyAmount',
      this.garbage_disposal_monthlyAmount
    );
    console.log('this.cleaner_monthlyAmount', this.cleaner_monthlyAmount);
    var totalOperatingExpensesMonthlyAmount =
      parseFloat(this.propertyManagementMonthlyAmount) +
      parseFloat(this.repairsMaintenanceMonthlyAmount) +
      parseFloat(this.bookkeepingMonthlyAmount) +
      parseFloat(this.property_tax_monthlyAmount) +
      parseFloat(this.insurance_monthlyAmount) +
      parseFloat(this.water_monthlyAmount) +
      parseFloat(this.electricity_monthlyAmount) +
      parseFloat(this.heating_monthlyAmount) +
      parseFloat(this.snow_removal_monthlyAmount) +
      parseFloat(this.lawn_maintenance_monthlyAmount) +
      parseFloat(this.garbage_disposal_monthlyAmount) +
      parseFloat(this.cleaner_monthlyAmount);

    let totalPurcahseCostAmountValue = totalOperatingExpensesMonthlyAmount;
    console.log('totalPurcahseCostAmountValue--', totalPurcahseCostAmountValue);
    var operatingItemsCostData = this.addPropertyStep5Form.value.operatingItems;
    for (const singleRecord of operatingItemsCostData) {
      totalPurcahseCostAmountValue =
        totalPurcahseCostAmountValue +
        parseFloat(singleRecord.item_amount_monthly);
    }
    console.log('totalPurcahseCostAmountValue', totalPurcahseCostAmountValue);
    this.totalOperatingExpensesMonthlyAmount = totalPurcahseCostAmountValue;
    // this.totalOperatingExpensesYearlyAmount = 12 * this.totalOperatingExpensesMonthlyAmount;
    this.addPropertyStep5Form.patchValue({
      total_operating_expenses: totalPurcahseCostAmountValue,
    });
    this.addPropertyStep5Form.patchValue({
      total_operating_expenses_monthly: totalPurcahseCostAmountValue,
    });

    var totalOperatingExpensesYearlyAmountD =
      parseFloat(this.propertyManagementYearlyAmount) +
      parseFloat(this.repairsMaintenanceYearlyAmount) +
      parseFloat(this.bookkeepingYearlyAmount) +
      parseFloat(this.property_tax_yearlyAmount) +
      parseFloat(this.insurance_yearlyAmount) +
      parseFloat(this.water_yearlyAmount) +
      parseFloat(this.electricity_yearlyAmount) +
      parseFloat(this.heating_yearlyAmount) +
      parseFloat(this.snow_removal_yearlyAmount) +
      parseFloat(this.lawn_maintenance_yearlyAmount) +
      parseFloat(this.garbage_disposal_yearlyAmount) +
      parseFloat(this.cleaner_yearlyAmount);
    let totalPurcahseCostAmountValueYearly =
      totalOperatingExpensesYearlyAmountD;
    var operatingItemsCostData = this.addPropertyStep5Form.value.operatingItems;
    for (const singleRecord of operatingItemsCostData) {
      totalPurcahseCostAmountValueYearly =
        totalPurcahseCostAmountValueYearly +
        parseFloat(singleRecord.item_amount_yearly);
    }
    console.log(
      'totalPurcahseCostAmountValueYearly',
      totalPurcahseCostAmountValueYearly
    );
    this.totalOperatingExpensesYearlyAmount =
      totalPurcahseCostAmountValueYearly;

    this.addPropertyStep5Form.patchValue({
      total_operating_expenses_yearly: this.totalOperatingExpensesYearlyAmount,
    });

    this.calculateTotalExpenses();
  }

  calculateYearlyAmount(amount, index) {
    var self = this;
    let operatingItemsData = this.addPropertyStep5Form.value.operatingItems;
    setTimeout(function () {
      operatingItemsData[index]['item_amount_yearly'] =
        operatingItemsData[index]['item_amount_monthly'] * 12;
      console.log(operatingItemsData);
      self.addPropertyStep5Form.patchValue({
        operatingItems: operatingItemsData,
      });
      self.calculateTotalOperatingExpensesMonthly();
    }, 2000);
  }

  calculateYearlyAmountOfRental(amount, index) {
    var self = this;
    let rentalEquipmentItemsData =
      this.addPropertyStep5Form.value.rentalEquipmentItems;
    setTimeout(function () {
      rentalEquipmentItemsData[index]['item_amount_yearly'] =
        rentalEquipmentItemsData[index]['item_amount_monthly'] * 12;
      console.log(rentalEquipmentItemsData);
      self.addPropertyStep5Form.patchValue({
        rentalEquipmentItems: rentalEquipmentItemsData,
      });
      self.calculateTotalRentalEquipmentMonthly();
    }, 2000);
  }

  calculateTotalExpenses() {
    this.totalExpensesMonthlyAmount =
      parseFloat(this.totalOperatingExpensesMonthlyAmount) +
      parseFloat(this.totalRentalEquipmentMonthlyAmount);
    this.totalExpensesYearlyAmount =
      parseFloat(this.totalOperatingExpensesYearlyAmount) +
      parseFloat(this.totalRentalEquipmentYearlyAmount);

    this.addPropertyStep5Form.patchValue({
      total_expenses_monthly: this.totalExpensesMonthlyAmount,
    });
    this.addPropertyStep5Form.patchValue({
      total_expenses_yearly: this.totalExpensesYearlyAmount,
    });
  }

  calculateHoldingCost(months) {
    var totalHoldingAmount =
      parseInt(this.addPropertyStep5Form.value.total_operating_expenses) +
      parseInt(this.addPropertyStep5Form.value.total_rental_equipment);

    this.addPropertyStep5Form.patchValue({
      total_expense_holding_costs: months * totalHoldingAmount,
    });
  }
}
