<div class="dashboard_content">
    <div class="dash-head">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="page-titles">Properties /<span> Add Property</span></h1>
            </div>
            <div class="col-auto">
                <div class="left-head">
                    <a [routerLink]="['/properties/add-property-step7', uniqueId]" class="btn-style1"><i class="far fa-arrow-left me-2"></i> Back</a>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-container">
        <div class="container">
            <div class="customtabs_btns position-relative">
                <ul class="list-unstyled mb-10">
                    <li class="active">
                        <a [routerLink]="['/properties/add-property-step1', uniqueId]">
                            <span class="tabcount">1</span>
                            Property
                        </a>
                    </li>
                    <li class="active">
                        <a [routerLink]="['/properties/add-property-step2', uniqueId]">
                            <span class="tabcount">2</span>
                            Financing
                        </a>
                    </li>
                    <li class="active">
                        <a [routerLink]="['/properties/add-property-step3', uniqueId]">
                            <span class="tabcount">3</span>
                            Investment
                        </a>
                    </li>
                    <li class="active">
                        <a [routerLink]="['/properties/add-property-step4', uniqueId]">
                            <span class="tabcount">4</span>
                            Income
                        </a>
                    </li>
                    <li class="active">
                        <a [routerLink]="['/properties/add-property-step5', uniqueId]">
                            <span class="tabcount">5</span>
                            Expenses
                        </a>
                    </li>
                    <li class="active">
                        <a [routerLink]="['/properties/add-property-step6', uniqueId]">
                            <span class="tabcount">6</span>
                            Payback
                        </a>
                    </li>
                    <li class="active">
                        <a [routerLink]="['/properties/add-property-step7', uniqueId]">
                            <span class="tabcount">7</span>
                            Performance
                        </a>
                    </li>
                    <li class="active">
                        <a [routerLink]="['/properties/add-property-step8', uniqueId]">
                            <span class="tabcount">8</span>
                            Summary
                        </a>
                    </li>
                </ul>
            </div>
            <div class="stepaccordCard">
                <div class="accordion" id="accordionStep">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"  data-bs-target="#purchaseAccord" aria-expanded="false" aria-controls="purchaseAccord">
                                Purchase Metrics
                            </button>
                        </h2>
                        <div id="purchaseAccord" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionStep">
                            <div class="accordion-body">
                                <ul class="list-unstyled mb-0 summaryList">
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Purchase Price
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{purchasePriceAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Loans
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="loanAmountMetrics > 0">${{loanAmountMetrics | number : '1.2-2'}}</span>
                                                <span class="su-amt redField" *ngIf="loanAmountMetrics < 0">-${{loanAmountMetrics | makePositive}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Down Payment
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{downPaymentAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Closing Costs
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="closingCostsAmount > 0">${{closingCostsAmount| number : '1.2-2'}}</span>
                                                <span class="su-amt redField" *ngIf="closingCostsAmount < 0">-${{closingCostsAmount| makePositive}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Repairs and Renovations
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{repairsRenovationsAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Investment Required
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{totalInvestmentAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#financingAccord" aria-expanded="false" aria-controls="financingAccord">
                                Financing Metrics
                            </button>
                        </h2>
                        <div id="financingAccord" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionStep">
                            <div class="accordion-body">
                                <ul class="list-unstyled mb-0 summaryList">
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Loans
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="loanAmountMetrics > 0">${{loanAmountMetrics | number : '1.2-2'}}</span>
                                                <span class="su-amt redField" *ngIf="loanAmountMetrics < 0">-${{loanAmountMetrics | makePositive}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="stepcard">
                                    <div class="formtable">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Loan Name</th>
                                                        <th>Loan Type</th>
                                                        <th>Amount</th>
                                                        <th>Term</th>
                                                        <th>Interest</th>
                                                        <th>Monthly</th>
                                                        <th>Yearly</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let singleRecord of loanData; let i = index">
                                                        <td>{{singleRecord.loan_name}}</td>
                                                        <td>{{singleRecord.loan_type}}</td>
                                                        <td>${{singleRecord.loan_amount | number : '1.2-2'}}</td>
                                                        <td>{{singleRecord.terms_in_years}}</td>
                                                        <td>{{singleRecord.interest_rate | number : '1.2-2'}}%</td>
                                                        <td *ngIf="singleRecord.emi_monthly">${{singleRecord.emi_monthly | number : '1.2-2'}}</td>
                                                        <td *ngIf="!singleRecord.emi_monthly">$0.00</td>
                                                        <td *ngIf="singleRecord.emi_yearly">${{singleRecord.emi_yearly | number : '1.2-2'}}</td>
                                                        <td *ngIf="!singleRecord.emi_yearly">$0.00</td>
                                                    </tr>
                                                    <tr *ngIf="loanData?.length == 0">
                                                        <td colspan="8">
                                                            <div class="alert alert-danger">
                                                              <strong>Nothing here!</strong>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#valuationAccord" aria-expanded="false" aria-controls="valuationAccord">
                                Valuation Metrics
                            </button>
                        </h2>
                        <div id="valuationAccord" class="accordion-collapse collapse show" aria-labelledby="headingThree"
                            data-bs-parent="#accordionStep">
                            <div class="accordion-body">
                                <ul class="list-unstyled mb-0 summaryList">
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Purchase Price
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{purchasePriceAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Price Per Unit
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{pricePerUnitAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                After Repair Value
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{afterRepairValueAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Price Per Unit After Repair
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{pricePerUnitAfterRepairAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Value Increase
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">{{ValueIncreaseAmount | number : '1.2-2' }}%</span>
                                            </div>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item" *ngIf="dealTypeId === 1">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#cashflow1" aria-expanded="false" aria-controls="cashflow1">
                                Cash Flow Metrics Yearly
                            </button>
                        </h2>
                        <div id="cashflow1" class="accordion-collapse collapse show" aria-labelledby="headingFour"
                            data-bs-parent="#accordionStep">
                            <ul class="list-unstyled mb-0 summaryList">
                                <li>
                                    <div class="summaryList-databox">
                                        <div class="summaryHead">
                                            Gross Rent
                                        </div>

                                        <div class="summaryamt">
                                            <span class="su-amt">${{grossRentAmount  | number : '1.2-2'}}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="summaryList-databox">
                                        <div class="summaryHead">
                                            Income
                                        </div>

                                        <div class="summaryamt">
                                            <span class="su-amt">${{incomeAmount  | number : '1.2-2'}}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="summaryList-databox">
                                        <div class="summaryHead">
                                            Expenses
                                        </div>

                                        <div class="summaryamt">
                                            <span class="su-amt">${{expensesAmount  | number : '1.2-2'}}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="summaryList-databox">
                                        <div class="summaryHead">
                                            Operating Income
                                        </div>
                                        <div class="summaryamt">
                                            <span class="su-amt" *ngIf="operatingIncomeAmount > 0">${{operatingIncomeAmount  | number : '1.2-2'}}</span>
                                            <span class="su-amt redField" *ngIf="operatingIncomeAmount < 0">-${{operatingIncomeAmount  | makePositive}}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="summaryList-databox">
                                        <div class="summaryHead">
                                            Vacancy
                                        </div>
                                        <div class="summaryamt">
                                            <span class="su-amt" *ngIf="VacancyAmount > 0">${{VacancyAmount  | number : '1.2-2'}}</span>
                                            <span class="su-amt redField" *ngIf="VacancyAmount < 0">-${{VacancyAmount  | makePositive}}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="summaryList-databox">
                                        <div class="summaryHead">
                                            Net Operating Income
                                        </div>

                                        <div class="summaryamt">
                                            <span class="su-amt" *ngIf="NetOperatingIncomeAmount > 0">${{NetOperatingIncomeAmount  | number : '1.2-2'}}</span>
                                            <span class="su-amt redField" *ngIf="NetOperatingIncomeAmount < 0">-${{NetOperatingIncomeAmount  | makePositive}}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="summaryList-databox">
                                        <div class="summaryHead">
                                            Loan Payments
                                        </div>

                                        <div class="summaryamt">
                                            <span class="su-amt">${{LoanPaymentsAmount  | number : '1.2-2'}}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="summaryList-databox">
                                        <div class="summaryHead">
                                            Cash Flow
                                        </div>

                                        <div class="summaryamt">
                                            <span class="su-amt" *ngIf="cashFlowAmount > 0">${{cashFlowAmount  | number : '1.2-2'}}</span>
                                            <span class="su-amt redField" *ngIf="cashFlowAmount < 0">-${{cashFlowAmount  | makePositive}}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="accordion-item" *ngIf="dealTypeId === 1">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#investmentReturn" aria-expanded="false"
                                aria-controls="investmentReturn">
                                Investment Metrics
                            </button>
                        </h2>
                        <div id="investmentReturn" class="accordion-collapse collapse show" aria-labelledby="headingFive"
                            data-bs-parent="#accordionStep">
                            <div class="accordion-body">
                                <ul class="list-unstyled mb-0 summaryList">
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Cash on Cash Return
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="CashOnCashReturnValue > 0">{{CashOnCashReturnValue | number : '1.2-2'}}%</span>
                                                <span class="su-amt redField" *ngIf="CashOnCashReturnValue < 0">{{CashOnCashReturnValue | number : '1.2-2'}}%</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                CAP Rate (Sale Price)
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="CAPRateSalePrice > 0">{{CAPRateSalePrice | number : '1.2-2' }}%</span>
                                                <span class="su-amt redField" *ngIf="CAPRateSalePrice < 0">{{CAPRateSalePrice | number : '1.2-2' }}%</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                CAP Rate (Purchase Price)
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="CAPRatePurchasePrice > 0">{{CAPRatePurchasePrice | number : '1.2-2' }}%</span>
                                                <span class="su-amt redField" *ngIf="CAPRatePurchasePrice < 0">{{CAPRatePurchasePrice | number : '1.2-2' }}%</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Debt Coverage Ratio
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="DebtCoverageRatioValue > 0">{{DebtCoverageRatioValue | number : '1.2-2' }}</span>
                                                <span class="su-amt redField" *ngIf="DebtCoverageRatioValue < 0">{{DebtCoverageRatioValue | number : '1.2-2' }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Annual Gross Rent Multiplier
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">{{AnnualGrossRentMultiplierValue | number : '1.2-2' }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Operating Expense Ratio
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">{{OperatingExpenseRatioValue | number : '1.2-2' }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item" *ngIf="dealTypeId === 1">
                        <h2 class="accordion-header" id="headingSix">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#financialratios" aria-expanded="false" aria-controls="financialratios">
                                Total Return
                            </button>
                        </h2>
                        <div id="financialratios" class="accordion-collapse collapse show" aria-labelledby="headingSix"
                            data-bs-parent="#accordionStep">
                            <div class="accordion-body">
                                <ul class="list-unstyled mb-0 summaryList">
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Cash Flow
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="cashFlowValue > 0">${{cashFlowValue| number : '1.2-2' }}</span>
                                                <span class="su-amt redField" *ngIf="cashFlowValue < 0">-${{cashFlowValue| makePositive }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Mortgage Paydown
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="MortgagePaydownValue > 0">${{MortgagePaydownValue| number : '1.2-2' }}</span>
                                                <span class="su-amt redField" *ngIf="MortgagePaydownValue < 0">-${{MortgagePaydownValue| makePositive }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Appreciation
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="AppreciationValue > 0">${{AppreciationValue| number : '1.2-2' }}</span>
                                                <span class="su-amt redField" *ngIf="AppreciationValue < 0">-${{AppreciationValue| makePositive }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Return
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="TotalReturnValue">
                                                   <span *ngIf="TotalReturnValue > 0">${{TotalReturnValue  | number : "1.2-2"}}</span> 
                                                   <span class="redField" *ngIf="TotalReturnValue < 0">-${{TotalReturnValue  | makePositive }}</span> 
                                                </span>
                                                <span class="su-amt" *ngIf="!TotalReturnValue">$0.00</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Invested
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt">${{TotalInvestmentValue| number : '1.2-2' }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total ROI
                                            </div>

                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="totalROIValue">
                                                    <span *ngIf="totalROIValue > 0">{{totalROIValue | number : '1.2-2' }}%</span>
                                                    <span class="redField" *ngIf="totalROIValue < 0">{{totalROIValue | number : '1.2-2' }}%</span>
                                                </span>
                                                <span class="su-amt" *ngIf="!totalROIValue">0.00%</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item" *ngIf="dealTypeId === 2">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#brrrrValuationAccord" aria-expanded="false" aria-controls="brrrrValuationAccord">
                                BRRRR Metrics
                            </button>
                        </h2>
                        <div id="brrrrValuationAccord" class="accordion-collapse collapse show" aria-labelledby="headingThree"
                            data-bs-parent="#accordionStep">
                            <div class="accordion-body">
                                <ul class="list-unstyled mb-0 summaryList">
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Loan Amount
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{totalLoanAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Investment
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{TotalInvestmentValue | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Expense Holding Costs
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{totalExpenseHoldingCostsAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Financing Holding Costs
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{total_financing_holdings_costs | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Refinance Costs
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{totalRefinanceCosts | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total BRRRR Cost
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt" *ngIf="total_brrrr_cost">${{total_brrrr_cost | number : '1.2-2'}}</span>
                                                <span class="su-amt" *ngIf="!total_brrrr_cost">$0.00</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                After Repair Value
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{afterRepairValueAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Loan to Value
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">{{expected_loan_refinance}}%</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Equity Available
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{equity_available | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Cash Out
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{cashOutAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                BRRRR Result
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{brrrResultAmount | number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                BRRRR Performance
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{brrrPerformanceValue| number : '1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item" *ngIf="dealTypeId === 3">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#brrrrValuationAccord" aria-expanded="false" aria-controls="brrrrValuationAccord">
                                Flip Metrics
                            </button>
                        </h2>
                        <div id="brrrrValuationAccord" class="accordion-collapse collapse show" aria-labelledby="headingThree"
                            data-bs-parent="#accordionStep">
                            <div class="accordion-body">
                                <ul class="list-unstyled mb-0 summaryList">
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Loan Amount
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{totalLoanAmount}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Investment
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{TotalInvestmentValue}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Expense Holding Costs
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{total_expense_holding_costs}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Financing Holding Costs
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{total_financing_holdings_costs}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Selling Costs
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{total_selling_costs}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Total Flip Cost
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{total_flip_cost}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                After Repair Value
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{afterRepairValueAmount}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                FLIP Result
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{flip_result}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                FLIP Return on Investment
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{flip_return_on_investment}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="stepform-pagelink">
                    <button class="pagelink-next">Submit <i class="far fa-arrow-right ms-2"></i></button>
                </div> -->
            </div>
        </div>
    </div>
</div>