<div class="dashboard_content">
  <div class="dash-head">
    <div class="row align-items-center">
      <div class="col">
        <h1 class="page-titles">Properties /<span> Add Property</span></h1>
      </div>
      <div class="col-auto">
        <div class="left-head">
          <a (click)="clickToBack()" class="btn-style1 me-2"
            ><i class="far fa-arrow-left me-2"></i> Back</a
          >
          <button (click)="redirectToNextStep()" class="btn-style1">
            Save & Next
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container">
    <div class="container">
      <div class="customtabs_btns position-relative">
        <ul class="list-unstyled mb-10">
          <li class="active">
            <a [routerLink]="['/properties/add-property-step1', uniqueId]">
              <span class="tabcount">1</span>
              Property
            </a>
          </li>
          <li class="active">
            <a [routerLink]="['/properties/add-property-step2', uniqueId]">
              <span class="tabcount">2</span>
              Financing
            </a>
          </li>
          <li class="active">
            <a [routerLink]="['/properties/add-property-step3', uniqueId]">
              <span class="tabcount">3</span>
              Investment
            </a>
          </li>
          <li class="active">
            <a [routerLink]="['/properties/add-property-step4', uniqueId]">
              <span class="tabcount">4</span>
              Income
            </a>
          </li>
          <li class="active">
            <a [routerLink]="['/properties/add-property-step5', uniqueId]">
              <span class="tabcount">5</span>
              Expenses
            </a>
          </li>
          <li class="active">
            <a [routerLink]="['/properties/add-property-step6', uniqueId]">
              <span class="tabcount">6</span>
              Payback
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">
              <span class="tabcount">7</span>
              Performance
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">
              <span class="tabcount">8</span>
              Summary
            </a>
          </li>
        </ul>
      </div>
      <div class="stepcard">
        <div class="stephead-style1">
          <span>Loan Information</span>
        </div>
        <div class="formtable">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Loan Name</th>
                  <th>Loan Type</th>
                  <th>Term</th>
                  <th>Interest</th>
                  <th>Monthly</th>
                  <th>Yearly</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let singleRecord of loanData; let i = index">
                  <td>{{ singleRecord.loan_name }}</td>
                  <td>{{ singleRecord.loan_type }}</td>
                  <td>{{ singleRecord.terms_in_years }}</td>
                  <td>{{ singleRecord.interest_rate | number : "1.2-2" }}%</td>
                  <td>${{ singleRecord.emi_monthly | number : "1.2-2" }}</td>
                  <td>${{ singleRecord.emi_yearly | number : "1.2-2" }}</td>
                </tr>
                <tr *ngIf="loanData?.length == 0">
                  <td colspan="8">
                    <div class="alert alert-danger">
                      <strong>Nothing here!</strong>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="amtblock">
            <label>Total Loan Payback</label>
            <div class="flexwrap-box">
              <div class="row">
                <div class="col-6">
                  <div class="ttamt">
                    ${{
                      details?.total_loan_payback_monthly | number : "1.2-2"
                    }}
                    (Monthly)
                  </div>
                </div>
                <div class="col-6">
                  <div class="ttamt">
                    ${{ details?.total_loan_payback_yearly | number : "1.2-2" }}
                    (Yearly)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="amtblock">
            <label>Message</label>
            <div class="flexwrap-box">
              <div class="row">
                <div class="col-12">
                  <div class="ttamt">
                    To edit these values please go to the Financing step
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- using only for BRRR Deal Type -->
        <div *ngIf="dealTypeId === 2">
          <div class="stephead-style1">
            <span>Financing Holding Costs</span>
          </div>
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td>Total Holding Months</td>
                  <td>{{ totalHoldingMonths }}</td>
                </tr>
                <tr *ngFor="let singleRecord of loanData; let i = index">
                  <td>{{ singleRecord.loan_name }}</td>
                  <td>${{ singleRecord.emi_monthly | number : "1.2-2" }}</td>
                </tr>
                <tr>
                  <td>Total Financing Holdings Costs</td>
                  <td>
                    ${{
                      details?.total_loan_payback_monthly * totalHoldingMonths
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- End code for BRRR Deal Type -->
      </div>
    </div>
  </div>
</div>
