import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../_services/auth.service';
import { PropertiesService } from '../../_services/properties.service';
import { CommonService } from '../../_services/common.service';
import { ContactsService } from '../../_services/contacts.service';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../_services/global.service';
import { Subscription } from 'rxjs';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var iniImageMagnify: Function;
declare var showPopup: Function;
declare var hidePopup: Function;

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss'],
})
export class SharedComponent implements OnInit {
  public environment = environment;
  submitted: boolean = false;
  showReport: boolean = false;
  public user;
  uniqueId: string;
  details: {};
  public allRecords;
  public shareUniqueId;
  public searchKeyword = '';
  public frontUrl = '';
  public sharingURL = '';
  public currentOrderType = 'DESC';
  public currentOrderField = 'id';
  public totalCashNeeded = 0;
  public selectedColumnsValues;
  public fileName;
  public filePath;
  quoteForm: FormGroup;
  subscription: Subscription;
  public imagesData;
  public loanData;
  public incomeProjections;
  public totalIncomesItems;
  public expensesProjections;
  public totalExpensesItems;
  public operatingIncomes;
  otherDetails: {};
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private globalservice: GlobalService,
    private _ContactsService: ContactsService,
    private _CommonService: CommonService,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
    this.subscription = this.globalservice.refreshUserData.subscribe((res) => {
      this.user = this._AuthService.getUserInfo();
    });
    console.log('this.user', this.user);
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.checkSharingReportStatus();
    this.subscription = this.globalservice.refreshReportData.subscribe(
      (res) => {
        this.checkSharingReportStatus();
      }
    );
  }

  checkSharingReportStatus() {
    var storeUniqueIds = localStorage.getItem('storeUniqueIds');
    if (storeUniqueIds != null) {
      var storeUniqueIdsArray = storeUniqueIds.split(',');
      if (storeUniqueIdsArray.includes(this.uniqueId)) {
        this.showReport = true;
      }
    }
  }

  ngOnInit() {
    this.quoteForm = this.formBuilder.group(
      {
        name: ['', Validators.required],
        email: ['', Validators.required],
        uniqueId: ['', Validators.required],
        phone_number: ['', Validators.required],
      },
      {}
    );
    this.getAllImagesByUniqueId();
    this.getSingleRecord();
    this.getSingleRecordOtherDetails();
    this.getProjectionsReportByUniqueId();
    this.getAllLoansRecordsByUniqueId();
  }

  getProjectionsReportByUniqueId() {
    startLoader();
    this._PropertiesService
      .getProjectionsReportByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.incomeProjections = response.data.incomeProjections;
            this.totalIncomesItems = response.data.totalIncomesItems;
            this.expensesProjections = response.data.expensesProjections;
            this.totalExpensesItems = response.data.totalExpensesItems;
            this.operatingIncomes = response.data.operatingIncomes;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getAllLoansRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllLoansRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.loanData = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecordOtherDetails() {
    startLoader();
    this._PropertiesService
      .singleRecordOtherDetailsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.otherDetails = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecord() {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.details = response.data;
            this.totalCashNeeded =
              response.data.total_purchase_costs +
              response.data.total_repair_renovation;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getAllImagesByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllImagesRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.imagesData = response.data;
            console.log('this.imagesData', this.imagesData);
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  get f() {
    return this.quoteForm.controls;
  }

  doSubmit() {
    var self = this;
    this.quoteForm.patchValue({ uniqueId: this.uniqueId });
    this.submitted = true;
    let data = this.quoteForm.value;
    if (this.quoteForm.invalid) {
      return;
    }
    console.log('--');
    startLoader();
    this._CommonService
      .submitReportUserDetails(this.quoteForm.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            stopLoader();
            checkTosterResponse(response.message, 'Success');
            var storeUniqueIds = localStorage.getItem('storeUniqueIds');
            if (storeUniqueIds == null) {
              var storeUniqueIds = this.uniqueId;
            } else {
              var storeUniqueIds = storeUniqueIds + ',' + this.uniqueId;
            }
            localStorage.setItem('storeUniqueIds', storeUniqueIds);
            self.globalservice.refreshReportData.next(true);
          } else {
            stopLoader();
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
          this.submitted = false;
        },
        error: (error) => {
          this.submitted = false;
          stopLoader();
        },
      });
  }

  downloadReportPdf() {
    startLoader();
    this._PropertiesService.downloadSharingReportPdf(this.uniqueId).subscribe(
      (response: any) => {
        console.log('response', response);
        if (response.status == 200) {
          this.fileName = response.data.fileName;
          this.filePath = response.data.filePath;
          console.log('filePath', this.filePath);
          window.open(this.filePath);
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  showImagePreview() {
    iniImageMagnify();
  }
}
