import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';
import { CommonService } from '../../../_services/common.service';
import { ContactsService } from '../../../_services/contacts.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var iniImageMagnify: Function;

@Component({
  selector: 'app-add-property-step1',
  templateUrl: './add-property-step1.component.html',
  styleUrls: ['./add-property-step1.component.css'],
})
export class AddPropertyStep1Component implements OnInit {
  public environment = environment;
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep1Form: FormGroup;
  imageForm: FormGroup;
  details: {};
  public imagesData;
  public contactsDropdown;
  public contactsAgentDropdown;
  public propertyTypesDropdown;
  productData: any;
  propertyarr: any = [];
  properties: FormArray;
  isMonthlyRent: any = 'Yes';

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private _ContactsService: ContactsService,
    private _CommonService: CommonService,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.getActiveContactsByUserId();
    this.getActiveAgentContactsByUserId();
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
    this.getAllMonthlyRentRecordsByUniqueId(this.uniqueId);
    this.getAllImagesByUniqueId();
    this.getAllActivePropertiesTypes();

    this.addPropertyStep1Form = this.formBuilder.group({
      property_name: ['', Validators.required],
      unique_id: ['', Validators.required],
      number_of_doors: ['', Validators.required],
      building_class: ['', ''],
      listing_id: ['', Validators.required],
      agent_id: ['', Validators.required],
      brokerage_id: ['', Validators.required],
      vacancy: ['', Validators.required],
      depreciation: ['', ''],
      land_value: ['', ''],
      street_address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      description: ['', Validators.required],
      about_city: ['', Validators.required],
      why_city: ['', Validators.required],
      total_monthly_rent: ['', Validators.required],
      is_monthly_rent: ['', Validators.required],
      // property_tags: ['', Validators.required],
      notes: ['', Validators.required],
      building_foundation: ['', Validators.required],
      building_style: ['', Validators.required],
      exterior_finish: ['', Validators.required],
      heat_fuel: ['', Validators.required],
      estimated_appreciation: ['', ''],
      estimated_income_increase: ['', ''],
      estimated_expenses_increase: ['', ''],
      expected_selling_costs: ['', ''],
      expected_loan_refinance: ['', ''],
      year_built: ['', Validators.required],
      square_footage: ['', Validators.required],
      ownership_type: ['', Validators.required],
      property_type: ['', Validators.required],
      lot_size: ['', Validators.required],
      property_pitch: ['', Validators.required],
      zoning: ['', Validators.required],
      status: ['Active', Validators.required],
      user_id: [this.user.id, Validators.required],
      deal_type_id: [this.user.id, Validators.required],
      propertiesData: ['', Validators.required],
      properties: this.formBuilder.array([]),
    });
    this.imageForm = this.formBuilder.group(
      {
        property_id: ['', Validators.required],
        unique_id: [this.uniqueId, Validators.required],
        user_id: [this.user.id, Validators.required],
        file: [],
      },
      {}
    );
  }

  getAllActivePropertiesTypes() {
    startLoader();
    this._CommonService.getAllActivePropertiesTypes().subscribe(
      (response: any) => {
        if (response.status == 200) {
          console.log(response.data);
          this.propertyTypesDropdown = response.data;
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  getActiveAgentContactsByUserId() {
    startLoader();
    this._ContactsService
      .getActiveAgentContactsByUserId(this.user.id)
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.contactsAgentDropdown = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (err) => {
          stopLoader();
        },
        () => stopLoader()
      );
  }

  getActiveContactsByUserId() {
    startLoader();
    this._ContactsService.getActiveContactsByUserId(this.user.id).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.contactsDropdown = response.data;
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  updateMonthlyRentField(event) {
    this.isMonthlyRent = event;
  }

  addProperty(): void {
    this.properties = this.addPropertyStep1Form.get('properties') as FormArray;
    this.properties.push(this.createProperty());
  }

  removeProperty(rowIndex: number) {
    this.properties.removeAt(rowIndex);
    this.calculateTotalMonthRent(0);
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      unit: '',
      number_beds: '',
      monthly_rent: '',
    });
  }

  calculateTotalMonthRent(amount) {
    if (this.isMonthlyRent == 'No') {
      let totalMonth = 0;
      var propertiesData = this.addPropertyStep1Form.value.properties;
      for (const singleRecord of propertiesData) {
        totalMonth = totalMonth + singleRecord.monthly_rent;
      }
      this.addPropertyStep1Form.patchValue({ total_monthly_rent: totalMonth });
    }
  }

  getAllImagesByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllImagesRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.imagesData = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getAllMonthlyRentRecordsByUniqueId(uniqueId) {
    startLoader();
    this._PropertiesService
      .getAllMonthlyRentRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.productData = response;
            let propertyarr = [];
            for (let i = 0; i <= this.productData.data.length; i++) {
              propertyarr.push(this.productData.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep1Form.controls['properties']
            );
            if (this.productData.data.length > 0) {
              for (let i = 0; i < this.productData.data.length; i++) {
                let form = this.formBuilder.group({
                  unit: [this.productData.data[i].unit],
                  monthly_rent: [this.productData.data[i].monthly_rent],
                  number_beds: [this.productData.data[i].number_beds],
                });
                control.push(form);
              }
            }
            this.properties = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.recordId = response.data.id;
            this.isMonthlyRent = response.data.is_monthly_rent;
            this.addPropertyStep1Form.patchValue(response.data);
            this.imageForm.patchValue({ property_id: response.data.id });
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  updateBrokerageByAgent(event) {
    this.addPropertyStep1Form.patchValue({ brokerage_id: event });
  }

  onAddPropertyStep1FormSubmit(type: any = "") {
    if (this.addPropertyStep1Form.value.property_name == '') {
      checkTosterResponse('Please enter Property Name.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.number_of_doors == '') {
      checkTosterResponse('Please enter Number of Doors.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.is_monthly_rent == '') {
      checkTosterResponse('Please enter total monthly rent.', 'Error');
      return false;
    } else {
      if (this.addPropertyStep1Form.value.is_monthly_rent == 'No') {
        var propertiesData = this.addPropertyStep1Form.value.properties;
        if (propertiesData.length < 1) {
          checkTosterResponse('Please enter monthly rents values.', 'Error');
          return false;
        } else {
          for (const singleRecord of propertiesData) {
            if (singleRecord.unit == '') {
              checkTosterResponse('Please enter monthly rents unit.', 'Error');
              return false;
            }
            if (singleRecord.monthly_rent == '') {
              checkTosterResponse('Please enter monthly rent.', 'Error');
              return false;
            }
            if (singleRecord.number_beds == '') {
              checkTosterResponse('Please enter number of beds.', 'Error');
              return false;
            }
          }
        }
      }
    }
    if (this.addPropertyStep1Form.value.total_monthly_rent == '') {
      checkTosterResponse('Please enter Total Monthly Rent.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.vacancy == '') {
      checkTosterResponse('Please enter Vacancy.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.street_address == '') {
      checkTosterResponse('Please enter Street address.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.city == '') {
      checkTosterResponse('Please enter City.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.state == '') {
      checkTosterResponse('Please enter State.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.country == '') {
      checkTosterResponse('Please enter Country.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.vacancy > 100) {
      checkTosterResponse('Please enter vacancy less than or equal of 100.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.estimated_appreciation > 100) {
      checkTosterResponse('Please enter estimated appreciation less than or equal of 100.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.estimated_income_increase > 100) {
      checkTosterResponse('Please enter estimated income increase less than or equal of 100.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.estimated_expenses_increase > 100) {
      checkTosterResponse('Please enter estimated expenses increase less than or equal of 100.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.expected_selling_costs > 100) {
      checkTosterResponse('Please enter expected selling costs less than or equal of 100.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.expected_loan_refinance > 100) {
      checkTosterResponse('Please enter expected loan refinance less than or equal of 100.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.depreciation > 100) {
      checkTosterResponse('Please enter depreciation less than or equal of 100.', 'Error');
      return false;
    }
    startLoader();
    this.addPropertyStep1Form.patchValue({ status: 'Active' });
    var propertiesData = this.addPropertyStep1Form.value.properties;
    this.addPropertyStep1Form.patchValue({
      propertiesData: JSON.stringify(propertiesData),
    });
    this._PropertiesService
      .updateRecord(this.recordId, this.addPropertyStep1Form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            if(type == "back") {
              this.router.navigate([
                '/properties/list',
              ]);
            } else {
              checkTosterResponse(response.message, 'Success');
              this.router.navigate([
                '/properties/add-property-step2/' + this.uniqueId,
              ]);
            }
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  clickToBack() {
    this.onAddPropertyStep1FormSubmit('back');
  }

  onFileChangeUploadNewImage(event) {
    console.log('event', event);
    const file = event.target.files[0];
    this.imageForm.patchValue({ file: file });
    this.imageForm.patchValue({ user_id: this.user.id });
    this.imageForm.patchValue({ property_id: this.recordId });
    this.imageForm.patchValue({ unique_id: this.uniqueId });

    this._PropertiesService
      .uploadNewImage(this.imageForm.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.getAllImagesByUniqueId();
            this.imageForm.reset();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  deleteImage(id) {
    this._PropertiesService
      .deleteImageById(id)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.getAllImagesByUniqueId();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  showImagePreview() {
    iniImageMagnify();
  }
}
