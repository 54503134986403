<div class="dashboard_content_wrap contactsListpage">
  <div class="dashboard_content Properties_AddDashFix">
    <div class="dash-head">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="page-titles">Contacts</h1>
        </div>
        <div class="col-auto">
          <div class="left-head">
            <a [routerLink]="['/contacts/add']" class="btn-style1"><i class="far fa-plus me-2"></i>Add New</a>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-container addStep_From">
      <div class="container">

        <div class="theme-card p-0">
          <div class="theme-table">

            <div class="table-header">
              <div class="row">

                <!-- <div class="col-12 col-md-auto">
                  <div class="table-search">
                    <i class="far fa-search"></i>
                    <input placeholder="Search here..." type="text" name="" id="">
                  </div>
                </div> -->

                <!-- <div class="col-auto ms-auto">
                  <div class="tablefilter-btn">
                    <div class="dropdown ms-2">
                      <button class="btn btnicon dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-filter"></i>
                        Filter
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                      </ul>
                    </div>
                    <button class="btn btnicon ms-2">
                      <i class="fas fa-cloud-download"></i>
                      Export
                    </button>
                  </div>
                </div> -->

              </div>
            </div>

            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Cell Phone</th>
                    <th>Email</th>
                    <th>Investor Type</th>
                    <th>Capital Available</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  
                  <tr *ngFor="let singleRecord of allRecords; let i = index">
                    <td>
                      {{singleRecord.first_name}}
                    </td>
                    <td>
                      {{singleRecord.last_name}}
                    </td>
                    <td>
                      {{singleRecord.cell_phone}}
                    </td>
                    <td>
                      {{singleRecord.email}}
                    </td>
                    <td>
                      {{singleRecord.investor_type}}
                    </td>
                    <td>
                      ${{singleRecord.funds_available}}
                    </td>
                    <td>
                      <div class="table-action">
                        <button routerLink="/contacts/update/{{singleRecord.id}}" class="btn btn-square btn-square-edit">
                          <i class="fas fa-pencil"></i>
                        </button>
                        <button routerLink="/contacts/view/{{singleRecord.id}}" class="btn btn-square btn-square-edit">
                          <i class="fas fa-eye"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="allRecords?.length == 0">
                    <td colspan="6">
                        <div class="alert alert-danger">
                          <strong>Nothing here!</strong>
                        </div>
                    </td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>

          <!-- <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item disabled">
                <a class="page-link" href="#!" aria-label="Previous">
                  <span aria-hidden="true"><i class="far fa-angle-left"></i></span>
                </a>
              </li>
              <li class="page-item active"><a class="page-link" href="#!">1</a></li>
              <li class="page-item"><a class="page-link" href="#!">2</a></li>
              <li class="page-item"><a class="page-link" href="#!">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#!" aria-label="Next">
                  <span aria-hidden="true"><i class="far fa-angle-right"></i></span>
                </a>
              </li>
            </ul>
          </nav> -->

        </div>
      </div>
      <!-- dashboard-container  END -->
    </div>
  </div>