import { Pipe, PipeTransform } from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'makePositive'
})
export class MakePositivePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let numberValue = Math.abs(Number(value)).toFixed(2);
    return formatNumber(Number(numberValue), 'en-US', '1.2-2')
  }

}
