import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  refreshUserData = new Subject<boolean>();
  refreshUserAuth = new Subject<boolean>();
  refreshReportData = new Subject<boolean>();
  refreshUserSubscriptionData = new Subject<boolean>();
  constructor() { }
}
