import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { ContactsService } from '../../../_services/contacts.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-update-contacts',
  templateUrl: './update-contacts.component.html',
  styleUrls: ['./update-contacts.component.css'],
})
export class UpdateContactsComponent implements OnInit {
  public environment = environment;
  updateContactForm: FormGroup;
  public user;
  id: string;
  defaultImage = true;
  imgsrc: any = '';
  image_name: any = '';
  public imageURL;
  public maxNumber = 100;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _ContactsService: ContactsService,
    private _AuthService: AuthService,
    private _location: Location
  ) {
    this.user = this._AuthService.getUserInfo();
    console.log(this.user);
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    startLoader();
    this._ContactsService
      .getById(this.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.updateContactForm.patchValue(response.data);
            this.defaultImage = false;
            this.image_name = response.data.image_name;
          } else {
            checkTosterResponse(response.message, 'Error');
          }

          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );

    this.updateContactForm = this.formBuilder.group(
      {
        first_name: ['', Validators.required],
        email: ['', Validators.required],
        company: ['', Validators.required],
        job_title: ['', Validators.required],
        address: ['', Validators.required],
        source: ['', Validators.required],
        facebook_link: ['', Validators.required],
        image_name: ['', Validators.required],
        real_estate_agent: ['', Validators.required],
        file: [],
        linkedin_link: ['', Validators.required],
        instagram_link: ['', Validators.required],
        content: ['', Validators.required],
        twitter_link: ['', Validators.required],
        website: ['', Validators.required],
        work_phone_type: ['', Validators.required],
        work_phone: ['', Validators.required],
        cell_phone_type: ['', Validators.required],
        cell_phone: ['', Validators.required],
        investor_type: ['', Validators.required],
        funds_available: ['', Validators.required],
        last_name: ['', Validators.required],
      },
      {}
    );
  }

  processFundsAvailable(fundsAvailable): void {
    var fundsAvailable = fundsAvailable.replace(/[^a-zA-Z0-9]/g, '');
    let numberVal = parseInt(fundsAvailable).toLocaleString();
    this.updateContactForm.patchValue({funds_available: numberVal});
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  onupdateContactFormSubmit() {
    if (this.updateContactForm.value.first_name == '') {
      checkTosterResponse('Please enter first name.', 'Error');
      return false;
    }
    if (this.updateContactForm.value.last_name == '') {
      checkTosterResponse('Please enter last name.', 'Error');
      return false;
    }
    if (this.updateContactForm.value.email == '') {
      checkTosterResponse('Please enter email.', 'Error');
      return false;
    }
    if (this.updateContactForm.value.cell_phone == '') {
      checkTosterResponse('Please enter cell phone.', 'Error');
      return false;
    }
    if (this.updateContactForm.value.source == '') {
      checkTosterResponse('Please select source.', 'Error');
      return false;
    }
    if (this.updateContactForm.value.real_estate_agent == '') {
      checkTosterResponse("Please select real estate agent.", "Error");
      return false;
    }
    if (this.updateContactForm.value.investor_type == '') {
      checkTosterResponse("Please select investor type.", "Error");
      return false;
    }
    startLoader();
    this._ContactsService
      .update(this.id, this.updateContactForm.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            checkTosterResponse(response.message, 'Success');
            this._location.back();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  checkCellPhoneSMSPhone(cell_phone) {
    if(this.updateContactForm.value.cell_phone_type == "Number") {
      var cell_phone = cell_phone.replace(/[^a-zA-Z0-9]/g, '');
    }
      let newNumberD = '';
      var array = cell_phone.split("");
      if(Number(cell_phone)) {
        this.updateContactForm.patchValue({cell_phone_type: 'Number'});
        if(cell_phone.length > 3) {
          newNumberD += "("+array[0]+array[1]+array[2]+") ";
          if(array[3]) {
            newNumberD += array[3];
          }
          if(array[4]) {
            newNumberD += array[4];
          }
          if(array[5]) {
            newNumberD += array[5];
          }
          if(cell_phone.length > 9) {          
            if(array[6]) {
              newNumberD += '-';
              newNumberD += array[6];
            }
            if(array[7]) {
              newNumberD += array[7];
            }
            if(array[8]) {
              newNumberD += array[8];
            }
            if(array[9]) {
              newNumberD += array[9];
            }
          } else {
            if(array[6]) {
              newNumberD += '-'
              newNumberD += array[6];
            }
            if(array[7]) {
              newNumberD += array[7];
            }
            if(array[8]) {
              newNumberD += array[8];
            }
            if(array[9]) {
              newNumberD += array[9];
            }
          }
          this.maxNumber = 14;
          this.updateContactForm.patchValue({cell_phone: newNumberD});
        } else {
          this.updateContactForm.patchValue({cell_phone: cell_phone});
        }
      } 
  }

  checkWorkPhoneSMSPhone(work_phone) {
    if(this.updateContactForm.value.work_phone_type == "Number") {
      var work_phone = work_phone.replace(/[^a-zA-Z0-9]/g, '');
    }
      let newNumberD = '';
      var array = work_phone.split("");
      if(Number(work_phone)) {
        this.updateContactForm.patchValue({work_phone_type: 'Number'});
        if(work_phone.length > 3) {
          newNumberD += "("+array[0]+array[1]+array[2]+") ";
          if(array[3]) {
            newNumberD += array[3];
          }
          if(array[4]) {
            newNumberD += array[4];
          }
          if(array[5]) {
            newNumberD += array[5];
          }
          if(work_phone.length > 9) {          
            if(array[6]) {
              newNumberD += '-';
              newNumberD += array[6];
            }
            if(array[7]) {
              newNumberD += array[7];
            }
            if(array[8]) {
              newNumberD += array[8];
            }
            if(array[9]) {
              newNumberD += array[9];
            }
          } else {
            if(array[6]) {
              newNumberD += '-'
              newNumberD += array[6];
            }
            if(array[7]) {
              newNumberD += array[7];
            }
            if(array[8]) {
              newNumberD += array[8];
            }
            if(array[9]) {
              newNumberD += array[9];
            }
          }
          this.maxNumber = 14;
          this.updateContactForm.patchValue({work_phone: newNumberD});
        } else {
          this.updateContactForm.patchValue({work_phone: work_phone});
        }
      } 
  }

  onFileChange(event) {
    const file = event.target.files[0];
    this.updateContactForm.patchValue({ file: file });
    this.defaultImage = false;

    const reader = new FileReader();
    this.imageURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgsrc = reader.result;
    };
  }
}
