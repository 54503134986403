<div class="dashboard_content_wrap">
  <div class="dashboard_content Properties_AddDashFix">
    <form method="post" id="email-form" [formGroup]="updateContactForm" class="addStep_From">
      <div class="dash-head">
        <div class="row align-items-center">
          <div class="col">
            <h1 class="page-titles">View Contacts </h1>
          </div>
        </div>
      </div>
      <div class="dashboard-container">
        <div class="container">
          <div class="theme-card">
            <div class="contact-detail-row">
              <div class="row">
                <div class="col-lg-8 cd-col-1 pe-xl-4 order-2 order-lg-1">
                  <div class="cd-form">

                    <div class="cd-form-row">
                      <label>First Name</label>
                      <input readonly class="form-control" type="text" formControlName="first_name" id="">
                    </div>

                    <div class="cd-form-row">
                      <label>Last Name</label>
                      <input readonly class="form-control" type="text" formControlName="last_name" id="">
                    </div>
                    <div class="cd-form-row">
                      <label>Email</label>
                      <input readonly class="form-control" type="text" formControlName="email" id="">
                    </div>
                    <div class="cd-form-row">
                      <label>Cell Phone</label>
                      <input readonly class="form-control" type="text" formControlName="cell_phone" id="">
                    </div>
                    
                    <div class="cd-form-row">
                      <label>Company</label>
                      <input readonly class="form-control" type="text" formControlName="company" id="">
                    </div>

                    <div class="cd-form-row">
                      <label>Job Title</label>
                      <input readonly class="form-control" type="text" formControlName="job_title" id="">
                    </div>

                    <div class="cd-form-row">
                      <label>Work Phone</label>
                      <input readonly class="form-control" type="text" formControlName="work_phone" id="">
                    </div>
                    <div class="cd-form-row">
                      <label>Website</label>
                      <input readonly class="form-control" type="text" formControlName="website" id="">
                    </div>

                    <div class="cd-form-row">
                      <label>Address</label>
                      <textarea readonly class="form-control" formControlName="address" id="" cols="" rows=""></textarea>
                    </div>

                    <div class="cd-form-row">
                      <label>Source</label>
                      <select disabled class="form-select" formControlName="source" id="">
                        <option value="">--</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Linkedin">Linkedin</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Networking Event">Networking Event</option>
                        <option value="Referral">Referral</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div class="cd-form-row">
                      <label>Facebook Link</label>
                      <input disabled class="form-control" type="text" formControlName="facebook_link" id="">
                    </div>

                    <div class="cd-form-row">
                      <label>Linkedin Link</label>
                      <input disabled class="form-control" type="text" formControlName="linkedin_link" id="">
                    </div>

                    <div class="cd-form-row">
                      <label>Instagram Link</label>
                      <input disabled class="form-control" type="text" formControlName="instagram_link" id="">
                    </div>

                    <div class="cd-form-row">
                      <label>Twitter Link</label>
                      <input disabled class="form-control" type="text" formControlName="twitter_link" id="">
                    </div>
                    <!-- <div class="cd-form-row">
                      <label>Content</label>
                      <div>{{contentContent}}</div>
                    </div> -->

                  </div>
                </div>
                <div class="col-lg-4 cd-col-2 order-1 order-lg-2">
                  <div class="cd-user-details">
                    <!-- User Avatar -->
                    <div class="cduser-wall">
                      <figure>
                        <img src="assets/img/userwall.jpg" alt="" *ngIf="defaultImage">
                        <img [src]="imgsrc" id="upload_img" class="roundk" *ngIf="imgsrc">
                        <img [src]="imgsrc" src="{{environment.apiUrl+'/uploads/contacts/'+image_name}}" class="roundk" *ngIf="!imgsrc">
                      </figure>
                    </div>
                    <!-- Investor Type -->
                    <div class="iv-type">
                      <div class="ivtype-head">Real Estate Agent : {{details?.real_estate_agent}}</div>
                    </div>
                    <div class="iv-type">
                      <div class="ivtype-head">Investor Type : {{details?.investor_type}}</div>
                    </div>
                    <div class="cd-form-row">
                      <label>Capital Available ($) : {{details?.funds_available}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>