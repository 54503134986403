<div class="loginsign-wrap">
  <div class="loginform-wrap">
    <div class="loginlogo">
      <img src="assets/img/logo.svg" alt="">
    </div>
    <div class="login-form default-form">
      <div class="form-inner">
        <h3>Sign up</h3>
        <!--Signup Form-->
        <form class="pt-3" [formGroup]="signupForm" (ngSubmit)="doSignup()">

          <div class="row g-3">
            <div class="col-md-6">
              <div class="form-group">
                <label>First Name</label>
                <input class="form-control" formControlName="first_name" type="text"  placeholder="">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Last Name</label>
                <input class="form-control" formControlName="last_name" type="text" placeholder="">
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Email</label>
            <input class="form-control" formControlName="email" type="text" placeholder="">
          </div>

          <div class="form-group">
            <label>Password</label>
            <input class="form-control" formControlName="password" id="password-field" type="password" value="" placeholder="">
          </div>

          <div class="form-group mt-4">
            <button class="loginbtn" type="submit">Sign up</button>
          </div>
        </form>

        <div class="bottom-box">
          <div class="text">Have an account? <a [routerLink]="['/user/login']" class="call-modal signup">Login</a>
          </div>
          <!-- <div class="divider"><span>or</span></div>
          <div class="btn-box row">
            <div class="col-lg-6 col-md-12">
              <a href="#" class="theme-btn social-btn-two facebook-btn"><i class="fab fa-facebook-f"></i> Log In via
                Facebook</a>
            </div>
            <div class="col-lg-6 col-md-12">
              <a href="#" class="theme-btn social-btn-two google-btn"><i class="fab fa-google"></i> Log In via Gmail</a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</div>
