<div class="customtabs_btns position-relative" *ngIf="stageType == '1'">
  <ul class="list-unstyled mb-10">
    <li [ngClass]="{'active': 1 <= currentSelectedStage}">
      <a href="javascript:void(0);" (click)="completeLevel >= 1 ? false : showErrorForEditProperty()"
        [routerLink]="completeLevel >= 1 ? ['/properties/view-property-step1', uniqueId] : []">
        <span class="tabcount">1</span>
        Property
      </a>
    </li>
    <li [ngClass]="{'active': 2 <= currentSelectedStage}">
      <a href="javascript:void(0);" (click)="completeLevel >= 2 ? false : showErrorForEditProperty()"
        [routerLink]="completeLevel >= 2 ? ['/properties/view-property-step2', uniqueId] : []">
        <span class="tabcount">2</span>
        Financing
      </a>
    </li>
    <li [ngClass]="{'active': 3 <= currentSelectedStage}">
      <a href="javascript:void(0);" (click)="completeLevel >= 3 ? false : showErrorForEditProperty()"
        [routerLink]="completeLevel >= 3 ? ['/properties/view-property-step3', uniqueId] : []">
        <span class="tabcount">3</span>
        Investment
      </a>
    </li>
    <li [ngClass]="{'active': 4 <= currentSelectedStage}">
      <a href="javascript:void(0);" (click)="completeLevel >= 4 ? false : showErrorForEditProperty()"
        [routerLink]="completeLevel >= 4 ? ['/properties/view-property-step4', uniqueId] : []">
        <span class="tabcount">4</span>
        Income
      </a>
    </li>
    <li [ngClass]="{'active': 5 <= currentSelectedStage}">
      <a href="javascript:void(0);" (click)="completeLevel >= 5 ? false : showErrorForEditProperty()"
        [routerLink]="completeLevel >= 5 ? ['/properties/view-property-step5', uniqueId] : []">
        <span class="tabcount">5</span>
        Expenses
      </a>
    </li>
    <li [ngClass]="{'active': 6 <= currentSelectedStage}">
      <a href="javascript:void(0);" (click)="completeLevel >= 6 ? false : showErrorForEditProperty()"
        [routerLink]="completeLevel >= 6 ? ['/properties/view-property-step6', uniqueId] : []">
        <span class="tabcount">6</span>
        Payback
      </a>
    </li>
    <li [ngClass]="{'active': 7 <= currentSelectedStage}">
      <a href="javascript:void(0);" (click)="completeLevel >= 7 ? false : showErrorForEditProperty()"
        [routerLink]="completeLevel >= 7 ? ['/properties/view-property-step7', uniqueId] : []">
        <span class="tabcount">7</span>
        Performance
      </a>
    </li>
    <li [ngClass]="{'active': 7 <= currentSelectedStage}">
      <a href="javascript:void(0);" (click)="completeLevel >= 7 ? false : showErrorForEditProperty()"
        [routerLink]="completeLevel >= 7 ? ['/properties/view-property-step8', uniqueId] : []">
        <span class="tabcount">8</span>
        Summary
      </a>
    </li>
  </ul>
</div>