import { Component, OnInit } from '@angular/core';
import { SubscriptionsService } from '../../_services/subscriptions.service';
import { environment } from '../../../environments/environment';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { first } from 'rxjs/operators';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  public user;
  public environment = environment;
  public allPlansRecords;
  priceType = 'Monthly';
  selectedClass = '';
  checkout_session_id: any;
  plan_subscription_id: any;
  user_id: any;

  constructor(
    private _SubscriptionsService: SubscriptionsService,
    private _AuthService: AuthService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.user = this._AuthService.getUserInfo();
    this.route.queryParams.subscribe((params) => {
      this.plan_subscription_id = params.plan_subscription_id;
      this.checkout_session_id = params.checkout_session_id;
    });
  }

  ngOnInit(): void {
    this.createSubscription();
  }

  createSubscription() {
    let data = {
      plan_subscription_id: this.plan_subscription_id,
      checkout_session_id: this.checkout_session_id,
      user_id: this.user.id,
    };
    this._SubscriptionsService
      .createSubscription(data)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            checkTosterResponse(response.message, 'Success');
            setTimeout(function () {
              this.router.navigate(['/memberships']);
            }, 2000);
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }
}
