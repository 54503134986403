<div class="dashboard_content ">
  <div class="dash-head">
    <div class="row align-items-center">
      <div class="col-6">
        <h1 class="page-titles">Subscription</h1>
      </div>
    </div>
  </div>
  <div class="dashboard-container ">
    <div class="pricing-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div id='card' class="subScribCard ">
              <div id='upper-side' class="bg-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                </svg>
                <h3 id='status'>
                  Failed
                </h3>
              </div>
              <div id='lower-side'>
                <p id='message'>
                  Sorry!, your subscription has been canceled. Payment not completed.
                </p>
                <a href="javascript:void(0);" class="btn-style1 d-inline-block">Continue</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>