import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { AuthService } from '../../_services/auth.service';
import { GlobalService } from '../../_services/global.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;

  constructor(
    private _UserService: UserService,
    private _AuthService: AuthService,
    private globalservice: GlobalService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public doLogin() {
    let data = this.loginForm.value;
    if (this.loginForm.value.email == '') {
      checkTosterResponse('Email is required', 'Error');
      return false;
    }
    if (this.loginForm.value.password == '') {
      checkTosterResponse('Password is required', 'Error');
      return false;
    }
    startLoader();
    this._UserService.login(data).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this._AuthService.setAuth(response.data);
          this.globalservice.refreshUserAuth.next(true);
          this.router.navigate(['/dashboard']);
          checkTosterResponse(response.message, 'Success');
          return false;
        } else {
          checkTosterResponse(response.message, 'Error');
          return false;
        }
        stopLoader();
      },
      (error) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }
}
