import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { AuthService } from '../../_services/auth.service';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../_services/global.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  public environment = environment;
  public user;
  changePasswordform: FormGroup;
  imageForm: FormGroup;
  myProfileform: FormGroup;
  imgsrc: any = "";
  profile_image: any = "";
  public imageURL;
  companyProfileform: FormGroup;
  imgcompanysrc: any = "";
  imgcompanysrc_1: any = "";
  imgcompanysrc_2: any = "";
  imgcompanysrc_3: any = "";
  company_image: any = "";
  acquisition_image_1: any = "";
  acquisition_image_2: any = "";
  acquisition_image_3: any = "";
  public imagecompanyURL;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _AuthService: AuthService,
    private globalservice: GlobalService,
    private _UserService: UserService
  ) {
    this.user = this._AuthService.getUserInfo();
    this.profile_image = this.user.profile_image;
    this.company_image = this.user.company_image;
    if(this.user.acquisition_image_1 != 'null') {
      this.acquisition_image_1 = this.user.acquisition_image_1;
    }
    if(this.user.acquisition_image_2 != 'null') {
      this.acquisition_image_2 = this.user.acquisition_image_2;
    }
    if(this.user.acquisition_image_3 != 'null') {
      this.acquisition_image_3 = this.user.acquisition_image_3;
    }
  }

  ngOnInit(): void {
    this.changePasswordform = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {});

    this.profile_image = this.user.profile_image;
    this.myProfileform = this.formBuilder.group({
      first_name: [this.user.first_name, Validators.required],
      last_name: [this.user.last_name, Validators.required],
      about_me: [this.user.about_me, Validators.required],
      phone_number: [this.user.phone_number, Validators.required],
      profile_image: [this.user.profile_image, Validators.required],
      user_id: [this.user.id, Validators.required],
      file: [],
      email: [this.user.email, Validators.required],
    }, {});

    this.company_image = this.user.company_image;
    this.companyProfileform = this.formBuilder.group({
      company_about: [this.user.company_about, Validators.required],
      company_name: [this.user.company_name, Validators.required],
      company_image: [this.user.company_image, Validators.required],
      acquisition_title_1: [this.user.acquisition_title_1, Validators.required],
      acquisition_title_2: [this.user.acquisition_title_2, Validators.required],
      acquisition_title_3: [this.user.acquisition_title_3, Validators.required],
      user_id: [this.user.id, Validators.required],
      file: [],
    }, {});

    this.imageForm = this.formBuilder.group({
      image_type: ['', Validators.required],
      user_id: [this.user.id, Validators.required],
      file: [],
    }, {});
  }

  onSubmitChangePassword() {
    if (this.changePasswordform.value.oldPassword == '') {
      checkTosterResponse("Old Password is required", "Error");
      return false;
    }
    if (this.changePasswordform.value.newPassword == '') {
      checkTosterResponse("New Password is required", "Error");
      return false;
    }
    if (this.changePasswordform.value.confirmPassword == '') {
      checkTosterResponse("Confirm Password is required", "Error");
      return false;
    }
    if (this.changePasswordform.value.newPassword != this.changePasswordform.value.confirmPassword) {
      checkTosterResponse("Confirm password didn't matched with the new password.", "Error");
      return false;
    }
    startLoader();
    let data = {
      old_password: this.changePasswordform.value.oldPassword,
      new_password: this.changePasswordform.value.newPassword,
      user_id: this.user.id
    }
    this._UserService.changePassword(data)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.changePasswordform.reset();
            checkTosterResponse(response.message, "Success");
            this.router.navigate(['/my-profile']);
          } else {
            stopLoader();
            checkTosterResponse(response.message, "Error");
            return false;
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  onSubmitMyProfile() {
    if (this.myProfileform.value.first_name == '') {
      checkTosterResponse("First name is required", "Error");
      return false;
    }
    if (this.myProfileform.value.last_name == '') {
      checkTosterResponse("Last name is required", "Error");
      return false;
    }
    if (this.myProfileform.value.email == '') {
      checkTosterResponse("Email is required", "Error");
      return false;
    }
    if (this.myProfileform.value.phone_number == '') {
      checkTosterResponse("Phone number is required", "Error");
      return false;
    }
    // if (this.myProfileform.value.about_me == '') {
    //   checkTosterResponse("About me is required", "Error");
    //   return false;
    // }
    startLoader();
    this._UserService.updateProfile(this.myProfileform.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.getUserProfileDetails('Yes');
          } else {
            stopLoader();
            checkTosterResponse(response.message, "Error");
            return false;
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  private getUserProfileDetails(message) {
    this._UserService.getUserProfile(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this._AuthService.updateUserInfo(response.data);
            if(message === 'Yes') {
              checkTosterResponse(response.message, "Success");
            }
            this.globalservice.refreshUserData.next(true);
            // this.router.navigate(['/my-profile']);
          } else {
            checkTosterResponse(response.message, "Error");
            return false;
          }
        },
        (error) => {
          
        }
      );
  }

  onFileChangeMyProfile(event) {
    const file = event.target.files[0];
    this.myProfileform.patchValue({ file: file });
    const reader = new FileReader();
    this.imageURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgsrc = reader.result;
    }
  }

  onFileChangeCompanyImage(event) {
    const file = event.target.files[0];
    this.companyProfileform.patchValue({ file: file });
    const reader = new FileReader();
    this.imagecompanyURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgcompanysrc = reader.result;
    }
  }
  
  uploadImage(data) {
    this._UserService
      .updateAcquisitionImage(data)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            //checkTosterResponse(response.message, 'Success');
            // this.getAllImagesByUniqueId();
            // this.imageForm.reset();
            this.getUserProfileDetails('No');
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  onFileChangeAcquisitionImage_1(event) {
    const file = event.target.files[0];
    this.imageForm.patchValue({ file: file });
    this.imageForm.patchValue({ user_id: this.user.id });
    this.imageForm.patchValue({ image_type: 'acquisition_image_1' });

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgcompanysrc_1 = reader.result;
    }
    this.uploadImage(this.imageForm.value);
  }
  
  onFileChangeAcquisitionImage_2(event) {
    const file = event.target.files[0];
    this.imageForm.patchValue({ file: file });
    this.imageForm.patchValue({ user_id: this.user.id });
    this.imageForm.patchValue({ image_type: 'acquisition_image_2' });

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgcompanysrc_2 = reader.result;
    }
    this.uploadImage(this.imageForm.value);
  }
  
  onFileChangeAcquisitionImage_3(event) {
    const file = event.target.files[0];
    this.imageForm.patchValue({ file: file });
    this.imageForm.patchValue({ user_id: this.user.id });
    this.imageForm.patchValue({ image_type: 'acquisition_image_3' });

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgcompanysrc_3 = reader.result;
    }
    this.uploadImage(this.imageForm.value);
  }

  onSubmitCompanyProfile() {
    if (this.companyProfileform.value.company_name == '') {
      checkTosterResponse("Company name is required", "Error");
      return false;
    }
    if (this.companyProfileform.value.company_about == '') {
      checkTosterResponse("Company about is required", "Error");
      return false;
    }
    if (this.companyProfileform.value.acquisition_title_1 == '') {
      checkTosterResponse("Acquisition title 1 is required", "Error");
      return false;
    }
    if (this.companyProfileform.value.acquisition_title_2 == '') {
      checkTosterResponse("Acquisition title 2 is required", "Error");
      return false;
    }
    if (this.companyProfileform.value.acquisition_title_3 == '') {
      checkTosterResponse("Acquisition title 3 is required", "Error");
      return false;
    }
    startLoader();
    this._UserService.updateCompanyProfile(this.companyProfileform.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.getUserProfileDetails('Yes');
          } else {
            stopLoader();
            checkTosterResponse(response.message, "Error");
            return false;
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }


}