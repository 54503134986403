import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-wholesale-property-step2',
  templateUrl: './wholesale-property-step2.component.html',
  styleUrls: ['./wholesale-property-step2.component.css'],
})
export class WholesalePropertyStep2Component implements OnInit {

  uniqueId: string;
  recordId: string;
  dealTypeId: number;
  public user;
  addPropertyStep3Form: FormGroup;
  details: {};
  public currencySymbol = "$";
  public advertisingAmount: any = 0;
  public attorney_feesAmount: any = 0;
  public bird_dog_feesAmount: any = 0;
  public totalSellingCostsAmount: any = 0;
  purchaseCostItems: any;
  purchaseItemArray: any = [];
  purchaseItems: FormArray;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
    this.getAllPurhaseCostsRecordsByUniqueId();

    this.addPropertyStep3Form = this.formBuilder.group({
      currency: ['', Validators.required],
      acquisition_price: ['', Validators.required],
      assignment_fee: ['', Validators.required],
      wholesale_price: ['', Validators.required],
      after_repair_value: ['', Validators.required],
      advertising: ['', Validators.required],
      attorney_fees: ['', Validators.required],
      bird_dog_fees: ['', Validators.required],
      total_selling_costs: ['', Validators.required],
      purchaseCostsData: ['', Validators.required],
      purchaseItems: this.formBuilder.array([]),
      unique_id: ['', Validators.required],
      user_id: [this.user.id, Validators.required],
    });
  }

  updateCurrencySymbol(event) {
    this.addPropertyStep3Form.patchValue({currency_symbol: '$'});
    this.currencySymbol = "$";
    console.log(event);
  }

  getAllPurhaseCostsRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllItemsRecordsByUniqueId(this.uniqueId, 'Wholesale_Costs')
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.purchaseCostItems = response;
            let purchaseItemArray = [];
            for (let i = 0; i <= this.purchaseCostItems.data.length; i++) {
              purchaseItemArray.push(this.purchaseCostItems.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep3Form.controls['purchaseItems']
            );
            if (this.purchaseCostItems.data.length > 0) {
              for (let i = 0; i < this.purchaseCostItems.data.length; i++) {
                let form = this.formBuilder.group({
                  item_name: [this.purchaseCostItems.data[i].item_name],
                  item_amount: [this.purchaseCostItems.data[i].item_amount],
                  item_type: [this.purchaseCostItems.data[i].item_type],
                });
                control.push(form);
              }
            }
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  updateFieldValue(amount, type) {
    if (type == 'advertising') {
      this.advertisingAmount = amount;
    } else if (type == 'attorney_fees') {
      this.attorney_feesAmount = amount;
    } else if (type == 'bird_dog_fees') {
      this.bird_dog_feesAmount = amount;
    }
    this.calculateTotalSellingCosts();
  }

  calculateTotalSellingCosts() {
    this.totalSellingCostsAmount =
      parseInt(this.advertisingAmount) +
      parseInt(this.attorney_feesAmount) +
      parseInt(this.bird_dog_feesAmount);
    let totalSellingCostsAmountValue = this.totalSellingCostsAmount;
    var purchaseItemsCostData = this.addPropertyStep3Form.value.purchaseItems;
    for (const singleRecord of purchaseItemsCostData) {
      totalSellingCostsAmountValue =
        totalSellingCostsAmountValue + parseInt(singleRecord.item_amount);
    }
    this.addPropertyStep3Form.patchValue({
      total_selling_costs: totalSellingCostsAmountValue,
    });
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.recordId = response.data.id;
            this.dealTypeId = response.data.deal_type_id;
            this.addPropertyStep3Form.patchValue(response.data);
            this.advertisingAmount = response.data.advertising?response.data.advertising:0;
            this.attorney_feesAmount = response.data.attorney_fees?response.data.attorney_fees:0;
            this.bird_dog_feesAmount = response.data.bird_dog_fees?response.data.bird_dog_fees:0;
            // this.calculateTotalSellingCosts();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  onAddPropertyStep3FormSubmit() {
    if (this.addPropertyStep3Form.value.currency == '') {
      checkTosterResponse('Please enter currency.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.acquisition_price == '') {
      checkTosterResponse('Please enter acquisition price.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.assignment_fee == '') {
      checkTosterResponse('Please enter assignment fee.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.wholesale_price == '') {
      checkTosterResponse('Please enter wholesale price.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.after_repair_value == '') {
      checkTosterResponse('Please enter after repair price.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.advertising == '') {
      checkTosterResponse('Please enter advertising.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.attorney_fees == '') {
      checkTosterResponse('Please enter attorney fees.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.bird_dog_fees == '') {
      checkTosterResponse('Please enter bird dog fees.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.total_selling_costs == '') {
      checkTosterResponse('Please enter total selling costs.', 'Error');
      return false;
    }
    var purchaseItemsData = this.addPropertyStep3Form.value.purchaseItems;
    if (purchaseItemsData.length >= 1) {
      for (const singleRecord of purchaseItemsData) {
        if (singleRecord.item_type == '') {
          checkTosterResponse('Please enter item type.', 'Error');
          return false;
        }
        if (singleRecord.item_name == '') {
          checkTosterResponse('Please enter item name.', 'Error');
          return false;
        }
        if (singleRecord.item_amount == '') {
          checkTosterResponse('Please enter item amount.', 'Error');
          return false;
        }
      }
    }
    startLoader();
    var purchaseCostsData = this.addPropertyStep3Form.value.purchaseItems;
    this.addPropertyStep3Form.patchValue({
      purchaseCostsData: JSON.stringify(purchaseCostsData),
    });
    console.log(this.addPropertyStep3Form.value);
    this._PropertiesService
      .updateWholesaleRecordStep2(
        this.recordId,
        this.addPropertyStep3Form.value
      )
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            checkTosterResponse(response.message, 'Success');
            this.router.navigate([
              '/properties/wholesale-property-step3/' + this.uniqueId,
            ]);
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  addProperty(): void {
    this.purchaseItems = this.addPropertyStep3Form.get(
      'purchaseItems'
    ) as FormArray;
    this.purchaseItems.push(this.createProperty());
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      item_type: 'Wholesale_Costs',
      item_name: '',
      item_amount: '',
    });
  }

}
