import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private _ApiService: ApiService) { }
  
  getSingleCMSPageBySlug(slug) {
    return this._ApiService.get('api/cmspages/getCmsPage/'+slug); //Done
  }

  getSearchFilterData(data) {
    return this._ApiService.post('api/search/list', data); //Done
  }
  
  submitReportUserDetails(data) {
    return this._ApiService.post('api/common/submitReportUserDetails', data); //Done
  }

  updateUserColumnsData(data) {
    return this._ApiService.post('api/common/updateUserColumnsData', data); //Done
  }

  getPropertyColumnByUser(userId) {
    return this._ApiService.get('api/common/getPropertyColumnByUser/'+userId); //Done
  }

  getAllActivePropertiesTypes() {
    return this._ApiService.get('api/propertycategories/getAllActiveRecords'); //Done
  }


}