import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-wholesale-property-step4',
  templateUrl: './wholesale-property-step4.component.html',
  styleUrls: ['./wholesale-property-step4.component.css'],
})
export class WholesalePropertyStep4Component implements OnInit {
  
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep8Form: FormGroup;
  dealTypeId: number;
  public details;

  constructor(
    private route: ActivatedRoute,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService,
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.recordId = response.data.id;
            this.dealTypeId = response.data.deal_type_id;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

}
