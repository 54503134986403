<div class="dashboard_content_wrap">
  <div class="dashboard_content ">
    <div class="dash-head">
      <div class="row align-items-center">
        <div class="col-6">
          <h1 class="page-titles">Hello, <span>{{user.first_name}} {{user.last_name}}</span></h1>
        </div>
        <div class="col-6">
          <div class="left-head">
            <a [routerLink]="['/properties/add-property']" class="btn-style1 d-inline-block"><i
                class="fas fa-plus-circle me-1"></i> New Property</a>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-container ">
      <div class="container">
        <div class="row g-4">
          <div class="col-md-6 col-lg-4">
            <figure class="highcharts-figure">
              <div id="deal_pipeline"></div>
            </figure>
          </div>
          <div class="col-md-6 col-lg-4">
            <figure class="highcharts-figure">
              <div id="chart_deal_city"></div>
            </figure>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="dash-card">
              <div class="dashhead">Top Investors</div>
              <div class="dash-graph" *ngIf="allInvestors?.length > 0">
                <ul class="list-unstyled mb-0 topinvestor-list">
                  <li *ngFor="let singleRecord of allInvestors; let i = index">
                    <figure>
                      <img *ngIf="!singleRecord?.image_name" src="assets/img/userwall.jpg" alt="">
                      <img *ngIf="singleRecord?.image_name"
                        src="{{environment.apiUrl+'/uploads/contacts/'+singleRecord?.image_name}}" class="roundk">
                    </figure>
                    <div class="ti-text">
                      <span>{{singleRecord?.first_name}}</span>
                      <span>${{singleRecord?.funds_available }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="dash-card bluebg card_DashBlue">
              <div class="dashhead">Top Deals</div>
              <div class="dash-graph" *ngIf="allTopDeals?.length > 0">
                <ul class="list-unstyled mb-0 topdeals-list">
                  <li *ngFor="let singleRecord of allTopDeals; let i = index">
                    <figure>
                      <img src="assets/img/td-icon.png" alt="">
                    </figure>
                    <div class="ti-text">
                      <span>{{singleRecord?.property_name}}</span>
                      <span>${{singleRecord?.purchase_price | number : '1.2-2'}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <figure class="highcharts-figure">
              <div id="chart_deal_month"></div>
            </figure>
          </div>
          <div class="col-md-6 col-lg-4">
            <figure class="highcharts-figure">
              <div id="investor_by_source"></div>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>