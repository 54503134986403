import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class PaymentService {

  constructor(private _ApiService: ApiService) { }

  createCheckoutSession(data) {
    return this._ApiService.post('api/payment/create_checkout_session', data); //Done
  }

  
}