import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { ContactsService } from '../../../_services/contacts.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.css'],
})
export class ContactsListComponent implements OnInit {
  public allRecords;
  public user;

  constructor(
    private _ContactsService: ContactsService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
    console.log(this.user);
  }

  ngOnInit(): void {
    this.getAllContacts();
  }

  getAllContacts() {
    startLoader();
    let searchData = {
      user_id: this.user.id,
    };
    this._ContactsService.getAllRecordsByUserId(searchData).subscribe(
      (response: any) => {
        if (response.status == 200) {
          console.log(response.data);
          this.allRecords = response.data;
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }
}
