import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view-property-step8',
  templateUrl: './view-property-step8.component.html',
  styleUrls: ['./view-property-step8.component.css'],
})
export class ViewPropertyStep8Component implements OnInit {
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep8Form: FormGroup;
  dealTypeId: number;
  details: {};
  otherDetails: {};
  loanData: any;
  loanAmountMetrics: any = 0;
  purchasePriceAmount: any = 0;
  downPaymentAmount: any = 0;
  closingCostsAmount: any = 0;
  repairsRenovationsAmount: any = 0;
  totalInvestmentAmount: any = 0;
  afterRepairValueAmount: any = 0;
  pricePerUnitAmount: any = 0;
  pricePerUnitAfterRepairAmount: any = 0;
  grossRentAmount: any = 0;
  incomeAmount: any = 0;
  expensesAmount: any = 0;
  operatingIncomeAmount: any = 0;
  VacancyAmount: any = 0;
  NetOperatingIncomeAmount: any = 0;
  LoanPaymentsAmount: any = 0;
  cashFlowAmount: any = 0;
  cashFlowValue: any = 0;
  MortgagePaydownValue: any = 0;
  AppreciationValue: any = 0;
  TotalReturnValue: any = 0;
  TotalInvestmentValue: any = 0;
  totalROIValue: any = 0;
  ValueIncreaseAmount: any = 0;
  totalLoanAmount: any = 0;
  totalExpenseHoldingCostsAmount: any = 0;
  totalRefinanceCosts: any = 0;
  total_brrrr_cost: any = 0;
  expected_loan_refinance: any = 0;
  equity_available: any = 0;
  cashOutAmount: any = 0;
  brrrResultAmount: any = 0;
  brrrPerformanceValue: any = 0;
  total_expense_holding_costs: any = 0;
  total_financing_holdings_costs: any = 0;
  total_selling_costs: any = 0;
  total_flip_cost: any = 0;
  flip_result: any = 0;
  flip_return_on_investment: any = 0;

  public CashOnCashReturnValue: any = 0;
  public CAPRateSalePrice: any = 0;
  public CAPRatePurchasePrice: any = 0;
  public DebtCoverageRatioValue: any = 0;
  public AnnualGrossRentMultiplierValue: any = 0;
  public OperatingExpenseRatioValue: any = 0;

  constructor(
    private route: ActivatedRoute,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecordOtherDetails(this.uniqueId);
    this.getSingleRecord(this.uniqueId);
    this.getAllLoansRecordsByUniqueId();
  }

  getAllLoansRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllLoansRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.loanData = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecordOtherDetails(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordOtherDetailsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.otherDetails = response.data;
            this.grossRentAmount = response.data.gross_rent_yearly
              ? response.data.gross_rent_yearly
              : '0.00';
            this.incomeAmount = response.data.total_income_yearly
              ? response.data.total_income_yearly
              : '0.00';
            this.expensesAmount = response.data.total_expenses_yearly
              ? response.data.total_expenses_yearly
              : '0.00';
            this.operatingIncomeAmount =
              response.data.total_income_yearly -
              response.data.total_expenses_yearly;
            this.VacancyAmount = response.data.net_perfomance_vacancy_yearly
              ? response.data.net_perfomance_vacancy_yearly
              : '0.00';
            this.NetOperatingIncomeAmount =
              this.operatingIncomeAmount -
              response.data.net_perfomance_vacancy_yearly;
            this.CashOnCashReturnValue = response.data.cash_on_cash_return
              ? response.data.cash_on_cash_return
              : '0.00';
            this.CAPRateSalePrice = response.data.cap_rate_sale_price
              ? response.data.cap_rate_sale_price
              : '0.00';
            this.CAPRatePurchasePrice = response.data.cap_rate_purchase_price
              ? response.data.cap_rate_purchase_price
              : '0.00';
            this.DebtCoverageRatioValue = response.data.debt_coverage_ratio
              ? response.data.debt_coverage_ratio
              : '0.00';
            this.OperatingExpenseRatioValue = response.data
              .operating_expense_ratio
              ? response.data.operating_expense_ratio
              : '0.00';
            this.cashFlowValue = response.data.cash_flow_yearly
              ? response.data.cash_flow_yearly
              : '0.00';
            this.totalExpenseHoldingCostsAmount =
              response.data.total_expense_holding_costs;
            this.total_brrrr_cost = response.data.total_brrrr_cost
              ? response.data.total_brrrr_cost
              : '0.00';
            this.equity_available = response.data.equity_available
              ? response.data.equity_available
              : '0.00';
            this.total_expense_holding_costs = response.data
              .total_expense_holding_costs
              ? response.data.total_expense_holding_costs
              : '0.00';
            this.total_financing_holdings_costs = response.data
              .total_financing_holdings_costs
              ? response.data.total_financing_holdings_costs
              : '0.00';
            this.total_selling_costs = response.data.total_selling_costs
              ? response.data.total_selling_costs
              : '0.00';
            this.total_flip_cost = response.data.total_flip_cost
              ? response.data.total_flip_cost
              : '0.00';
            this.flip_result = response.data.flip_result
              ? response.data.flip_result
              : '0.00';
            this.flip_return_on_investment = response.data
              .flip_return_on_investment
              ? response.data.flip_return_on_investment
              : '0.00';
            this.cashOutAmount = response.data.cash_out
              ? response.data.cash_out
              : '0.00';
            this.AnnualGrossRentMultiplierValue = response.data
              .annual_gross_rent_multiplier
              ? response.data.annual_gross_rent_multiplier
              : 0;
            this.cashFlowAmount = response.data.cash_flow_yearly
              ? response.data.cash_flow_yearly
              : '0.00';
            this.calculateTotalReturnValue();
            this.calculateTotalROIValue();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.recordId = response.data.id;
            this.dealTypeId = response.data.deal_type_id;

            this.purchasePriceAmount = response.data.purchase_price
              ? response.data.purchase_price
              : '0.00';

            if (response.data.financing_required == 'Yes') {
              this.totalLoanAmount = response.data.total_loan_amount;
            } else {
              this.totalLoanAmount = 0;
            }

            if (response.data.financing_required == 'Yes') {
              this.downPaymentAmount =
                response.data.purchase_price - response.data.total_loan_amount;
              this.loanAmountMetrics = response.data.total_loan_amount
                ? response.data.total_loan_amount
                : '0.00';
            } else {
              this.downPaymentAmount =
                response.data.purchase_price -
                response.data.total_funds_available;
              this.loanAmountMetrics = response.data.total_funds_available
                ? response.data.total_funds_available
                : '0.00';
            }
            this.closingCostsAmount =
              response.data.total_purchase_costs_string -
              response.data.downpayment;
            this.repairsRenovationsAmount = response.data
              .total_repair_renovation
              ? response.data.total_repair_renovation
              : '0.00';

            this.totalInvestmentAmount =
              parseInt(this.downPaymentAmount) +
              parseInt(this.closingCostsAmount) +
              parseInt(this.repairsRenovationsAmount);
            this.pricePerUnitAmount =
              response.data.purchase_price / response.data.number_of_doors;
            this.afterRepairValueAmount = response.data.after_repair_value
              ? response.data.after_repair_value
              : '0.00';
            this.pricePerUnitAfterRepairAmount =
              response.data.after_repair_value / response.data.number_of_doors;
            this.LoanPaymentsAmount = response.data.total_loan_payback_yearly
              ? response.data.total_loan_payback_yearly
              : '0.00';
            this.MortgagePaydownValue = response.data.total_mortgage_paydown
              ? response.data.total_mortgage_paydown
              : '0.00';

            this.AppreciationValue =
              (response.data.estimated_appreciation *
                response.data.purchase_price) /
              100;
            this.TotalInvestmentValue = response.data.total_investment_string
              ? response.data.total_investment_string
              : 0;
            this.totalRefinanceCosts = response.data.total_refinance_costs
              ? response.data.total_refinance_costs
              : 0;
            this.expected_loan_refinance = response.data.expected_loan_refinance
              ? response.data.expected_loan_refinance
              : 0;
            this.calculateTotalReturnValue();
            this.calculateTotalROIValue();
            this.calculateValueIncreaseAmount();
            this.calculateBrrrResultAmount();
            this.calculateBrrrPerformanceValue();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  calculateBrrrPerformanceValue() {
    this.brrrPerformanceValue =
      this.TotalInvestmentValue / this.brrrResultAmount;
  }

  calculateBrrrResultAmount() {
    this.brrrResultAmount = this.TotalInvestmentValue - this.cashOutAmount;
  }

  calculateValueIncreaseAmount() {
    this.ValueIncreaseAmount =
      (this.purchasePriceAmount - this.afterRepairValueAmount) /
      this.afterRepairValueAmount;
  }

  calculateTotalROIValue() {
    this.totalROIValue =
      (this.TotalReturnValue / this.TotalInvestmentValue) * 100;
  }

  calculateTotalReturnValue() {
    this.TotalReturnValue =
      this.AppreciationValue + this.MortgagePaydownValue + this.cashFlowValue;
  }
}
