import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { ContactsService } from '../../../_services/contacts.service';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view-contacts',
  templateUrl: './view-contacts.component.html',
  styleUrls: ['./view-contacts.component.css'],
})
export class ViewContactsComponent implements OnInit {
  public environment = environment;
  updateContactForm: FormGroup;
  public user;
  id: string;
  defaultImage = true;
  imgsrc: any = '';
  image_name: any = '';
  public imageURL;
  contentContent: any;
  public details;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _ContactsService: ContactsService,
    private _AuthService: AuthService,
    private sanitizer: DomSanitizer,
  ) {
    this.user = this._AuthService.getUserInfo();
    console.log(this.user);
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    startLoader();
    this._ContactsService
      .getById(this.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.updateContactForm.patchValue(response.data);
            this.defaultImage = false;
            this.image_name = response.data.image_name;
            if(response.data.content) {
              this.contentContent = this.sanitizer.bypassSecurityTrustHtml(response.data.content); 
            }
            console.log(this.contentContent);
            this.details = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }

          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );

    this.updateContactForm = this.formBuilder.group(
      {
        first_name: ['', Validators.required],
        cell_phone: ['', Validators.required],
        email: ['', Validators.required],
        company: ['', Validators.required],
        job_title: ['', Validators.required],
        address: ['', Validators.required],
        source: ['', Validators.required],
        facebook_link: ['', Validators.required],
        image_name: ['', Validators.required],
        file: [],
        linkedin_link: ['', Validators.required],
        instagram_link: ['', Validators.required],
        content: ['', Validators.required],
        twitter_link: ['', Validators.required],
        website: ['', Validators.required],
        work_phone: ['', Validators.required],
        investor_type: ['', Validators.required],
        funds_available: ['', Validators.required],
        last_name: ['', Validators.required],
      },
      {}
    );
  }

  processFundsAvailable(fundsAvailable): void {
    var fundsAvailable = fundsAvailable.replace(/[^a-zA-Z0-9]/g, '');
    let numberVal = parseInt(fundsAvailable).toLocaleString();
    this.updateContactForm.patchValue({funds_available: numberVal});
  }

}
