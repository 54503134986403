import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class DealtypesService {

  constructor(private _ApiService: ApiService) { }

  getAllActiveRecords() {
    return this._ApiService.get('api/dealtypes/activeRecords'); //Done
  }


}