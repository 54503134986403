import { Component, OnInit } from '@angular/core';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from '@angular/router';
import { GlobalService } from './_services/global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'DoorHound';
  subscription: Subscription;
  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  showSharedBar: boolean = true;
  isLoading: boolean;

  constructor(private globalservice: GlobalService, private router: Router) {
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        console.log(event['url'].split('/')[1]);
        if (
          event['url'] == '/user/login' ||
          event['url'] == '/user/signup' ||
          event['url'] == '/user/forgot-password' ||
          event['url'] == '/error/404' ||
          event['url'] == '/error/500' ||
          event['url'].split('/')[1] == 'reset-password' ||
          event['url'].split('/')[1] == 'email-verification' ||
          event['url'].split('/')[1] == 'shared'
        ) {
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
          document.querySelector('.main-panel').classList.add('w-100');
          document
            .querySelector('.page-body-wrapper')
            .classList.add('full-page-wrapper');
          document
            .querySelector('.content-wrapper')
            .classList.remove('auth', 'auth-img-bg');
          document
            .querySelector('.content-wrapper')
            .classList.remove('auth', 'lock-full-bg');
          if (event['url'] == '/error/404' || event['url'] == '/error/500') {
            document.querySelector('.content-wrapper').classList.add('p-0');
          }
        } else if (event['url'].split('/')[1] == 'shared') {
          this.showSharedBar = true;
        } else {
          this.showSidebar = true;
          this.showNavbar = true;
          this.showSharedBar = false;
          this.showFooter = true;
          document.querySelector('.main-panel').classList.remove('w-100');
          document
            .querySelector('.page-body-wrapper')
            .classList.remove('full-page-wrapper');
          document
            .querySelector('.content-wrapper')
            .classList.remove('auth', 'auth-img-bg');
          document.querySelector('.content-wrapper').classList.remove('p-0');
        }
      }
    });

    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });

    this.subscription = this.globalservice.refreshUserAuth.subscribe((res) => {
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          console.log(event['url'].split('/')[1]);
          if (
            event['url'] == '/user/login' ||
            event['url'] == '/user/signup' ||
            event['url'] == '/user/forgot-password' ||
            event['url'] == '/error/404' ||
            event['url'] == '/error/500' ||
            event['url'].split('/')[1] == 'reset-password' ||
            event['url'].split('/')[1] == 'email-verification' ||
            event['url'].split('/')[1] == 'shared'
          ) {
            this.showSidebar = false;
            this.showNavbar = false;
            this.showFooter = false;
            document.querySelector('.main-panel').classList.add('w-100');
            document
              .querySelector('.page-body-wrapper')
              .classList.add('full-page-wrapper');
            document
              .querySelector('.content-wrapper')
              .classList.remove('auth', 'auth-img-bg');
            document
              .querySelector('.content-wrapper')
              .classList.remove('auth', 'lock-full-bg');
            if (event['url'] == '/error/404' || event['url'] == '/error/500') {
              document.querySelector('.content-wrapper').classList.add('p-0');
            }
          } else if (event['url'].split('/')[1] == 'shared') {
            this.showSharedBar = true;
          } else {
            this.showSidebar = true;
            this.showNavbar = true;
            this.showSharedBar = false;
            this.showFooter = true;
            document.querySelector('.main-panel').classList.remove('w-100');
            document
              .querySelector('.page-body-wrapper')
              .classList.remove('full-page-wrapper');
            document
              .querySelector('.content-wrapper')
              .classList.remove('auth', 'auth-img-bg');
            document.querySelector('.content-wrapper').classList.remove('p-0');
          }
        }
      });
    });
  }

  ngOnInit() {
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
