import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionsService {

  constructor(private _ApiService: ApiService) { }

  createSubscription(data) {
    return this._ApiService.post('api/subscriptions/create', data);
  }
  
  cancelUserSubscription(data) {
    return this._ApiService.post('api/subscriptions/cancelSubscription', data);
  }
  
  getUserSubscription(userId) {
    return this._ApiService.get('api/subscriptions/getUserSubscription/'+ userId);
  }


}
