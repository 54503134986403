<div class="dashboard_content_wrap">
  <div class="dashboard_content ">
    <div class="dash-head">
      <div class="row">
        <div class="col">
          <h1 class="page-titles">
            Properties / <span>Add Property</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="dashboard-container ">
      <div class="container">
        <div class="theme-card addproperty-wrap">
          <div class="addproperty-head">
            Choose Property Type
          </div>
          <div class="addproperty-box">
            <div class="row g-4">
              <div class="col-md-6" *ngFor="let singleRecord of allRecords; let i = index">
                <div class="addproperty-card">
                  <a href="javascript:void(0);" (click)="redirectToPropertyStep(singleRecord.id)">
                    <figure>
                      <img src="assets/img/property-icon1.png" alt="">
                    </figure>
                    <h3>{{singleRecord.title}}</h3>
                    <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>