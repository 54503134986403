<div class="dashboard_content Properties_AddDashFix">
    <form method="post" id="email-form" [formGroup]="addPropertyStep1Form" (ngSubmit)="onAddPropertyStep1FormSubmit()"
        class="addStep_From">
        <div class="dash-head">
            <div class="row align-items-center">
                <div class="col">
                    <h1 class="page-titles">Properties /<span> Add Property</span></h1>
                </div>
                <div class="col-auto">
                    <div class="left-head">
                        <a (click)="clickToBack()" class="btn-style1 me-2"><i class="far fa-arrow-left me-2"></i>Back</a>
                        <button type="submit" class="btn-style1">Save & Next</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-container">
            <div class="container">
                <div class="customtabs_btns position-relative">
                    <ul class="list-unstyled mb-10">
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step1', uniqueId]">
                                <span class="tabcount">1</span>
                                Property
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">2</span>
                                Financing
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">3</span>
                                Investment
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">4</span>
                                Income
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">5</span>
                                Expenses
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">6</span>
                                Payback
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">7</span>
                                Performance
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">8</span>
                                Summary
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="stepcard">
                    <div class="stephead-style1">
                        <span>General Info</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Property Name <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="property_name" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Number of Doors <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <wj-input-number [isRequired]="false" format="n0" class="view_proInputNum withOut_space"
                                    type="number" formControlName="number_of_doors" id=""></wj-input-number>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Building Class</label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="building_class" id="">
                                    <option value="">Select Building Class</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Vacancy <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" [min]="0" format="n2" class="form-control1" type="number" formControlName="vacancy" id=""></wj-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Monthly Rent</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Enter Total Monthly Rent <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="is_monthly_rent" id=""
                                    (change)="updateMonthlyRentField($event.target.value)">
                                    <option value="">Select Monthly Rent</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="isMonthlyRent == 'No'">
                            <label for=""></label>
                            <div class="flexwrap-box repeatedCreateDesign">
                                <div class="form-group d-none d-lg-flex">
                                    <div class="w-100 min-h-loan">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="incomehead">
                                                    Unit Number<span class="required-error">*</span>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="incomehead">
                                                    Number of Beds <span class="required-error">*</span>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="incomehead">
                                                    Unit Monthly Rent <span class="required-error">*</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div formArrayName="properties" class="repeatedCreate"
                                    *ngFor="let property of addPropertyStep1Form.get('properties')['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="mb-3 position-relative mt-4 mt-lg-0 flotingsquare">
                                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                    <div class="incomehead d-lg-none">Unit Number<span
                                                            class="required-error">*</span></div>
                                                    <div class="position-relative">
                                                        <input min="0" placeholder="Enter Unit Number" value=""
                                                            class="form-control1" type="text" formControlName="unit">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                    <div class="incomehead d-lg-none">Number of Beds <span
                                                            class="required-error">*</span></div>
                                                    <div class="position-relative">
                                                        <input min="0" placeholder="Enter Number Of Beds" value=""
                                                            class="form-control1" type="number"
                                                            formControlName="number_beds">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                    <div class="incomehead d-lg-none">Monthly Rent <span
                                                            class="required-error">*</span></div>
                                                    <div class="position-relative">
                                                        <wj-input-number [isRequired]="false" format="n2"
                                                            placeholder="Enter Unit Monthly Rent" value=""
                                                            class="view_proInputNum withOut_space" type="number"
                                                            formControlName="monthly_rent"
                                                            (keyup)="calculateTotalMonthRent($event.target.value)">
                                                        </wj-input-number>
                                                    </div>
                                                </div>
                                                <a (click)="removeProperty(i)"
                                                    class="squarebtn btn btn-danger removeBlock">
                                                    <i class="far fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="addProperty()" class="createAddBtn addOnBtn top-0">
                                    <i class="far fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Total Monthly Rent <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group" *ngIf="isMonthlyRent == 'No'">
                                    <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                        class="form-control1 ilsign-p" type="text" formControlName="total_monthly_rent"
                                        id=""></wj-input-number>
                                </div>
                                <div class="input-group" *ngIf="isMonthlyRent == 'Yes'">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1 ilsign-p"
                                        type="text" formControlName="total_monthly_rent" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Location</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Address <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="street_address" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">City <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="city" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">State <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="state" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Country <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="country" id="">
                            </div>
                        </div>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Listing ID</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="listing_id" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Agent</label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="agent_id" id=""  (change)="updateBrokerageByAgent($event.target.value)">
                                    <option value="">Select Agent</option>
                                    <option *ngFor="let singleRecord of contactsAgentDropdown; let i = index"
                                        value="{{singleRecord.id}}">{{singleRecord.first_name}}
                                        {{singleRecord.last_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Brokerage</label>
                            <div class="flexwrap-box">
                                <select disabled class="form-select" formControlName="brokerage_id" id="">
                                    <option value="">Select Brokerage</option>
                                    <option *ngFor="let singleRecord of contactsAgentDropdown; let i = index"
                                        value="{{singleRecord.id}}">
                                        <span>{{singleRecord.company}}</span>
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Images</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="upload-prop-wall">
                            <ul class="list-unstyled mb-0">
                                <li>
                                    <figure>
                                        <input type="file" (change)="onFileChangeUploadNewImage($event)">
                                        <img src="assets/img/upload-icon.jpg" alt="">
                                    </figure>
                                </li>
                                <li *ngFor="let singleRecord of imagesData; let i = index">
                                    <figure>
                                        <a (click)="showImagePreview()" class="fancybox-buttons btn-gallery"
                                            data-fancybox-group="button" href="javascript:void(0);"><img
                                                src="{{environment.apiUrl+'/uploads/propertiesimages/'+singleRecord.image_name}}"
                                                alt=""></a>
                                        <button type="button" (click)="deleteImage(singleRecord.id)"
                                            class="remove-pp-wall">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </figure>
                                    <div id="gallery-1" class="hidden">
                                        <a *ngFor="let singleRecord of imagesData; let i = index"
                                            href="{{environment.apiUrl+'/uploads/propertiesimages/'+singleRecord.image_name}}"></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Building Details</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Style</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="building_style"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Foundation</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text"
                                    formControlName="building_foundation" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Exterior finish</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text"
                                    formControlName="exterior_finish" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Heat fuel</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="heat_fuel"
                                    id="">
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Property Features</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Year Built</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="year_built"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Square Footage</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="square_footage"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Ownership Type</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="ownership_type"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Property Type</label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="property_type" id="">
                                    <option value="">Select Property Type</option>
                                    <option *ngFor="let singleRecord of propertyTypesDropdown; let i = index"
                                        value="{{singleRecord.id}}">{{singleRecord.category_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Lot Size</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="lot_size"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Zoning</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="zoning" id="">
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Estimates</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Estimated Appreciation </label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" [min]="0" format="n2" class="form-control1" type="number" formControlName="estimated_appreciation" id=""></wj-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Estimated Income Increase </label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" [min]="0" format="n2" class="form-control1" type="number" formControlName="estimated_income_increase" id=""></wj-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Estimated Expenses Increase </label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" [min]="0" format="n2" class="form-control1" type="number" formControlName="estimated_expenses_increase" id=""></wj-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Expected Selling Costs </label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" [min]="0" format="n2" class="form-control1" type="number" formControlName="expected_selling_costs" id=""></wj-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Expected Loan to Value for Refinance </label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" [min]="0" format="n2" class="form-control1" type="number" formControlName="expected_loan_refinance" id=""></wj-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Depreciation (Years) </label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" [min]="0" format="n2" class="form-control1" type="number" formControlName="depreciation" id=""></wj-input-number>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Land Value </label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1 ilsign-pl "
                                        type="number" formControlName="land_value" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>More Details</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Description of Property</label>
                            <div class="flexwrap-box editorbox">
                                <ckeditor #editor id="exampleInputName1" formControlName="description"
                                    class="editorContent" type="divarea">
                                </ckeditor>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">About the City</label>
                            <div class="flexwrap-box editorbox">
                                <ckeditor #editor id="exampleInputName2" formControlName="about_city"
                                    class="editorContent" type="divarea">
                                </ckeditor>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Why the City</label>
                            <div class="flexwrap-box editorbox">
                                <ckeditor #editor id="exampleInputName3" formControlName="why_city"
                                    class="editorContent" type="divarea">
                                </ckeditor>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label for="">Property Tags</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="property_tags"
                                    id="">
                            </div>
                        </div> -->
                        <div class="form-group">
                            <label for="">Why this Property</label>
                            <div class="flexwrap-box editorbox">
                                <ckeditor #editor id="exampleInputName5" formControlName="property_pitch"
                                    class="editorContent" type="divarea">
                                </ckeditor>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Notes</label>
                            <div class="flexwrap-box editorbox">
                                <ckeditor #editor id="exampleInputName4" formControlName="notes" class="editorContent"
                                    type="divarea">
                                </ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>