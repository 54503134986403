import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { UserService } from '../../../_services/user.service';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';
import { ContactsService } from '../../../_services/contacts.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-wholesale-property-step1',
  templateUrl: './wholesale-property-step1.component.html',
  styleUrls: ['./wholesale-property-step1.component.css'],
})
export class WholesalePropertyStep1Component implements OnInit {
  public environment = environment;
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep1Form: FormGroup;
  imageForm: FormGroup;
  details: {};
  public imagesData;
  public contactsDropdown;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private _ContactsService: ContactsService,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.getActiveContactsByUserId();
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
    this.getAllImagesByUniqueId();

    this.addPropertyStep1Form = this.formBuilder.group({
      property_name: ['', Validators.required],
      unique_id: ['', Validators.required],
      number_of_doors: ['', Validators.required],
      listing_id: ['', Validators.required],
      agent_id: ['', Validators.required],
      brokerage_id: ['', Validators.required],
      street_address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      description: ['', Validators.required],
      about_city: ['', Validators.required],
      why_city: ['', Validators.required],
      total_monthly_rent: ['', Validators.required],
      is_monthly_rent: ['', Validators.required],
      property_tags: ['', Validators.required],
      notes: ['', Validators.required],
      building_foundation: ['', Validators.required],
      building_style: ['', Validators.required],
      exterior_finish: ['', Validators.required],
      heat_fuel: ['', Validators.required],
      year_built: ['', Validators.required],
      square_footage: ['', Validators.required],
      ownership_type: ['', Validators.required],
      property_type: ['', Validators.required],
      lot_size: ['', Validators.required],
      zoning: ['', Validators.required],
      status: ['Active', Validators.required],
      user_id: [this.user.id, Validators.required],
      deal_type_id: [this.user.id, Validators.required],
    });

    this.imageForm = this.formBuilder.group({
      property_id: ['', Validators.required],
      unique_id: [this.uniqueId, Validators.required],
      user_id: [this.user.id, Validators.required],
      file: [],
    }, {});

  }

  getActiveContactsByUserId() {
    startLoader();
    this._ContactsService.getActiveContactsByUserId(this.user.id).subscribe(
      (response: any) => {
        if (response.status == 200) {
          console.log(response.data);
          this.contactsDropdown = response.data;
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  getAllImagesByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllImagesRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.imagesData = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.recordId = response.data.id;
            this.addPropertyStep1Form.patchValue(response.data);
            this.imageForm.patchValue({property_id: response.data.id});
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  onAddPropertyStep1FormSubmit() {
    if (this.addPropertyStep1Form.value.property_name == '') {
      checkTosterResponse('Please enter Property name.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.number_of_doors == '') {
      checkTosterResponse('Please enter Number of doors.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.street_address == '') {
      checkTosterResponse('Please enter Street address.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.city == '') {
      checkTosterResponse('Please enter City.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.state == '') {
      checkTosterResponse('Please enter State.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.country == '') {
      checkTosterResponse('Please enter Country.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.listing_id == '') {
      checkTosterResponse('Please enter listing id.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.agent_id == '') {
      checkTosterResponse('Please enter agent.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.brokerage_id == '') {
      checkTosterResponse('Please enter brokerage.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.building_style == '') {
      checkTosterResponse('Please enter style.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.building_foundation == '') {
      checkTosterResponse('Please enter foundation.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.exterior_finish == '') {
      checkTosterResponse('Please enter exterior finish.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.heat_fuel == '') {
      checkTosterResponse('Please enter heat fuel.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.year_built == '') {
      checkTosterResponse('Please enter year built.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.square_footage == '') {
      checkTosterResponse('Please enter square footage.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.ownership_type == '') {
      checkTosterResponse('Please enter ownership type.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.property_type == '') {
      checkTosterResponse('Please enter property type.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.lot_size == '') {
      checkTosterResponse('Please enter lot size.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.zoning == '') {
      checkTosterResponse('Please enter zoning.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.description == '') {
      checkTosterResponse('Please enter description.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.about_city == '') {
      checkTosterResponse('Please enter About city.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.why_city == '') {
      checkTosterResponse('Please enter Why city.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.property_tags == '') {
      checkTosterResponse('Please enter Property tags.', 'Error');
      return false;
    }
    if (this.addPropertyStep1Form.value.notes == '') {
      checkTosterResponse('Please enter Notes.', 'Error');
      return false;
    }
    startLoader();
    this.addPropertyStep1Form.patchValue({ status: 'Active' });
    this._PropertiesService
      .updateRecord(this.recordId, this.addPropertyStep1Form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            checkTosterResponse(response.message, 'Success');
            this.router.navigate(['/properties/wholesale-property-step2/'+this.uniqueId]);
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  onFileChangeUploadNewImage(event) {
    console.log("event", event);
    const file = event.target.files[0];
    this.imageForm.patchValue({ file: file });
    this.imageForm.patchValue({ user_id: this.user.id });
    this.imageForm.patchValue({ property_id: this.recordId });
    this.imageForm.patchValue({ unique_id: this.uniqueId });

    this._PropertiesService
      .uploadNewImage(this.imageForm.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.getAllImagesByUniqueId();
            this.imageForm.reset();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  deleteImage(id) {
    this._PropertiesService
      .deleteImageById(id)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.getAllImagesByUniqueId();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }


}
