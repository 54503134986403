import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../_services/payment.service';
import { environment } from '../../../environments/environment';
import { SubscriptionsService } from '../../_services/subscriptions.service';
import { AuthService } from '../../_services/auth.service';
import { GlobalService } from '../../_services/global.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var showPopup: Function;
declare var hidePopup: Function;

@Component({
    selector: 'app-memberships',
    templateUrl: './memberships.component.html',
    styleUrls: ['./memberships.component.scss'],
  })
export class MembershipsComponent implements OnInit {
  public user;
  user_id: any;
  public environment = environment;
  public allPlansRecords;
  public userSubscription;
  priceType = 'Monthly';
  selectedClass = '';
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _SubscriptionsService: SubscriptionsService,
    private globalservice: GlobalService,
    private _AuthService: AuthService,
    private _PaymentService: PaymentService
  ) { 
    this.user = this._AuthService.getUserInfo();
    this.subscription = this.globalservice.refreshUserSubscriptionData.subscribe((res) => {
      this.getUserSubscription();
    });
  }

  ngOnInit(): void {
    this.getUserSubscription();
  }

  cancelConfirmationPopup(id) {
    showPopup(id);
  }

  closePopup(id){
    hidePopup(id);
  } 

  getUserSubscription() {
    startLoader();
    this._SubscriptionsService.getUserSubscription(this.user.id).subscribe(
      (response: any)  => {
        if(response.status==200){
          console.log("response.data", response.data);
          this.userSubscription = response.data; 
        } else {
          checkTosterResponse(response.message, "Error");
          this.router.navigate(['/plans']);
          
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

  cancelSubscription() {
    startLoader();
    let data = {
      subscription_id: this.userSubscription.subscription_id,
      user_id: this.user.id
    };
    this._SubscriptionsService.cancelUserSubscription(data).subscribe(
      (response: any)  => {
        if(response.status==200){
          checkTosterResponse(response.message, "Success");
          hidePopup('cancel-plan-model');
          this.globalservice.refreshUserSubscriptionData.next(true);
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }


}
