import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view-property-step4',
  templateUrl: './view-property-step4.component.html',
  styleUrls: ['./view-property-step4.component.css'],
})
export class ViewPropertyStep4Component implements OnInit {
  public fmt: any = 'n2';
  uniqueId: string;
  vacancy: string;
  recordId: string;
  public user;
  addPropertyStep4Form: FormGroup;
  details: {};
  otherDetails: {};
  public grossRentMonthlyAmount: any = 0;
  public laundryMonthlyAmount: any = 0;
  public parkingMonthlyAmount: any = 0;
  public storageMonthlyAmount: any = 0;
  public totalIncomeMonthlyAmount: any = 0;
  public totalIncomeYearlyAmount: any = 0;
  incomeCostItems: any;
  incomeItemsArray: any = [];
  incomeItems: FormArray;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecordOtherDetails(this.uniqueId);
    this.getSingleRecord(this.uniqueId);
    this.getAllIncomeItemsByUniqueId();

    this.addPropertyStep4Form = this.formBuilder.group({
      gross_rent_monthly: ['', Validators.required],
      gross_rent_yearly: ['', Validators.required],
      laundry_monthly: ['', Validators.required],
      laundry_yearly: ['', Validators.required],
      parking_monthly: ['', Validators.required],
      parking_yearly: ['', Validators.required],
      storage_monthly: ['', Validators.required],
      storage_yearly: ['', Validators.required],
      incomeCostsData: ['', Validators.required],
      incomeItems: this.formBuilder.array([]),
      unique_id: [this.uniqueId, Validators.required],
      vacancy: [this.vacancy, Validators.required],
      user_id: [this.user.id, Validators.required],
      total_income_monthly: ['', Validators.required],
      total_income_yearly: ['', Validators.required],
    });
  }

  getAllIncomeItemsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllIncomeItemsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.incomeCostItems = response;
            let incomeItemsArray = [];
            for (let i = 0; i <= this.incomeCostItems.data.length; i++) {
              incomeItemsArray.push(this.incomeCostItems.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep4Form.controls['incomeItems']
            );
            if (this.incomeCostItems.data.length > 0) {
              for (let i = 0; i < this.incomeCostItems.data.length; i++) {
                let form = this.formBuilder.group({
                  item_name: [this.incomeCostItems.data[i].item_name],
                  item_amount_monthly: [
                    this.incomeCostItems.data[i].item_amount_monthly,
                  ],
                  item_amount_yearly: [
                    this.incomeCostItems.data[i].item_amount_yearly,
                  ],
                });
                control.push(form);
              }
            }
            this.incomeItems = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  addProperty(): void {
    this.incomeItems = this.addPropertyStep4Form.get(
      'incomeItems'
    ) as FormArray;
    this.incomeItems.push(this.createProperty());
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      item_amount_monthly: '',
      item_name: '',
      item_amount_yearly: '',
    });
  }

  removeProperty(rowIndex: number) {
    this.incomeItems.removeAt(rowIndex);
    this.calculateTotalIncome();
  }

  getSingleRecordOtherDetails(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordOtherDetailsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.otherDetails = response.data;
            this.addPropertyStep4Form.patchValue(response.data);
            this.grossRentMonthlyAmount = response.data.gross_rent_monthly
              ? response.data.gross_rent_monthly
              : 0;
            this.laundryMonthlyAmount = response.data.laundry_monthly
              ? response.data.laundry_monthly
              : 0;
            this.parkingMonthlyAmount = response.data.parking_monthly
              ? response.data.parking_monthly
              : 0;
            this.storageMonthlyAmount = response.data.storage_monthly
              ? response.data.storage_monthly
              : 0;
            this.totalIncomeMonthlyAmount = response.data.total_income_monthly
              ? response.data.total_income_monthly
              : 0;
            this.totalIncomeYearlyAmount = response.data.total_income_yearly
              ? response.data.total_income_yearly
              : 0;
            this.grossRentMonthlyAmount = response.data.gross_rent_monthly;
            this.calculateTotalIncome();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.details = response.data;
            this.recordId = response.data.id;
            this.vacancy = response.data.vacancy;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  onAddPropertyStep4FormSubmit() {}

  updateMonthlyToYearlyAmount(amount, type) {
    if (type == 'gross_rent_monthly') {
      this.grossRentMonthlyAmount = amount;
      this.addPropertyStep4Form.patchValue({ gross_rent_yearly: 12 * amount });
    } else if (type == 'laundry_monthly') {
      this.laundryMonthlyAmount = amount;
      this.addPropertyStep4Form.patchValue({ laundry_yearly: 12 * amount });
    } else if (type == 'parking_monthly') {
      this.parkingMonthlyAmount = amount;
      this.addPropertyStep4Form.patchValue({ parking_yearly: 12 * amount });
    } else if (type == 'storage_monthly') {
      this.storageMonthlyAmount = amount;
      this.addPropertyStep4Form.patchValue({ storage_yearly: 12 * amount });
    }
    this.calculateTotalIncome();
  }

  calculateTotalIncome() {
    this.totalIncomeMonthlyAmount =
      parseFloat(this.grossRentMonthlyAmount) +
      parseFloat(this.laundryMonthlyAmount) +
      parseFloat(this.parkingMonthlyAmount) +
      parseFloat(this.storageMonthlyAmount);

    let totalPurcahseCostAmountValue = this.totalIncomeMonthlyAmount;
    var incomeItemsCostData = this.addPropertyStep4Form.value.incomeItems;
    for (const singleRecord of incomeItemsCostData) {
      totalPurcahseCostAmountValue =
        totalPurcahseCostAmountValue +
        parseFloat(singleRecord.item_amount_monthly);
    }
    this.totalIncomeMonthlyAmount = totalPurcahseCostAmountValue;
    this.totalIncomeYearlyAmount = 12 * this.totalIncomeMonthlyAmount;
    this.addPropertyStep4Form.patchValue({
      total_income_monthly: totalPurcahseCostAmountValue,
    });
    this.addPropertyStep4Form.patchValue({
      total_income_yearly: this.totalIncomeYearlyAmount,
    });
  }

  calculateYearlyAmount(amount, index) {
    var self = this;
    let incomeItemsData = this.addPropertyStep4Form.value.incomeItems;
    setTimeout(function () {
      incomeItemsData[index]['item_amount_yearly'] =
        incomeItemsData[index]['item_amount_monthly'] * 12;
      console.log(incomeItemsData);
      console.log(incomeItemsData[index]['item_amount_monthly']);
      self.addPropertyStep4Form.patchValue({ incomeItems: incomeItemsData });
      self.calculateTotalIncome();
    }, 2000);
  }
}
