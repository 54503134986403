<div class="dashboard_content">
    <div class="dash-head">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="page-titles">Properties /<span> Add Property</span></h1>
            </div>
            <div class="col-auto">
                <div class="left-head">
                    <a href="#!" class="btn-style1"><i class="far fa-arrow-left me-2"></i> Back</a>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-container">
        <div class="container">
            <div class="customtabs_btns position-relative">
                <ul class="list-unstyled mb-10">
                    <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">1</span>
                            Property
                        </a>
                    </li>
                     <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">2</span>
                            Financing
                        </a>
                    </li>
                     <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">3</span>
                            Comparables
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">
                            <span class="tabcount">4</span>
                            Summary
                        </a>
                    </li>
                </ul>
            </div>
            <div class="stepcard">
                <div class="customtabs_btns position-relative">
                    <ul class="list-unstyled mb-10">
                        <li class="active">
                            <a href="javascript:void(0);">
                                <span class="tabcount">1</span>
                                Comparable 1
                            </a>
                        </li>
                        <!-- <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">2</span>
                                Add More
                            </a>
                        </li> -->
                    </ul>
                </div>
                <form method="post" id="email-form" [formGroup]="addPropertyStep1Form"
                    (ngSubmit)="onAddPropertyStep1FormSubmit()">
                    <div class="stephead-style1">
                        <span>General Info</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Property Name</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="property_name" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Sold Price</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="number" formControlName="sold_price" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Sold Date</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="sold_date" id="">
                                <!-- <input class="form-control1" type="date" value="01/06/2021" formControlName="sold_date" id=""> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Number of Doors</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="number" formControlName="number_of_doors" id="">
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Location</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Address</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="street_address" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">City</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="city" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">State</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="state" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Country</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="country" id="">
                            </div>
                        </div>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Listing ID</label>
                            <div class="flexwrap-box">
                                <input class="form-control1" type="text" formControlName="listing_id" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Agent</label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="agent_id" id="">
                                    <option value="">Select Agent</option>
                                    <option *ngFor="let singleRecord of contactsDropdown; let i = index"
                                        value="{{singleRecord.id}}">{{singleRecord.first_name}}
                                        {{singleRecord.last_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Brokerage</label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="brokerage_id" id="">
                                    <option value="">Select Brokerage</option>
                                    <option *ngFor="let singleRecord of contactsDropdown; let i = index"
                                        value="{{singleRecord.id}}">{{singleRecord.first_name}}
                                        {{singleRecord.last_name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Images</span>
                    </div>

                    <div class="stepform-style1">
                        <div class="upload-prop-wall">
                            <ul class="list-unstyled mb-0">
                                <li>
                                    <figure>
                                        <input type="file" (change)="onFileChangeUploadNewImage($event)">
                                        <img src="assets/img/upload-icon.jpg" alt="">
                                    </figure>
                                </li>
                                <li *ngFor="let singleRecord of imagesData; let i = index">
                                    <figure>
                                        <img src="{{environment.apiUrl+'/uploads/comparablesimages/'+singleRecord.image_name}}"
                                            alt="">
                                        <button type="button" (click)="deleteImage(singleRecord.id)"
                                            class="remove-pp-wall">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </figure>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Building Details</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Style</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="building_style"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Foundation</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text"
                                    formControlName="building_foundation" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Exterior finish</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text"
                                    formControlName="exterior_finish" id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Heat fuel</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="heat_fuel"
                                    id="">
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Property Features</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Year Built</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="year_built"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Square Footage</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="square_footage"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Ownership Type</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="ownership_type"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Property Type</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="property_type"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Lot Size</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="lot_size"
                                    id="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Zoning</label>
                            <div class="flexwrap-box">
                                <input placeholder="" class="form-control1" type="text" formControlName="zoning" id="">
                            </div>
                        </div>
                    </div>
                    <div class="stepform-pagelink">
                        <button type="submit" class="pagelink-next">Submit <i
                                class="far fa-arrow-right ms-2"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>