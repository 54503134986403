import { Component, OnInit } from '@angular/core';
import { PlansService } from '../../_services/plans.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var showHideAccordion: Function;

@Component({
    selector: 'app-payment-cancel',
    templateUrl: './payment-cancel.component.html',
    styleUrls: ['./payment-cancel.component.scss'],
  })
export class PaymentCancelComponent implements OnInit {
  
  public environment = environment;
  public allPlansRecords;
  priceType = 'Monthly';
  selectedClass = '';

  constructor(
    private _PlansService: PlansService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.getAllPlansRecords();
  }

  getAllPlansRecords() {
    startLoader();
    this._PlansService.getAllActiveRecords().subscribe(
      (response: any)  => {
        if(response.status==200){
          console.log(response.data);
          this.allPlansRecords = response.data; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

  updatePriceShowType() {
    if(this.priceType == "Monthly") {
      this.priceType = "Yearly";
    } else {
      this.priceType = "Monthly";
    }
  }

  safeCinema(cinemaLoc) {
    return this.sanitizer.bypassSecurityTrustHtml(cinemaLoc);
  }


}
