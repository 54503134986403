<div class="dashboard_content Properties_AddDashFix">
  <div class="dash-head">
    <div class="row align-items-center">
      <div class="col">
        <h1 class="page-titles">Properties /<span> View Property</span></h1>
      </div>
      <div class="col-auto">
        <div class="left-head">
          <a
            [routerLink]="['/properties/add-property-step6', uniqueId]"
            class="btn-style1"
            ><i class="far fa-pencil me-2"></i> Edit</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container addStep_From">
    <div class="container">
      <app-propertybar
        [currentSelectedStage]="6"
        [stageType]="1"
      ></app-propertybar>
      <div class="stepcard">
        <div class="stephead-style1">
          <span>Loan Information</span>
        </div>
        <div class="formtable">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Loan Name</th>
                  <th>Loan Type</th>
                  <th>Term</th>
                  <th>Interest</th>
                  <th>Monthly</th>
                  <th>Yearly</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let singleRecord of loanData; let i = index">
                  <td>{{ singleRecord.loan_name }}</td>
                  <td>{{ singleRecord.loan_type }}</td>
                  <td>{{ singleRecord.terms_in_years }}</td>
                  <td>{{ singleRecord.interest_rate }}%</td>
                  <td>${{ singleRecord.emi_monthly | number : "1.2-2" }}</td>
                  <td>${{ singleRecord.emi_yearly | number : "1.2-2" }}</td>
                </tr>
                <tr *ngIf="loanData?.length == 0">
                  <td colspan="8">
                    <div class="alert alert-danger">
                      <strong>Nothing here!</strong>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="amtblock">
            <label>Total Loan Payback</label>
            <div class="flexwrap-box">
              <div class="row">
                <div class="col-6">
                  <div class="ttamt">
                    ${{
                      details?.total_loan_payback_monthly | number : "1.2-2"
                    }}
                    (Monthly)
                  </div>
                </div>
                <div class="col-6">
                  <div class="ttamt">
                    ${{ details?.total_loan_payback_yearly | number : "1.2-2" }}
                    (Yearly)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="amtblock">
            <label>Message</label>
            <div class="flexwrap-box">
              <div class="row">
                <div class="col-12">
                  <div class="ttamt">
                    To edit these values please go to the Financing step
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- using only for BRRR Deal Type -->
        <div *ngIf="dealTypeId === 2">
          <div class="stephead-style1">
            <span>Financing Holding Costs</span>
          </div>
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td>Total Holding Months</td>
                  <td>{{ totalHoldingMonths }}</td>
                </tr>
                <tr *ngFor="let singleRecord of loanData; let i = index">
                  <td>{{ singleRecord.loan_name }}</td>
                  <td>${{ singleRecord.emi_monthly | number : "1.2-2" }}</td>
                </tr>
                <tr>
                  <td>Total Financing Holdings Costs</td>
                  <td>
                    ${{
                      details?.total_loan_payback_monthly * totalHoldingMonths
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- End code for BRRR Deal Type -->
      </div>
    </div>
  </div>
</div>
