<div class="dashboard_content Properties_AddDashFix">
    <form method="post" id="email-form" [formGroup]="addPropertyStep2Form" (ngSubmit)="onaddPropertyStep2FormSubmit()"
        class="addStep_From">
        <div class="dash-head">
            <div class="row align-items-center">
                <div class="col">
                    <h1 class="page-titles">Properties /<span> Add Property</span></h1>
                </div>
                <div class="col-auto">
                    <div class="left-head">
                        <a (click)="clickToBack()" class="btn-style1 me-2"><i  class="far fa-arrow-left me-2"></i> Back</a>
                        <button type="submit" class="btn-style1">Save & Next</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-container ">
            <div class="container">
                <div class="customtabs_btns position-relative">
                    <ul class="list-unstyled mb-10">
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step1', uniqueId]">
                                <span class="tabcount">1</span>
                                Property
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step2', uniqueId]">
                                <span class="tabcount">2</span>
                                Financing
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">3</span>
                                Investment
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">4</span>
                                Income
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">5</span>
                                Expenses
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">6</span>
                                Payback
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">7</span>
                                Performance
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">8</span>
                                Summary
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="stepcard">
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Currency <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="currency" id=""
                                    (change)="updateCurrencySymbol($event.target.value)">
                                    <option value="">Select Currency</option>
                                    <option value="CAD">CAD</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Sale Price <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1 ilsign-p"
                                        type="number" formControlName="sale_price" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Purchase Price <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1 ilsign-p"
                                        type="number" (keyup)="calculateDoorPrice($event.target.value)"
                                        formControlName="purchase_price" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">After Repair Value <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1 ilsign-p"
                                        type="number" formControlName="after_repair_value" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Door Price <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                        class="form-control1  ilsign-p" type="number" formControlName="door_price"
                                        id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Financing Required? <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="financing_required" id=""
                                    (change)="updateFinancingFields($event.target.value)">
                                    <option value="">Select Financing</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-4 w-100 position-relative min-h-loan" *ngIf="financingField == 'Yes'">
                            <button type="button" (click)="addProperty()"
                                class="createAddBtn addOnBtn top-0 mt-2 position-relative ms-auto">
                                <i class="far fa-plus"></i>
                            </button>
                            <div class="">
                                <div formArrayName="properties" class="repeatedCreate"
                                    *ngFor="let property of addPropertyStep2Form.get('properties')['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="w-100">
                                            <div class="stephead-style2">
                                                <h3>Loan {{i+1}}</h3>
                                                <a (click)="removeProperty(i)" class="squarebtn btn btn-danger">
                                                    <i class="far fa-times"></i>
                                                </a>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Name of Loan <span class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <input formControlName="loan_name" class="form-control1" type="text"
                                                        name="" id="">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Loan Type <span class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <select formControlName="loan_type" class="form-select" name=""
                                                        id="">
                                                        <option value="">Select Loan Type</option>
                                                        <option value="Interest Only">Interest Only</option>
                                                        <option value="Amortized">Amortized</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group" *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Amortized'">
                                                <label for="">Loan Amount<span
                                                        class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <div class="input-group"
                                                        *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Amortized'">
                                                        <wj-input-number [isRequired]="false" [min]="0" format="n2"
                                                            (keyup)="calculateLoanAmount($event.target.value, i)"
                                                            formControlName="loan_amount_percentage" type="number"
                                                            class="form-control1"></wj-input-number>
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                    <div class="input-group"
                                                        *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Interest Only'">
                                                        <wj-input-number readonly disabled="" [isRequired]="false"
                                                            format="n2" [min]="0"
                                                            (keyup)="calculateLoanAmount($event.target.value, i)"
                                                            formControlName="loan_amount_percentage" type="number"
                                                            class="form-control1"></wj-input-number>
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Loan Amount <span class="required-error">*</span></label>
                                                <div class="flexwrap-box position-relative">
                                                    <div class="input-group currdrop"
                                                        *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Amortized'">
                                                        <wj-input-number readonly disabled="" [isRequired]="false"
                                                            format="n2" formControlName="loan_amount" type="number"
                                                            class="form-control1  ilsign-pl"
                                                            (keyup)="calculateTotalLoanAmount($event.target.value)">
                                                        </wj-input-number>
                                                    </div>
                                                    <div class="input-group currdrop"
                                                        *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Interest Only'">
                                                        <wj-input-number [isRequired]="false" format="n2"
                                                            formControlName="loan_amount" type="number"
                                                            class="form-control1  ilsign-pl"
                                                            (keyup)="calculateTotalLoanPercent($event.target.value, i)">
                                                        </wj-input-number>
                                                    </div>
                                                    <span class="il-sign">$</span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Terms in Years <span
                                                        class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <div class="input-group">
                                                                <input (keyup)="calculateMonths($event.target.value, i)"
                                                                    formControlName="terms_in_years" type="number"
                                                                    class="form-control1">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-7 ms-auto">
                                                            <div class="row">
                                                                <div class="col-md-auto ms-auto">
                                                                    <label for="" class="mt-2 min-w-auto">Terms in
                                                                        Months <span
                                                                            class="required-error">*</span></label>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="input-group">
                                                                        <wj-input-number readonly disabled=""
                                                                            [isRequired]="false" format="n0" disabled
                                                                            formControlName="terms_in_months"
                                                                            type="number" class="form-control1">
                                                                        </wj-input-number>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Interest Rate <span class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <div class="input-group">
                                                        <wj-input-number [isRequired]="false" [min]="0" format="n2"
                                                            formControlName="interest_rate" type="number"
                                                            class="form-control1"></wj-input-number>
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="financingField == 'No'">
                            <label for="">Total Funds Available<span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1 ilsign-pl"
                                        type="number" formControlName="total_funds_available" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="amtblock" *ngIf="financingField == 'Yes'">
                            <label>Total Loan Amount <span class="required-error">*</span></label>
                            <div class="ttamt" *ngIf="totalLoanAmount">${{totalLoanAmount | number : '1.2-2'}}
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label for="">Total Loan Amount <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                        class="form-control1 ilsign-p" disabled type="number"
                                        formControlName="total_loan_amount" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>