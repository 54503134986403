<div class="dashboard_content">
    <div class="dash-head">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="page-titles">Properties /<span> Add Property</span></h1>
            </div>
            <div class="col-auto">
                <div class="left-head">
                    <a href="#!" class="btn-style1"><i class="far fa-arrow-left me-2"></i> Back</a>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-container">
        <div class="container">
            <div class="customtabs_btns position-relative">
                <ul class="list-unstyled mb-10">
                    <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">1</span>
                            Property
                        </a>
                    </li>
                    <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">2</span>
                            Financing
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">
                            <span class="tabcount">3</span>
                            Comparables
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">
                            <span class="tabcount">4</span>
                            Summary
                        </a>
                    </li>
                </ul>
            </div>
            <div class="stepcard">
                <form method="post" id="email-form" [formGroup]="addPropertyStep3Form"
                    (ngSubmit)="onAddPropertyStep3FormSubmit()">
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Currency</label>
                            <div class="flexwrap-box">
                                <select class="form-select" formControlName="currency" id="" (change)="updateCurrencySymbol($event.target.value)">
                                    <option value="">Select Currency</option>
                                    <option value="CAD">CAD</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Acquisition Price</label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <input class="form-control1" type="number" formControlName="acquisition_price" id="">
                                    <span class="input-group-text">{{currencySymbol}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Assignment Fee</label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <input class="form-control1" type="number" formControlName="assignment_fee" id="">
                                    <span class="input-group-text">{{currencySymbol}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Wholesale Price</label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <input class="form-control1" type="number" formControlName="wholesale_price" id="">
                                    <span class="input-group-text">{{currencySymbol}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">After Repair Price</label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <input class="form-control1" type="number" formControlName="after_repair_value" id="">
                                    <span class="input-group-text">{{currencySymbol}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Wholesale Costs</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Advertising</label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <input class="form-control1" (keyup)="updateFieldValue($event.target.value, 'advertising')" type="number" formControlName="advertising"
                                        id="">
                                    <span class="input-group-text">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Attorney Fees</label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <input class="form-control1"
                                        (keyup)="updateFieldValue($event.target.value, 'attorney_fees')"
                                        type="number" formControlName="attorney_fees" id="">
                                    <span class="input-group-text">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Bird Dog Fees</label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <input class="form-control1"
                                        (keyup)="updateFieldValue($event.target.value, 'bird_dog_fees')" type="number"
                                        formControlName="bird_dog_fees" id="">
                                    <span class="input-group-text">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group Nofield">
                            <label for="">Items</label>
                            <div class="flexwrap-box repeatedCreateDesign">
                                <div class="form-group d-none d-lg-flex">
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="incomehead">
                                                    Item Name
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="incomehead">
                                                    Item Amount
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div formArrayName="purchaseItems" class="repeatedCreate"
                                    *ngFor="let property of addPropertyStep3Form.get('purchaseItems')['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="mb-3">
                                            <div class="row">
                                                <input placeholder="Enter Item Type" value=""
                                                            class="form-control1" type="hidden"
                                                            formControlName="item_type">
                                                <div class="col-6">
                                                    <div class="incomehead d-lg-none">Item Name</div>
                                                    <div class="position-relative">
                                                        <input placeholder="Enter Item Name" value=""
                                                            class="form-control1" type="text"
                                                            formControlName="item_name">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="incomehead d-lg-none">Item Amount</div>
                                                    <div class="position-relative">
                                                        <input placeholder="Enter Item Amount" value=""
                                                            class="form-control1" type="number"
                                                            formControlName="item_amount"
                                                            (keyup)="calculateTotalSellingCosts()">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="addProperty()" class="createAddBtn addOnBtn">
                                    <i class="far fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Total Selling Costs</label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <input class="form-control1" disabled="" type="number"
                                        formControlName="total_selling_costs" id="">
                                    <span class="input-group-text">$</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stepform-pagelink">
                        <button type="submit" class="pagelink-next">Next <i
                                class="far fa-arrow-right ms-2"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>