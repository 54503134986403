<div class="dashboard_content_wrap">
  <div class="dashboard_content ">
    <div class="dash-head">
      <div class="row">
        <div class="col-md-6">
          <h1 class="page-titles">My <span>Profile</span></h1>
        </div>
      </div>
    </div>
    <div class="dashboard-container ">
      <div class="container">
        <div class="theme-card themeTabs profile-card">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="editprofile-tab" data-bs-toggle="tab" data-bs-target="#editprofile"
                type="button" role="tab" aria-controls="editprofile" aria-selected="false">My Profile</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="companyprofile-tab" data-bs-toggle="tab" data-bs-target="#companyprofile"
                type="button" role="tab" aria-controls="companyprofile" aria-selected="true">Company Profile</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="changepassword-tab" data-bs-toggle="tab" data-bs-target="#changepassword"
                type="button" role="tab" aria-controls="changepassword" aria-selected="false">Change Password</button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="editprofile" role="tabpanel" aria-labelledby="editprofile-tab">
              <form class="forms-sample" [formGroup]="myProfileform" (ngSubmit)="onSubmitMyProfile()">
                <div class="row">
                  <div class="col-12">
                    <div class="uploadnew-pic">
                      <figure>
                        <img [src]="imgsrc" alt="" *ngIf="imgsrc">
                        <img src="{{environment.apiUrl+'/uploads/users/'+profile_image}}" alt="" *ngIf="!imgsrc">
                      </figure>
                      <div class="uploadnewbtn">
                        Upload New Image <input type="file" (change)="onFileChangeMyProfile($event)"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" formControlName="first_name">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" formControlName="last_name">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="text" class="form-control" formControlName="email">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Phone</label>
                      <input type="text" class="form-control" formControlName="phone_number">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>About Me</label>
                      <textarea class="form-control" formControlName="about_me"></textarea>
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <button type="submit" class="btnstyle-1">Save Changes</button>
                  </div>
                </div>
              </form>
            </div>

            <div class="tab-pane fade" id="companyprofile" role="tabpanel" aria-labelledby="companyprofile-tab">
              <form class="forms-sample" [formGroup]="companyProfileform" (ngSubmit)="onSubmitCompanyProfile()">
                <div class="row">
                  <div class="col-12">
                    <div class="uploadnew-pic">
                      <figure>
                        <img [src]="imgcompanysrc" alt="" *ngIf="imgcompanysrc">
                        <img src="{{environment.apiUrl+'/uploads/users/'+company_image}}" alt="" *ngIf="!imgcompanysrc">
                      </figure>
                      <div class="uploadnewbtn">
                        Upload New Image <input type="file" (change)="onFileChangeCompanyImage($event)"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Company Name</label>
                      <input type="text" class="form-control" formControlName="company_name">
                    </div>
                    <div class="form-group">
                      <label>About Company</label>
                      <ckeditor
                        #editor
                        id="exampleInputName2"
                        formControlName="company_about"
                        type="divarea">
                      </ckeditor>
                    </div>
                    <div class="form-group">
                      <label>Acquisition Title 1</label>
                      <input type="text" class="form-control" formControlName="acquisition_title_1">
                    </div>
                    <div class="col-12">
                      <div class="uploadnew-pic">
                        <figure>
                          <img [src]="imgcompanysrc_1" alt="" *ngIf="imgcompanysrc_1">
                          <img src="{{environment.apiUrl+'/uploads/users/'+acquisition_image_1}}" alt="" *ngIf="acquisition_image_1">
                        </figure>
                        <div class="uploadnewbtn">
                          Upload New Image <input type="file" (change)="onFileChangeAcquisitionImage_1($event)"></div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Acquisition Title 2</label>
                      <input type="text" class="form-control" formControlName="acquisition_title_2">
                    </div>
                    <div class="col-12">
                      <div class="uploadnew-pic">
                        <figure>
                          <img [src]="imgcompanysrc_2" alt="" *ngIf="imgcompanysrc_2">
                          <img src="{{environment.apiUrl+'/uploads/users/'+acquisition_image_2}}" alt="" *ngIf="acquisition_image_2">
                        </figure>
                        <div class="uploadnewbtn">
                          Upload New Image <input type="file" (change)="onFileChangeAcquisitionImage_2($event)"></div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Acquisition Title 3</label>
                      <input type="text" class="form-control" formControlName="acquisition_title_3">
                    </div>
                    <div class="col-12">
                      <div class="uploadnew-pic">
                        <figure>
                          <img [src]="imgcompanysrc_3" alt="" *ngIf="imgcompanysrc_3">
                          <img src="{{environment.apiUrl+'/uploads/users/'+acquisition_image_3}}" alt="" *ngIf="acquisition_image_3">
                        </figure>
                        <div class="uploadnewbtn">
                          Upload New Image <input type="file" (change)="onFileChangeAcquisitionImage_3($event)"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <button type="submit" class="btnstyle-1">Save Changes</button>
                  </div>
                </div>
              </form>
            </div>
            <div class="tab-pane fade" id="changepassword" role="tabpanel" aria-labelledby="changepassword-tab">
              <form class="forms-sample" [formGroup]="changePasswordform" (ngSubmit)="onSubmitChangePassword()">
                <div class="row px-4">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Old Password</label>
                      <input type="password" formControlName="oldPassword" class="form-control" value="">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>New Password</label>
                      <input type="password" formControlName="newPassword" class="form-control" value="">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Confirm New Password</label>
                      <input type="password" formControlName="confirmPassword" class="form-control" value="">
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <button type="submit" class="btnstyle-1">Change password</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>