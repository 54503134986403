import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { ContactsService } from '../../../_services/contacts.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
    selector: 'app-add-contacts',
    templateUrl: './add-contacts.component.html',
    styleUrls: ['./add-contacts.component.css']
  })
  export class AddContactsComponent implements OnInit {

  addContactForm: FormGroup;
  public user;
  defaultImage = true;
  imgsrc: any = "";
  public imageURL;
  public maxNumber = 100;
  
  constructor(
    private formBuilder: FormBuilder,
    private _ContactsService: ContactsService,
    private _AuthService:AuthService,
    private _location: Location
  ) { 
    this.user = this._AuthService.getUserInfo();
    console.log(this.user);
  }

  ngOnInit(): void {
    this.addContactForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      work_phone_type: ['', Validators.required],
      work_phone: ['', Validators.required],
      cell_phone_type: ['', Validators.required],
      cell_phone: ['', Validators.required],
      email: ['', Validators.required],
      company: ['', Validators.required],
      image_name: ['', Validators.required],
      file: [],
      job_title: ['', Validators.required],
      address: ['', Validators.required],
      source: ['', Validators.required],
      facebook_link: ['', Validators.required],
      linkedin_link: ['', Validators.required],
      real_estate_agent: ['', Validators.required],
      instagram_link: ['', Validators.required],
      content: ['', Validators.required],
      twitter_link: ['', Validators.required],
      website: ['', Validators.required],
      investor_type: ['', Validators.required],
      user_id: [this.user.id, Validators.required],
      funds_available: ['', Validators.required],
      last_name: ['', Validators.required],
    }, {});
  }

  processFundsAvailable(fundsAvailable): void {
    var fundsAvailable = fundsAvailable.replace(/[^a-zA-Z0-9]/g, '');
    let numberVal = parseInt(fundsAvailable).toLocaleString();
    this.addContactForm.patchValue({funds_available: numberVal});
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  checkCellPhoneSMSPhone(cell_phone) {
    if(this.addContactForm.value.cell_phone_type == "Number") {
      var cell_phone = cell_phone.replace(/[^a-zA-Z0-9]/g, '');
    }
      let newNumberD = '';
      var array = cell_phone.split("");
      if(Number(cell_phone)) {
        this.addContactForm.patchValue({cell_phone_type: 'Number'});
        if(cell_phone.length > 3) {
          newNumberD += "("+array[0]+array[1]+array[2]+") ";
          if(array[3]) {
            newNumberD += array[3];
          }
          if(array[4]) {
            newNumberD += array[4];
          }
          if(array[5]) {
            newNumberD += array[5];
          }
          if(cell_phone.length > 9) {          
            if(array[6]) {
              newNumberD += '-';
              newNumberD += array[6];
            }
            if(array[7]) {
              newNumberD += array[7];
            }
            if(array[8]) {
              newNumberD += array[8];
            }
            if(array[9]) {
              newNumberD += array[9];
            }
          } else {
            if(array[6]) {
              newNumberD += '-'
              newNumberD += array[6];
            }
            if(array[7]) {
              newNumberD += array[7];
            }
            if(array[8]) {
              newNumberD += array[8];
            }
            if(array[9]) {
              newNumberD += array[9];
            }
          }
          this.maxNumber = 14;
          this.addContactForm.patchValue({cell_phone: newNumberD});
        } else {
          this.addContactForm.patchValue({cell_phone: cell_phone});
        }
      } 
  }

  checkWorkPhoneSMSPhone(work_phone) {
    if(this.addContactForm.value.work_phone_type == "Number") {
      var work_phone = work_phone.replace(/[^a-zA-Z0-9]/g, '');
    }
      let newNumberD = '';
      var array = work_phone.split("");
      if(Number(work_phone)) {
        this.addContactForm.patchValue({work_phone_type: 'Number'});
        if(work_phone.length > 3) {
          newNumberD += "("+array[0]+array[1]+array[2]+") ";
          if(array[3]) {
            newNumberD += array[3];
          }
          if(array[4]) {
            newNumberD += array[4];
          }
          if(array[5]) {
            newNumberD += array[5];
          }
          if(work_phone.length > 9) {          
            if(array[6]) {
              newNumberD += '-';
              newNumberD += array[6];
            }
            if(array[7]) {
              newNumberD += array[7];
            }
            if(array[8]) {
              newNumberD += array[8];
            }
            if(array[9]) {
              newNumberD += array[9];
            }
          } else {
            if(array[6]) {
              newNumberD += '-'
              newNumberD += array[6];
            }
            if(array[7]) {
              newNumberD += array[7];
            }
            if(array[8]) {
              newNumberD += array[8];
            }
            if(array[9]) {
              newNumberD += array[9];
            }
          }
          this.maxNumber = 14;
          this.addContactForm.patchValue({work_phone: newNumberD});
        } else {
          this.addContactForm.patchValue({work_phone: work_phone});
        }
      } 
  }

  onaddContactFormSubmit() {
    if (this.addContactForm.value.first_name == '') {
      checkTosterResponse("Please enter first name.", "Error");
      return false;
    }
    if (this.addContactForm.value.last_name == '') {
      checkTosterResponse("Please enter last name.", "Error");
      return false;
    }
    if (this.addContactForm.value.email == '') {
      checkTosterResponse('Please enter email.', 'Error');
      return false;
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(this.addContactForm.value.email.match(mailformat))
      {
        console.log("email valid");
      } else {
        checkTosterResponse('Email must be a valid email address.', 'Error');
        return false;
      }
    }
    if (this.addContactForm.value.cell_phone == '') {
      checkTosterResponse("Please enter cell phone.", "Error");
      return false;
    }
    if (this.addContactForm.value.source == '') {
      checkTosterResponse("Please select source.", "Error");
      return false;
    }
    if (this.addContactForm.value.real_estate_agent == '') {
      checkTosterResponse("Please select real estate agent.", "Error");
      return false;
    }
    if (this.addContactForm.value.investor_type == '') {
      checkTosterResponse("Please select investor type.", "Error");
      return false;
    }
    // if (this.addContactForm.value.file == '' || this.addContactForm.value.file == null) {
    //   checkTosterResponse("Image is required","Error");
    //   return false;
    // }
    startLoader();
    this._ContactsService.create(this.addContactForm.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            checkTosterResponse(response.message,"Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message,"Error");
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  onFileChange(event){
    const file = event.target.files[0];
    this.addContactForm.patchValue({file: file});
    this.defaultImage = false;

    const reader = new FileReader();
    this.imageURL = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgsrc = reader.result;
    }
  }
  

}
