<div class="overlay" [ngClass]="menuShow ? 'overlay-show' : ''" (click)="menuToggle()"></div>

<div class="headerdash">

  <div class="upgradePlan-block">
    <div class="container">
      <p>There are {{differenceDate}} days left in your Free Trial. <a [routerLink]="['/plans']">Upgrade Today!</a></p>
    </div>
    <!-- <button type="button" class="closeUpgradeBlock">
      <i class="far fa-times"></i>
    </button> -->
  </div>
  <a class="navbar-brand" [routerLink]="['/dashboard']">
    <img src="assets/img/logo.svg" alt="">
  </a>
  <nav class="navbar navbar-expand-lg p-0 w-100">
    <button class="navbar-toggler" type="button" (click)="menuToggle()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="search-toggle-mobile" [ngClass]="SearchShow ? '' : ''" (click)="SearchToggle()">
      <i class="far fa-search"></i>
    </div>
    <div class="searchBox ms-auto" [ngClass]="SearchShow ? 'Searchopened' : ''">
      <form action="" class="w-md-100">
        <div class="w-md-100 position-relative ng-autocomplete">
          <ng-autocomplete [data]="searchData" [searchKeyword]="keyword" placeholder="Search properties or contacts"
            (selected)='selectEvent($event)' (keyUp)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <a [routerLink]="['/contacts/update',item.id]" [innerHTML]="item.name" *ngIf="item.type == 'Contacts'"></a>
            <a [routerLink]="['/properties/add-property-step1', item.id]" [innerHTML]="item.name"
              *ngIf="item.type == 'Properties'"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
          <!-- <button class="searchBtn">
            <i class="far fa-search"></i>
          </button>
          <input type="text" placeholder="Search properties"> -->
        </div>
      </form>
      <button class="searchClose btn btn-danger" [ngClass]="SearchShow ? '' : ''" (click)="SearchToggle()">
        <i class="far fa-times"></i>
      </button>
    </div>
    <!--  ======= User DropDown =========  -->
    <div class="nav-item dropdown user_dropdown" *ngIf="user">
      <a class="nav-link dropdown-toggle p-0" href="javascript:void(0);" id="user-drop" role="button"
        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-names">{{user.first_name}} {{user.last_name}}</div>
        <!-- <i class="default-user-icon fas fa-user"></i> -->
        <img src="{{environment.apiUrl+'/uploads/users/'+this.user.profile_image}}" class="user-photo" alt="">
        <i class="downArrow fas fa-caret-down"></i>
      </a>
      <div class="dropdown-menu" aria-labelledby="user-drop">
        <div class="user_info">
          <div class="user_name">
            <div>{{user.first_name}} {{user.last_name}}</div>
            <div class="user_email">
              <small>{{user.email}}</small>
            </div>
          </div>
          <ul>
            <li>
              <a [routerLink]="['/my-profile']"><i class="ion-android-person"></i> My Profile</a>
            </li>
            <li>
              <a [routerLink]="['/memberships']"><i class="ion-android-person"></i> Memberships</a>
            </li>
            <!-- <li>
              <a [routerLink]="['/change-password']"><i class="ion-android-person"></i> Change Password</a>
            </li> -->
            <li>
              <a (click)="openLogoutPopup()" href="javascript:void(0);"><i class="ion-log-out"></i> Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>

<div class="modal right fade tire-details show buy_course logout-confirm" id="logout-model" tabindex="-1" role="dialog"
  aria-labelledby="right_modal_sm" aria-modal="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <a href="javascript:void(0);" (click)="closeLogoutPopUp()" class="close-panel ripplelink">
        <img src="assets/img/close_icon.png">
      </a>
      <div class="top_sec">
        <!-- <h3 class="user_heading">Hey, {{user.firstName}} </h3> -->
        <p class="mb-0 mt-4">Are you sure do you want to Logout? </p>
      </div>
      <div class="bottom_btnsec text-center">
        <a href="javascript:void(0);" (click)="logout()" class="btn btnstyle-1 me-2 ">Logout</a>
        <a href="javascript:void(0);" (click)="closeLogoutPopUp()" class="btn btnstyle-1">Cancel</a>
      </div>
    </div>
  </div>
</div>