import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;

  constructor(
    private _UserService: UserService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  public doforgotPassword() {
    let data = this.forgotPasswordForm.value;
    if (this.forgotPasswordForm.value.email == '') {
      checkTosterResponse('Email is required.', 'Error');
      return;
    }
    startLoader();
    this._UserService.forgotPassword(data).subscribe(
      (response: any) => {
        if (response.status == 200) {
          checkTosterResponse(response.message, 'Success');
          this.router.navigate(['/user/login']);
        } else {
          checkTosterResponse(response.message, 'Error');
        }
      },
      (error) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }
  
}
