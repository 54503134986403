<div class="dashboard_content Properties_AddDashFix">
    <div class="dash-head">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="page-titles">Properties /<span> View Property</span></h1>
            </div>
            <div class="col-auto">
                <div class="left-head">
                    <a [routerLink]="['/properties/add-property-step5', uniqueId]" class="btn-style1"><i
                            class="far fa-pencil me-2"></i> Edit</a>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-container addStep_From">
        <div class="container">
            <app-propertybar [currentSelectedStage]="5" [stageType]="1"></app-propertybar>
            <div class="">
                <form method="post" id="email-form" [formGroup]="addPropertyStep5Form"
                    (ngSubmit)="onAddPropertyStep5FormSubmit()">
                    <div class="stepcard">
                        <div class="stephead-style1">
                            <span>Operating Expenses</span>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group d-none d-lg-flex">
                                <label></label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-4">
                                            <div class="incomehead">
                                                Monthly
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="incomehead">
                                                Yearly
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Property Management <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-12 col-md-4 mb-2 mb-md-0">
                                            <div class="position-relative input-group">
                                                <input readonly value=""
                                                    (keyup)="calculatePropertyManagement($event.target.value)"
                                                    class="form-control1" type="number" min="0"
                                                    formControlName="property_management_percent">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="property_management_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'property_management_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="property_management_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Repairs and Maintenance<span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-12 col-md-4 mb-2 mb-md-0">
                                            <div class="position-relative input-group">
                                                <input readonly value=""
                                                    (keyup)="calculateRepairsAndMaintenance($event.target.value)"
                                                    class="form-control1" type="number"
                                                    formControlName="repairs_maintenance_percent">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="repairs_maintenance_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'repairs_maintenance_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="repairs_maintenance_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Bookkeeping <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="bookkeeping_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'bookkeeping_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" disabled class="view_proInputNum" type="text"
                                                    formControlName="bookkeeping_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Property Tax <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="property_tax_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'property_tax_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="property_tax_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Insurance <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="insurance_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'insurance_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="insurance_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Water <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="water_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'water_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="water_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Electricity</label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="electricity_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'electricity_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="electricity_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Heating <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="heating_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'heating_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="heating_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Snow Removal</label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="snow_removal_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'snow_removal_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="snow_removal_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Lawn Maintenance <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="lawn_maintenance_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'lawn_maintenance_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="lawn_maintenance_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Garbage Disposal</label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="garbage_disposal_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'garbage_disposal_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="garbage_disposal_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Cleaner</label>
                                <div class="flexwrap-box">
                                    <div class="row justify-content-end">
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="cleaner_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'cleaner_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="cleaner_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group Nofield">
                                <label for="">Additional Expenses</label>
                                <div class="flexwrap-box repeatedCreateDesign">
                                    <div class="form-group d-none d-lg-flex">
                                        <div class="w-100">
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Name
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Amount Monthly
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Amount Yearly
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div formArrayName="operatingItems" class="repeatedCreate"
                                        *ngFor="let property of addPropertyStep5Form.get('operatingItems')['controls']; let i = index;">
                                        <div [formGroupName]="i">
                                            <div class="mb-3 position-relative mt-4 mt-lg-0 flotingsquare">
                                                <div class="row">
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Name</div>
                                                        <div class="position-relative proInputNum_bx">
                                                            <input readonly placeholder="Enter Item Name" value=""
                                                                class="view_proInputNum form-control1 " type="text"
                                                                formControlName="item_name">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative proInputNum_bx">
                                                            <wj-input-number readonly disabled="" [isRequired]="false"
                                                                [format]="fmt" placeholder="Enter Item Amount Monthly"
                                                                value="" class="view_proInputNum" type="number"
                                                                formControlName="item_amount_monthly"
                                                                (keyup)="calculateYearlyAmount($event.target.value, i)">
                                                            </wj-input-number>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative proInputNum_bx">
                                                            <wj-input-number readonly disabled="" [isRequired]="false"
                                                                [format]="fmt" placeholder="Enter Item Amount Yearly"
                                                                value="" class="view_proInputNum" type="number"
                                                                formControlName="item_amount_yearly"></wj-input-number>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="amtblock">
                            <label>Total Operating Expenses</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="ttamt">${{totalOperatingExpensesMonthlyAmount | number : '1.2-2'}}
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="ttamt">${{totalOperatingExpensesYearlyAmount | number : '1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="stephead-style1">
                            <span>Rental Equipment</span>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group d-none d-lg-flex">
                                <label></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="incomehead">
                                                Monthly
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="incomehead">
                                                Yearly
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Furnance <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="furnance_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmountRental($event.target.value, 'furnance_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="furnance_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Hot Water Tank <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="hot_water_tank_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmountRental($event.target.value, 'hot_water_tank_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="hot_water_tank_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Air Conditionaire <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="number"
                                                    formControlName="air_conditionaire_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmountRental($event.target.value, 'air_conditionaire_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="air_conditionaire_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group Nofield">
                                <label for="">Additional Equipment</label>
                                <div class="flexwrap-box repeatedCreateDesign">
                                    <div class="form-group d-none d-lg-flex">
                                        <div class="w-100">
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Name
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Amount Monthly
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Amount Yearly
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div formArrayName="rentalEquipmentItems" class="repeatedCreate"
                                        *ngFor="let property of addPropertyStep5Form.get('rentalEquipmentItems')['controls']; let i = index;">
                                        <div [formGroupName]="i">
                                            <div class="mb-3 position-relative mt-4 mt-lg-0 flotingsquare">
                                                <div class="row">
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Name</div>
                                                        <div class="position-relative">
                                                            <input readonly placeholder="Enter Item Name" value=""
                                                                class="form-control1" type="text"
                                                                formControlName="item_name">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative">
                                                            <wj-input-number readonly disabled="" [isRequired]="false"
                                                                [format]="fmt" placeholder="Enter Item Amount Monthly"
                                                                value="" class="view_proInputNum" type="number"
                                                                formControlName="item_amount_monthly"
                                                                (keyup)="calculateYearlyAmountOfRental($event.target.value, i)">
                                                            </wj-input-number>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative">
                                                            <wj-input-number readonly disabled="" [isRequired]="false"
                                                                [format]="fmt" placeholder="Enter Item Amount Yearly"
                                                                value="" class="view_proInputNum" type="number"
                                                                formControlName="item_amount_yearly"></wj-input-number>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="amtblock">
                            <label>Total Rental Equipment</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="ttamt">${{totalRentalEquipmentMonthlyAmount | number : '1.2-2'}}
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="ttamt">${{totalRentalEquipmentYearlyAmount | number : '1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="amtblock">
                            <label>Total Expenses</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="ttamt">${{totalExpensesMonthlyAmount | number : '1.2-2'}} </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="ttamt">${{totalExpensesYearlyAmount | number : '1.2-2'}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- using only for BRRR Deal Type -->
                        <div *ngIf="dealTypeId === 2 || dealTypeId === 3">
                            <div class="stephead-style1">
                                <span>Holding Costs</span>
                            </div>
                            <div class="stepform-style1">
                                <div class="form-group">
                                    <label for="">Total Holding Months</label>
                                    <div class="flexwrap-box">
                                        <div class="input-group">
                                            <wj-input-number readonly disabled="" [isRequired]="false" format="n0"
                                                class="form-control1"
                                                (keyup)="calculateHoldingCost($event.target.value)" type="number"
                                                formControlName="total_holding_months" id=""></wj-input-number>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="">Total Operating Expenses</label>
                                    <div class="flexwrap-box">
                                        <div class="input-group">
                                            <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                                class="form-control1 ilsign-p" type="number"
                                                formControlName="total_operating_expenses" id=""></wj-input-number>
                                                <span class="il-sign">$</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="">Total Rental Equipment</label>
                                    <div class="flexwrap-box">
                                        <div class="input-group">
                                            <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                                class="form-control1 ilsign-p" type="number"
                                                formControlName="total_rental_equipment" id=""></wj-input-number>
                                                <span class="il-sign">$</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="">Total Expense Holding Costs</label>
                                    <div class="flexwrap-box">
                                        <div class="input-group">
                                            <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                                class="form-control1 ilsign-p" type="number"
                                                formControlName="total_expense_holding_costs" id=""></wj-input-number>
                                                <span class="il-sign">$</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End code for BRRR Deal Type -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>