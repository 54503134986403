import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../_services/user.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})

export class EmailVerificationComponent implements OnInit {

  verifyString: any;

  constructor(
    private _UserService: UserService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.verifyString = this.router.url.split('email-verification/')[1].toString();
    console.log(this.verifyString);
    this.doEmailVerification();
  }

  public doEmailVerification() {
    startLoader();
    this._UserService.checkEmailVerification(this.verifyString).subscribe(
      (response: any) => {
        if (response.status == 200) {
          checkTosterResponse(response.message, "Success");
        } else {
          checkTosterResponse(response.message, "Error");
        }
        this.router.navigate(['/user/login']);
        stopLoader();
      },
      err => { stopLoader(); },
      () => stopLoader()
    );
  }


}
