import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ComparablesService {

  constructor(private _ApiService: ApiService) { }

  getAllRecordsByUniqueId(uniqueId) {
    return this._ApiService.get('api/comparables/getAllRecordsByUniqueId/'+uniqueId); //Done
  }
  
  getSingleComparableRecord(id) {
    return this._ApiService.get('api/comparables/getSingleRecordById/'+id); //Done
  }

  updateRecord(id, data) {
    return this._ApiService.put('api/comparables/updateRecordById/'+ id, data); //Done
  }

  //Upload New Image
  uploadNewImage(data) {
    return this._ApiService.post('api/comparablesimages/insertNewRecord', data); //Done
  }

  getAllImagesRecordsByComparableId(comparableId) {
    return this._ApiService.get('api/comparablesimages/getAllImagesRecordsByComparableId/' + comparableId); //Done
  }

  deleteImageById(id) {
    return this._ApiService.delete('api/comparablesimages/delete/' + id); //Done
  }
  
}
