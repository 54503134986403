<div class="dashboard_content_wrap">
  <div class="dashboard_content Properties_AddDashFix">
    <form
      method="post"
      id="email-form"
      [formGroup]="addContactForm"
      (ngSubmit)="onaddContactFormSubmit()"
      class="addStep_From"
    >
      <div class="dash-head">
        <div class="row align-items-center">
          <div class="col">
            <h1 class="page-titles">Contacts</h1>
          </div>
          <div class="col-auto">
            <div class="left-head">
              <a [routerLink]="['/contacts/list']" class="btn-style1 me-2"
                ><i class="far fa-times me-2"></i>Cancel</a
              >
              <button type="submit" class="btn-style1">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-container">
        <div class="container">
          <div class="theme-card">
            <div class="contact-detail-row">
              <div class="row">
                <div class="col-lg-8 cd-col-1 pe-xl-4 order-2 order-lg-1">
                  <div class="cd-form">
                    <div class="cd-form-row">
                      <label
                        >First Name <span class="required-error">*</span></label
                      >
                      <input
                        class="form-control"
                        type="text"
                        formControlName="first_name"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label
                        >Last Name <span class="required-error">*</span></label
                      >
                      <input
                        class="form-control"
                        type="text"
                        formControlName="last_name"
                        id=""
                      />
                    </div>
                    <div class="cd-form-row">
                      <label>Email <span class="required-error">*</span></label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="email"
                        id=""
                      />
                    </div>
                    <div class="cd-form-row">
                      <label
                        >Cell Phone <span class="required-error">*</span></label
                      >
                      <input
                        class="form-control"
                        type="text"
                        (keydown)="validateNumber($event)"
                        (paste)="onPaste($event)"
                        formControlName="cell_phone"
                        (keyup)="checkCellPhoneSMSPhone($event.target.value)"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label>Company</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="company"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label>Job Title</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="job_title"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label>Work Phone</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="work_phone"
                        (keydown)="validateNumber($event)"
                        (paste)="onPaste($event)"
                        (keyup)="checkWorkPhoneSMSPhone($event.target.value)"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label>Website</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="website"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label>Address</label>
                      <textarea
                        class="form-control"
                        formControlName="address"
                        id=""
                        cols=""
                        rows=""
                      ></textarea>
                    </div>

                    <div class="cd-form-row">
                      <label
                        >Source <span class="required-error">*</span></label
                      >
                      <select
                        class="form-select"
                        formControlName="source"
                        id=""
                      >
                        <option value="">Select Source</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Linkedin">Linkedin</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Networking Event">
                          Networking Event
                        </option>
                        <option value="Referral">Referral</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div class="cd-form-row">
                      <label>Facebook Link</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="facebook_link"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label>Linkedin Link</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="linkedin_link"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label>Instagram Link</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="instagram_link"
                        id=""
                      />
                    </div>

                    <div class="cd-form-row">
                      <label>Twitter Link</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="twitter_link"
                        id=""
                      />
                    </div>
                    <div class="cd-form-row">
                      <label>Notes</label>
                      <ckeditor
                        #editor
                        id="exampleInputName2"
                        formControlName="content"
                        class="editorContent"
                        type="divarea"
                      >
                      </ckeditor>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 cd-col-2 order-1 order-lg-2">
                  <div class="cd-user-details">
                    <!-- User Avatar -->
                    <div class="cduser-wall">
                      <figure>
                        <img
                          src="assets/img/userwall.jpg"
                          alt=""
                          *ngIf="defaultImage"
                        />
                        <img
                          [src]="imgsrc"
                          id="upload_img"
                          class="roundk"
                          *ngIf="imgsrc"
                        />
                      </figure>
                      <div class="uploadicon">
                        <input type="file" (change)="onFileChange($event)" />
                        <i class="fas fa-camera"></i>
                      </div>
                    </div>

                    <div class="iv-type">
                      <div class="ivtype-head">
                        Real Estate Agent <span class="required-error">*</span>
                      </div>

                      <div class="form-check radiostyle1 form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="real_estate_agent"
                          id="Yes"
                          value="Yes"
                        />
                        <label class="form-check-label" for="Yes">Yes</label>
                      </div>
                      <div class="form-check radiostyle1 form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="real_estate_agent"
                          id="No"
                          value="No"
                        />
                        <label class="form-check-label" for="No">No</label>
                      </div>
                    </div>
                    <!-- Investor Type -->
                    <div class="iv-type">
                      <div class="ivtype-head">
                        Investor Type <span class="required-error">*</span>
                      </div>

                      <div class="form-check radiostyle1 form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="investor_type"
                          id="Passive"
                          value="Passive"
                        />
                        <label class="form-check-label" for="Passive"
                          >Passive</label
                        >
                      </div>
                      <div class="form-check radiostyle1 form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="investor_type"
                          id="Active"
                          value="Active"
                        />
                        <label class="form-check-label" for="Active"
                          >Active</label
                        >
                      </div>
                      <div class="form-check radiostyle1 form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="investor_type"
                          id="Both"
                          value="Both"
                        />
                        <label class="form-check-label" for="Both">Both</label>
                      </div>
                    </div>
                    <div class="cd-form-row">
                      <label>The Capital Available</label>
                      <div class="w-100">
                        <div class="input-group">
                          <input
                            (keyup)="processFundsAvailable($event.target.value)"
                            class="form-control form-control1 ilsign-p"
                            value=""
                            type="text"
                            formControlName="funds_available"
                            id=""
                          />
                          <span class="il-sign">$</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
