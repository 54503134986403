import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var showPopup: Function;
declare var hidePopup: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var copyToClipboard: Function;
declare var setShareLinks: Function;
declare var $: Function;

@Component({
  selector: 'app-property-listing',
  templateUrl: './property-listing.component.html',
  styleUrls: ['./property-listing.component.css'],
})

export class PropertyListingComponent implements OnInit {
  public environment;
  public allRecords;
  public user;
  public shareUniqueId;
  public deleteRecordId;
  public searchKeyword = '';
  public frontUrl = '';
  public sharingURL = '';
  public sharePropertyName = '';
  public currentOrderType = 'DESC';
  public currentOrderField = 'id';
  public selectedColumnsValues;
  public fileName;
  public filePath;

  constructor(
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService,
  ) {
    this.user = this._AuthService.getUserInfo();
    console.log(this.user);
  }

  ngOnInit(): void {
    this.frontUrl = environment.frontUrl;
    console.log('environment', environment);
    console.log('frontUrl', this.frontUrl);
    this.getAllRecords();
  }

  getAllRecords() {
    startLoader();
    let searchData = {
      user_id: this.user.id,
      searchKeyword: this.searchKeyword,
      currentOrderType: this.currentOrderType,
      currentOrderField: this.currentOrderField,
    };
    this._PropertiesService.getAllRecordsByUserId(searchData).subscribe(
      (response: any) => {
        if (response.status == 200) {
          console.log(response.data);
          this.allRecords = response.data;
          this.selectedColumnsValues = response.selectedColumnsValues;
          console.log(this.selectedColumnsValues);
        } else {
          checkTosterResponse(response.message,"Error");
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  shareLinkOnSocialMedia(type): void {
    setShareLinks(type, this.sharingURL);
}

  copyInputMessage(inputElement) {
    copyToClipboard(inputElement);
    checkTosterResponse('Copied!', 'Success');
  }

  openDeletePopup(recordId) {
    this.deleteRecordId = recordId;
    showPopup('delete-model');
  }

  openSharePropertyPopup(uniquId, propertyName) {
    this.shareUniqueId = uniquId;
    this.sharePropertyName = propertyName;
    this.sharingURL = this.frontUrl + '/shared/' + uniquId;
    console.log('environment', environment);
    console.log('frontUrl', this.frontUrl);
    $("body").addClass('modal_ShareOpen');
    showPopup('sharing-model');
  }

  closeSharingPopUp() {
    this.shareUniqueId = '';
    $("body").removeClass('modal_ShareOpen');
    hidePopup('sharing-model');
  }

  closeDeletePopUp() {
    this.deleteRecordId = '';
    hidePopup('delete-model');
  }

  deleteRecord() {
    startLoader();
    this._PropertiesService.deleteRecordById(this.deleteRecordId).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.deleteRecordId = '';
          hidePopup('delete-model');
          checkTosterResponse(response.message, 'Success');
          this.getAllRecords();
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  onFilterByKeyword(key) {
    if (key == '') {
      this.searchKeyword = '';
    } else {
      this.searchKeyword = key;
    }
    this.getAllRecords();
  }

  sortDataByField(field_name, order_by) {
    this.currentOrderField = field_name;
    if (order_by == 'DESC') {
      this.currentOrderType = 'ASC';
    }
    if (order_by == 'ASC') {
      this.currentOrderType = 'DESC';
    }
    console.log(order_by);
    this.getAllRecords();
  }

  downloadReportPdf() {
    var uniqueId = this.shareUniqueId;
    startLoader();
    this._PropertiesService.downloadReportPdf(uniqueId).subscribe(
      (response: any) => {
        console.log('response', response);
        if (response.status == 200) {
          this.fileName = response.data.fileName;
          this.filePath = response.data.filePath;
          console.log('filePath', this.filePath);
          window.open(this.filePath);
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }
}
