<div class="loginsign-wrap">
  <div class="loginform-wrap">
    <div class="loginlogo">
      <img src="assets/img/logo.svg" alt="">
    </div>
    <div class="login-form default-form">
      <div class="form-inner">
        <h3>Login</h3>
        <!--Login Form-->
        <form class="pt-3" [formGroup]="loginForm" (ngSubmit)="doLogin()">
          <div class="form-group">
            <label>Email</label>
            <input class="form-control" formControlName="email" type="text" placeholder="">
          </div>
          <div class="form-group">
            <label>Password</label>
            <input class="form-control" id="password-field" type="password" formControlName="password" value="" placeholder="">
          </div>
          <div class="form-group">
            <div class="field-outer">
              <div class="form-check">
                <!-- <input class="form-check-input" type="checkbox" value="" id="rememberme">
                <label class="form-check-label" for="rememberme">
                  Remember me
                </label> -->
              </div>
              <a [routerLink]="['/user/forgot-password']" class="pwd">Forgot password?</a>
            </div>
          </div>

          <div class="form-group">
            <button class="loginbtn" type="submit">Log In</button>
          </div>
        </form>

        <div class="bottom-box">
          <div class="text">Don't have an account? <a [routerLink]="['/user/signup']" class="call-modal signup">Sign up</a>
          </div>
          <!-- <div class="divider"><span>or</span></div>
          <div class="btn-box row">
            <div class="col-lg-6 col-md-12">
              <a href="javscript:void(0);" class="theme-btn social-btn-two facebook-btn"><i class="fab fa-facebook-f"></i> Log In via
                Facebook</a>
            </div>
            <div class="col-lg-6 col-md-12">
              <a href="javscript:void(0);" class="theme-btn social-btn-two google-btn"><i class="fab fa-google"></i> Log In via Gmail</a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</div>
