<div class="dashboard_left">
  <div class="dashmenu">
    <ul class="list-unstyled dashmenu-list mb-0">
      <li routerLinkActive="active" routerLinkActiveOption="{exact:true}">
        <a [routerLink]="['/dashboard']">
          <i><img src="assets/img/home.png" alt=""></i> <span class="menu-txt">Home</span>
        </a>
      </li>
      <li routerLinkActive="active" routerLinkActiveOption="{exact:true}">
        <a [routerLink]="['/contacts/list']">
          <i><img src="assets/img/profile.png" alt=""></i> <span class="menu-txt">Contacts List</span>
        </a>
      </li>
      <li class="">
        <a [routerLink]="['/properties/list']">
          <i><img src="assets/img/properties.png" alt=""></i> <span class="menu-txt">Properties</span>
        </a>
      </li>
      <!-- <li class="">
        <a href="#!">
          <i><img src="assets/img/reports.png" alt=""></i> <span class="menu-txt">Reports</span>
        </a>
      </li> -->
    </ul>
  </div>
</div>
