import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { DealtypesService } from '../../../_services/dealtypes.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.css'],
})
export class AddPropertyComponent implements OnInit {
  public allRecords;
  public user;

  constructor(
    private router: Router,
    private _DealtypesService: DealtypesService,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
    console.log(this.user);
  }

  ngOnInit(): void {
    this.getAllDealTypes();
  }

  getAllDealTypes() {
    startLoader();
    this._DealtypesService.getAllActiveRecords().subscribe(
      (response: any) => {
        if (response.status == 200) {
          console.log(response.data);
          this.allRecords = response.data;
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  redirectToPropertyStep(dealTypeId) {
    if (dealTypeId == 3 || dealTypeId == 4) {
      checkTosterResponse('Coming soon.', 'Error');
    } else {
      let searchData = {
        deal_type_id: dealTypeId,
        user_id: this.user.id,
      };
      startLoader();
      this._PropertiesService
        .generateRecord(searchData)
        .pipe(first())
        .subscribe({
          next: (response: any) => {
            if (response.status == 200) {
              console.log(response);
              if (dealTypeId === 4) {
                this.router.navigate([
                  '/properties/wholesale-property-step1/' +
                    response.data.unique_id,
                ]);
              } else {
                this.router.navigate([
                  '/properties/add-property-step1/' + response.data.unique_id,
                ]);
              }
            } else {
              checkTosterResponse(response.message, 'Error');
            }
            stopLoader();
          },
          error: (error) => {
            stopLoader();
          },
        });
    }
  }
}
