<div class="dashboard_content_wrap">
  <div class="dashboard_content">
    <div class="dash-head">
      <div class="row">
        <div class="col-md-6">
          <h1 class="page-titles">Manage <span>Columns</span></h1>
        </div>
      </div>
    </div>
    <div class="dashboard-container">
      <div class="container">
        <div class="stepcard">
          <form [formGroup]="editItemForm" (ngSubmit)="onSubmitUserColumnsData(editItemForm.value)">
            <div class="stepform-style1">
              <div class="mb-3 d-none d-lg-block w-100">
                <div class="">
                  <div class="row">
                    <div class="col-4">
                      <div class="incomehead">
                        Field
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="incomehead">
                        Show
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="incomehead">
                        Display Order
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div formArrayName="properties" class="repeatedCreate"
                *ngFor="let property of editItemForm.get('properties')['controls']; let i = index;">
                <div [formGroupName]="i">
                  <div class="form-group">
                    <div class="w-100">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <div class="incomehead d-lg-none">Field</div>
                          <div class="position-relative">
                            {{productData?.data[i]?.columnName}}
                            <input value="" class="form-control1" type="hidden" formControlName="column_name" readonly>
                          </div>
                        </div>
                        <div class="col-12 col-md-4">
                          <div class="incomehead d-lg-none">Show</div>
                          <div class="position-relative">
                            <select class="form-select" formControlName="is_show">
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12 col-md-4">
                          <div class="incomehead d-lg-none">Display Order</div>
                          <div class="position-relative">
                            <input value="" class="form-control1" type="text" formControlName="display_order">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="stepform-pagelink">
              <button type="submit" class="pagelink-next">Submit <i class="far fa-arrow-right ms-2"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>