import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view-property-step7',
  templateUrl: './view-property-step7.component.html',
  styleUrls: ['./view-property-step7.component.css'],
})
export class ViewPropertyStep7Component implements OnInit {
  public fmt: any = 'n2';
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep7Form: FormGroup;
  dealTypeId: number;
  details: {};
  otherDetails: {};
  public OperatingIncomeMonthlyAmount: any = 0;
  public OperatingIncomeYearlyAmount: any = 0;
  public VacancyMonthlyAmount: any = 0;
  public VacancyYearlyAmount: any = 0;
  public NetOperatingIncomeMonthlyAmount: any = 0;
  public NetOperatingIncomeYearlyAmount: any = 0;
  public totalLoanPaybackMonthlyAmount: any = 0;
  public totalLoanPaybackYearlyAmount: any = 0;
  public CashFlowMonthlyAmount: any = 0;
  public CashFlowYearlyAmount: any = 0;
  public total_investment: any = 0;
  public sale_price: any = 0;
  public purchase_price: any = 0;
  public CashOnCashReturnValue: any = 0;
  public CAPRateSalePrice: any = 0;
  public CAPRatePurchasePrice: any = 0;
  public DebtCoverageRatioValue: any = 0;
  public AnnualGrossRentMultiplierValue: any = 0;
  public gross_rent_yearly: any = 0;
  public OperatingExpenseRatioValue: any = 0;
  public AppreciationValue: any = 0;
  public return_on_investment_total_return: any = 0;
  public return_invest_total_roi: any = 0;
  public total_expense_holding_costs: any = 0;
  public afterRepairValueAmount: any = 0;
  public equity_available: any = 0;
  public expected_loan_refinance: any = 0;
  public totalLoanAmount: any = 0;
  public totalRefinanceCosts: any = 0;
  public total_brrrr_cost: any = 0;
  public total_flip_cost: any = 0;
  public cash_out: any = 0;
  public vacancyPercent: any = 0;
  public flip_result: any = 0;
  public brrr_result: any = 0;
  public mortgagePaydownAmount: any = 0;
  public total_financing_holdings_costs: any = 0;
  public flip_return_on_investment: any = 0;
  public brrr_performance: any = 0;
  public investment_structure: any;
  public property_management: any;
  public active_partner: any;
  public passive_partner: any;
  public price_per_share: any;
  public max_number_of_shares: any;
  public amount_for_investment: any;
  public share_count: any;
  public desired_shares: any;
  public investment_required: any;
  public shareTableData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _PropertiesService: PropertiesService,
    private formBuilder: FormBuilder,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecordOtherDetails(this.uniqueId);
    this.getSingleRecord(this.uniqueId);

    this.addPropertyStep7Form = this.formBuilder.group({
      operating_income_monthly: ['', Validators.required],
      operating_income_yearly: ['', Validators.required],
      net_perfomance_vacancy_monthly: ['', Validators.required],
      net_perfomance_vacancy_yearly: ['', Validators.required],
      active_partner: ['', Validators.required],
      passive_partner: ['', Validators.required],
      max_number_of_shares: ['', Validators.required],
      price_per_share: ['', Validators.required],
      net_operating_income_monthly: ['', Validators.required],
      net_operating_income_yearly: ['', Validators.required],
      operating_expense_ratio: ['', Validators.required],
      investment_structure: ['', Validators.required],
      property_management: ['', Validators.required],
      share_count: ['', Validators.required],
      cash_on_cash_return: ['', Validators.required],
      cash_flow_monthly: ['', Validators.required],
      cash_flow_yearly: ['', Validators.required],
      cap_rate_sale_price: ['', Validators.required],
      investment_required: ['', Validators.required],
      cap_rate_purchase_price: ['', Validators.required],
      debt_coverage_ratio: ['', Validators.required],
      desired_shares: ['', Validators.required],
      amount_for_investment: ['', Validators.required],
      return_on_investment_appreciation: ['', Validators.required],
      return_on_investment_total_return: ['', Validators.required],
      return_invest_total_roi: ['', Validators.required],
      equity_available: ['', Validators.required],
      total_brrrr_cost: ['', Validators.required],
      flip_result: ['', Validators.required],
      flip_return_on_investment: ['', Validators.required],
      total_flip_cost: ['', Validators.required],
      mortgage_paydown: ['', Validators.required],
      cash_out: ['', Validators.required],
      annual_gross_rent_multiplier: ['', Validators.required],
    });
  }

  getSingleRecordOtherDetails(uniqueId) {
    var self = this;
    startLoader();
    this._PropertiesService
      .singleRecordOtherDetailsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            console.log(
              'response.data.total_expenses_yearly',
              response.data.total_expenses_yearly
            );
            console.log(
              'response.data.total_income_yearly',
              response.data.total_income_yearly
            );
            this.otherDetails = response.data;
            this.addPropertyStep7Form.patchValue(response.data);
            this.gross_rent_yearly = response.data.gross_rent_yearly;
            this.OperatingIncomeMonthlyAmount =
              response.data.operating_income_monthly;
            // this.OperatingIncomeMonthlyAmount = response.data.total_income_monthly - response.data.total_expenses_monthly;
            this.OperatingIncomeYearlyAmount =
              response.data.operating_income_yearly;
            // this.OperatingIncomeYearlyAmount = this.OperatingIncomeMonthlyAmount * 12;
            this.VacancyMonthlyAmount =
              response.data.net_perfomance_vacancy_monthly;
            this.VacancyYearlyAmount =
              response.data.net_perfomance_vacancy_yearly;
            this.NetOperatingIncomeMonthlyAmount =
              response.data.net_operating_income_monthly;
            // this.NetOperatingIncomeMonthlyAmount = this.OperatingIncomeMonthlyAmount - this.VacancyMonthlyAmount;
            this.NetOperatingIncomeYearlyAmount =
              response.data.net_operating_income_yearly;
            // this.NetOperatingIncomeYearlyAmount = this.OperatingIncomeYearlyAmount - this.VacancyYearlyAmount;
            this.CashFlowMonthlyAmount = response.data.cash_flow_monthly;
            this.CashFlowYearlyAmount = response.data.cash_flow_yearly;
            this.CashOnCashReturnValue = response.data.cash_on_cash_return;
            this.CAPRateSalePrice = response.data.cap_rate_sale_price;
            this.CAPRatePurchasePrice = response.data.cap_rate_purchase_price;
            this.DebtCoverageRatioValue = response.data.debt_coverage_ratio;
            this.AnnualGrossRentMultiplierValue =
              response.data.annual_gross_rent_multiplier;
            this.OperatingExpenseRatioValue =
              response.data.operating_expense_ratio;
            // this.OperatingExpenseRatioValue = response.data.total_expenses_yearly / response.data.total_income_yearly;
            this.total_expense_holding_costs =
              response.data.total_expense_holding_costs;
            this.total_financing_holdings_costs = response.data
              .total_financing_holdings_costs
              ? response.data.total_financing_holdings_costs
              : 0;
            this.active_partner = response.data.active_partner
              ? response.data.active_partner
              : 0;
            this.passive_partner = response.data.passive_partner
              ? response.data.passive_partner
              : 0;
            this.price_per_share = response.data.price_per_share
              ? response.data.price_per_share
              : 0;
            this.max_number_of_shares = response.data.max_number_of_shares
              ? response.data.max_number_of_shares
              : 0;
            this.amount_for_investment = response.data.amount_for_investment
              ? response.data.amount_for_investment
              : 0;
            this.share_count = response.data.share_count
              ? response.data.share_count
              : 0;
            this.desired_shares = response.data.desired_shares
              ? response.data.desired_shares
              : 0;
            this.investment_required = response.data.investment_required
              ? response.data.investment_required
              : 0;
            this.calculatereturnOnInvestmentTotalReturn();
            setTimeout(function () {
              self.generateShareTable();
            }, 2000);
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.details = response.data;
            this.recordId = response.data.id;
            this.dealTypeId = response.data.deal_type_id;
            console.log('dealTypeId', this.dealTypeId);
            if (response.data.financing_required == 'Yes') {
              this.totalLoanAmount = response.data.total_loan_amount;
            } else {
              this.totalLoanAmount = 0;
            }
            this.totalLoanPaybackMonthlyAmount = response.data
              .total_loan_payback_monthly
              ? response.data.total_loan_payback_monthly
              : '0.00';
            this.totalLoanPaybackYearlyAmount = response.data
              .total_loan_payback_yearly
              ? response.data.total_loan_payback_yearly
              : '0.00';
            this.total_investment = response.data.total_investment_string;
            console.log('total_investment', this.total_investment);
            console.log('total_investment', typeof this.total_investment);
            this.sale_price = response.data.sale_price;
            this.purchase_price = response.data.purchase_price;
            this.AppreciationValue =
              (response.data.purchase_price *
                response.data.estimated_appreciation) /
              100;
            this.totalRefinanceCosts = response.data.total_refinance_costs;
            this.afterRepairValueAmount = response.data.after_repair_value;
            this.expected_loan_refinance =
              response.data.expected_loan_refinance;
            this.mortgagePaydownAmount = response.data.total_mortgage_paydown;
            this.calculatereturnOnInvestmentTotalReturn();
            this.calculatereturnInvestTotalRoi();
            this.calculatetotal_brrrr_cost();
            this.calculateequity_available();
            this.calculatecash_out();
            this.calculatebrrr_result();
            this.calculatebrrr_performance();
            this.calculatetotal_flip_cost();
            this.calculateflip_result();
            this.calculateflip_return_on_investment();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  calculateflip_return_on_investment() {
    this.flip_return_on_investment = this.flip_result / this.total_investment;
  }

  calculateflip_result() {
    this.flip_result =
      this.afterRepairValueAmount - this.total_flip_cost - this.totalLoanAmount;
  }

  calculatetotal_flip_cost() {
    this.total_flip_cost =
      this.total_investment +
      this.total_expense_holding_costs +
      this.total_expense_holding_costs +
      this.total_expense_holding_costs;
  }

  calculatebrrr_performance() {
    this.brrr_performance = this.brrr_result / this.total_investment;
  }

  calculatebrrr_result() {
    this.brrr_result = this.cash_out - this.total_investment;
  }

  calculatecash_out() {
    this.cash_out = this.equity_available - this.totalLoanAmount;
  }

  calculateequity_available() {
    this.equity_available =
      (this.afterRepairValueAmount * this.expected_loan_refinance) / 100;
  }

  calculatetotal_brrrr_cost() {
    this.total_brrrr_cost =
      parseInt(this.total_investment) +
      parseInt(this.total_expense_holding_costs) +
      parseInt(this.total_expense_holding_costs) +
      parseInt(this.totalRefinanceCosts);
  }

  calculatereturnInvestTotalRoi() {
    this.return_invest_total_roi =
      (this.return_on_investment_total_return / this.total_investment) * 100;
  }

  calculatereturnOnInvestmentTotalReturn() {
    console.log('this.CashFlowYearlyAmount', this.CashFlowYearlyAmount);
    console.log('this.mortgagePaydownAmount', this.mortgagePaydownAmount);
    console.log('this.AppreciationValue', this.AppreciationValue);
    this.return_on_investment_total_return =
      this.CashFlowYearlyAmount +
      this.mortgagePaydownAmount +
      this.AppreciationValue;
    console.log(
      'return_on_investment_total_return',
      this.return_on_investment_total_return
    );
  }

  updateInvestmentStructure(investment_structure) {
    this.investment_structure = investment_structure;
  }

  updateActivePartner(active_partner) {
    console.log('active_partner', active_partner);
    console.log('total_investment', this.total_investment);
    var self = this;
    this.active_partner = active_partner;
    this.passive_partner = 100 - active_partner;
    this.addPropertyStep7Form.patchValue({
      passive_partner: this.passive_partner,
    });
    this.price_per_share = this.total_investment / this.passive_partner;
    console.log('this.price_per_share', this.price_per_share);
    this.max_number_of_shares = this.total_investment / this.price_per_share;
    this.updateInvestmentAmount(this.amount_for_investment);
    setTimeout(function () {
      self.generateShareTable();
    }, 2000);
  }

  generateShareTable() {
    let mainArray: any = [];
    let passive_partner = this.passive_partner;
    console.log('passive_partner', passive_partner);
    var newMainArray = {
      PassivePartnerShare: passive_partner,
      InvestmentRequried: this.total_investment,
    };
    mainArray.push(newMainArray);
    for (let i = passive_partner; i > 0; i) {
      i--;
      i--;
      i--;
      i--;
      i--;
      if (i > 0) {
        let investValue = this.price_per_share * i;
        let newMainArray: any;
        newMainArray = {
          PassivePartnerShare: i,
          InvestmentRequried: investValue,
        };
        mainArray.push(newMainArray);
      }
      console.log(mainArray);
    }
    this.shareTableData = mainArray;
  }

  updateDesiredShares(desired_shares) {
    this.desired_shares = desired_shares;
    var investment_required_value = this.desired_shares * this.price_per_share;
    this.investment_required = investment_required_value;
    console.log('investment_required', this.investment_required);
  }

  updateInvestmentAmount(amount_for_investment) {
    this.amount_for_investment = amount_for_investment;
    this.share_count = this.amount_for_investment / this.price_per_share;
  }

  submitPerformanceDetails() {}
}
