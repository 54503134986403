<div class="dashboard_content Properties_AddDashFix">
    <form method="post" id="email-form" [formGroup]="addPropertyStep7Form" (ngSubmit)="submitPerformanceDetails()" class="addStep_From">
        <div class="dash-head">
            <div class="row align-items-center">
                <div class="col">
                    <h1 class="page-titles">Properties /<span> Add Property</span></h1>
                </div>
                <div class="col-auto">
                    <div class="left-head">
                        <a (click)="clickToBack()" class="btn-style1 me-2"><i
                                class="far fa-arrow-left me-2"></i> Back</a>
                        <button type="submit" class="btn-style1">Save & Next</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-container">
            <div class="container">
                <div class="customtabs_btns position-relative">
                    <ul class="list-unstyled mb-10">
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step1', uniqueId]">
                                <span class="tabcount">1</span>
                                Property
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step2', uniqueId]">
                                <span class="tabcount">2</span>
                                Financing
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step3', uniqueId]">
                                <span class="tabcount">3</span>
                                Investment
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step4', uniqueId]">
                                <span class="tabcount">4</span>
                                Income
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step5', uniqueId]">
                                <span class="tabcount">5</span>
                                Expenses
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step6', uniqueId]">
                                <span class="tabcount">6</span>
                                Payback
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step7', uniqueId]">
                                <span class="tabcount">7</span>
                                Performance
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">8</span>
                                Summary
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="stepcard">
                    <div *ngIf="dealTypeId === 1">
                        <div class="stephead-style1">
                            <span>Net Performance</span>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group d-none d-lg-flex mb-2">
                                <label></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="incomehead">
                                                Monthly
                                            </div>
                                        </div>
                                        <div class="col-auto mw-col">
                                            <div class="incomehead">
                                                Yearly
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Operating Income</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="textbox" *ngIf="OperatingIncomeMonthlyAmount > 0">
                                                ${{OperatingIncomeMonthlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="OperatingIncomeMonthlyAmount < 0">
                                                -${{OperatingIncomeMonthlyAmount | makePositive}}
                                            </div>
                                        </div>
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="textbox" *ngIf="OperatingIncomeYearlyAmount > 0">
                                                ${{OperatingIncomeYearlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="OperatingIncomeYearlyAmount < 0">
                                                -${{OperatingIncomeYearlyAmount | makePositive}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Vacancy</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="textbox" *ngIf="VacancyMonthlyAmount > 0">
                                                ${{VacancyMonthlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="VacancyMonthlyAmount < 0">
                                                -${{VacancyMonthlyAmount | makePositive}}
                                            </div>
                                        </div>
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="textbox" *ngIf="VacancyYearlyAmount > 0">
                                                ${{VacancyYearlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="VacancyYearlyAmount < 0">
                                                -${{VacancyYearlyAmount | makePositive}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Net Operating Income</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="textbox" *ngIf="NetOperatingIncomeMonthlyAmount > 0">
                                                ${{NetOperatingIncomeMonthlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="NetOperatingIncomeMonthlyAmount < 0">
                                                -${{NetOperatingIncomeMonthlyAmount | makePositive}}
                                            </div>
                                        </div>
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="textbox" *ngIf="NetOperatingIncomeYearlyAmount > 0">
                                                ${{NetOperatingIncomeYearlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="NetOperatingIncomeYearlyAmount < 0">
                                                -${{NetOperatingIncomeYearlyAmount | makePositive}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Loan Payback</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="textbox" >
                                                ${{totalLoanPaybackMonthlyAmount | number : '1.2-2'}}
                                            </div>
                                        </div>
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="textbox">
                                                ${{totalLoanPaybackYearlyAmount | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Cash Flow</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Monthly
                                            </div>
                                            <div class="textbox" *ngIf="CashFlowMonthlyAmount > 0">
                                                ${{CashFlowMonthlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="CashFlowMonthlyAmount < 0">
                                                -${{CashFlowMonthlyAmount | makePositive}}
                                            </div>
                                        </div>
                                        <div class="col-auto mw-col">
                                            <div class="incomehead d-lg-none">
                                                Yearly
                                            </div>
                                            <div class="textbox" *ngIf="CashFlowYearlyAmount > 0">
                                                ${{CashFlowYearlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="CashFlowYearlyAmount < 0">
                                                -${{CashFlowYearlyAmount | makePositive}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="stephead-style1">
                            <span>Financial Metrics</span>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Cash on Cash Return</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox" *ngIf="CashOnCashReturnValue > 0">
                                                {{CashOnCashReturnValue | number : '1.2-2'}}%
                                            </div>
                                            <div class="textbox redField" *ngIf="CashOnCashReturnValue < 0">
                                                {{CashOnCashReturnValue | number : '1.2-2'}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">CAP Rate (Sale Price)</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox" *ngIf="CAPRateSalePrice > 0">
                                                {{CAPRateSalePrice | number : '1.2-2' }}%
                                            </div>
                                            <div class="textbox redField" *ngIf="CAPRateSalePrice < 0">
                                                {{CAPRateSalePrice | number : '1.2-2' }}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">CAP Rate (Purchase Price)</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox" *ngIf="CAPRatePurchasePrice > 0">
                                                {{CAPRatePurchasePrice | number : '1.2-2' }}%
                                            </div>
                                            <div class="textbox redField" *ngIf="CAPRatePurchasePrice < 0">
                                                {{CAPRatePurchasePrice | number : '1.2-2' }}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Debt Coverage Ratio</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox" *ngIf="DebtCoverageRatioValue > 0">
                                                {{DebtCoverageRatioValue | number : '1.2-2' }}
                                            </div>
                                            <div class="textbox redField" *ngIf="DebtCoverageRatioValue < 0">
                                                {{DebtCoverageRatioValue | number : '1.2-2' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Annual Gross Rent Multiplier</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                {{AnnualGrossRentMultiplierValue | number : '1.2-2' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Operating Expense Ratio</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                {{OperatingExpenseRatioValue | number : '1.2-2' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="stephead-style1">
                            <span>Return on Investment</span>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Cash Flow</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox" *ngIf="CashFlowYearlyAmount > 0">
                                                ${{CashFlowYearlyAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="CashFlowYearlyAmount < 0">
                                                -${{CashFlowYearlyAmount | makePositive }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Mortgage Paydown</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox" *ngIf="mortgagePaydownAmount > 0">
                                                ${{mortgagePaydownAmount | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="mortgagePaydownAmount < 0">
                                                -${{mortgagePaydownAmount | makePositive}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Appreciation</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox" *ngIf="AppreciationValue > 0">
                                                ${{AppreciationValue | number : '1.2-2'}}
                                            </div>
                                            <div class="textbox redField" *ngIf="AppreciationValue < 0">
                                                -${{AppreciationValue | makePositive}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="totalRoi-box">
                                <div class="form-group mb-2">
                                    <label for="" class="mt-md-0">Total Return</label>
                                    <div class="flexwrap-box">
                                        <div class="row">
                                            <div class="col-auto mw-col">
                                                <div class="textbox" *ngIf="return_on_investment_total_return > 0">
                                                    ${{return_on_investment_total_return | number : '1.2-2'}}
                                                </div>
                                                <div class="textbox redField" *ngIf="return_on_investment_total_return < 0">
                                                    -${{return_on_investment_total_return | makePositive}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="" class="mt-md-0">Cash Invested</label>
                                    <div class="flexwrap-box">
                                        <div class="row">
                                            <div class="col-auto mw-col">
                                                <div class="textbox ">
                                                    ${{total_investment | number : '1.2-2'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="" class="mt-md-0 themecolor">Total ROI</label>
                                    <div class="flexwrap-box">
                                        <div class="row">
                                            <div class="col-auto mw-col">
                                                <div class="textbox themecolor">
                                                    <strong>{{return_invest_total_roi| number : '1.2-2'}}%</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="dealTypeId === 2">
                        <div class="stephead-style1">
                            <span>BRRRR Calculation</span>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Loan Amount</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{totalLoanAmount | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Investment</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_investment | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Expense Holding Costs</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_expense_holding_costs | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Financing Holding Costs</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_financing_holdings_costs | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Refinance Costs</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{totalRefinanceCosts | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total BRRRR Cost</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_brrrr_cost | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">After Repair Value</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{afterRepairValueAmount | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Loan to Value</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                {{expected_loan_refinance}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Equity Available</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{equity_available | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Cash Out</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{cash_out | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">BRRRR Result</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{brrr_result | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">BRRRR Performance</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                {{brrr_performance | number : '1.2-2'}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="dealTypeId === 3">
                        <div class="stephead-style1">
                            <span>FLIP Calculation</span>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Loan Amount</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{totalLoanAmount | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Investment</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_investment | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Expense Holding Costs</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_expense_holding_costs | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Financing Holding Costs</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_expense_holding_costs | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Selling Costs</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_expense_holding_costs | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Total Flip Cost</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{total_flip_cost | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">After Repair Value</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{afterRepairValueAmount | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">FLIP Result</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                ${{flip_result | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">FLIP Return on Investment</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                {{flip_return_on_investment | number : '1.2-2'}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Partnership Split</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Active Partner</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="input-group">
                                            <input (keyup)="updateActivePartner($event.target.value)"
                                                formControlName="active_partner" type="number" min="0"
                                                class="form-control1 fixw-input">
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Passive Partner</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="input-group">
                                            <input disabled type="number" formControlName="passive_partner" min="0"
                                                class="form-control1 fixw-input" value={{passive_partner}}>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="" class="mt-md-0 themecolor">Price Per Share</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-auto mw-col">
                                        <div class="textbox themecolor">
                                            <strong>${{price_per_share| number : '1.2-2'}}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="" class="mt-md-0">Max Number of Shares</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-auto mw-col">
                                        <div class="textbox">
                                            <strong>{{max_number_of_shares | number}}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-4">
                            <div class="form-group mb-2">
                                <div class="em">Investment by Amount</div>
                            </div>

                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Investment Amount</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="input-group textbox">
                                                <wj-input-number [isRequired]="false" format="n2" (keyup)="updateInvestmentAmount($event.target.value)"
                                                    formControlName="amount_for_investment" type="number"
                                                    class="form-control1 fixw-input ilsign-p"></wj-input-number>
                                                    <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Share Count</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                {{share_count | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <div class="form-group mb-2">
                                <div class="em">Investment by Shares</div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Desired Shares</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                                <wj-input-number [isRequired]="false" format="n0" formControlName="desired_shares"
                                                    (keyup)="updateDesiredShares($event.target.value)" type="number"
                                                    class="view_proInputNum withOut_space"></wj-input-number>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <label for="" class="mt-md-0">Investment Required</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-auto mw-col">
                                            <div class="textbox">
                                           ${{investment_required | number : '1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="stephead-style1">
                        <span>Share Table</span>
                    </div>
                    <div class="stepform-style1">

                        <div class="form-group mb-2">
                            <div class="w-100">
                                <div class="row">
                                    <div class="col-6 fixwidth-col">
                                        <div class="em fst-normal">
                                            Passive Partner Share
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="em fst-normal">
                                            Investment Requried
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="form-group mb-2" *ngFor="let singleRecord of shareTableData; let i = index">
                            <div class="w-100">
                                <div class="row">
                                    <div class="col-6 fixwidth-col">
                                        <div class="textbox">
                                            {{singleRecord?.PassivePartnerShare}}%
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="textbox">
                                            ${{singleRecord?.InvestmentRequried | number : '1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Investment Structure</label>
                            <div class="flexwrap-box editorbox">
                                <ckeditor #editor id="exampleInputName4" formControlName="investment_structure"
                                    class="editorContent" type="divarea">
                                </ckeditor>
                            </div>
                        </div>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Property Management</label>
                            <div class="flexwrap-box editorbox">
                                <ckeditor #editor id="exampleInputName5" formControlName="property_management"
                                    class="editorContent" type="divarea">
                                </ckeditor>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="stepform-pagelink">
                        <button type="submit" class="pagelink-next">Next <i class="far fa-arrow-right ms-2"></i></button>
                    </div> -->
                </div>
            </div>
        </div>
    </form>
</div>