import { Component, OnInit } from '@angular/core';
import { PlansService } from '../../_services/plans.service';
import { PaymentService } from '../../_services/payment.service';
import { environment } from '../../../environments/environment';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var showHideAccordion: Function;

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit {
  public environment = environment;
  public allPlansRecords;
  priceType = 'Monthly';
  selectedClass = '';

  constructor(
    private _PlansService: PlansService,
    private _PaymentService: PaymentService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getAllPlansRecords();
  }

  getAllPlansRecords() {
    startLoader();
    this._PlansService.getAllActiveRecords().subscribe(
      (response: any) => {
        if (response.status == 200) {
          console.log(response.data);
          this.allPlansRecords = response.data;
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  clickToSubscription(plan_id) {
    startLoader();
    let data = {
      plan_id: plan_id,
    };
    this._PaymentService.createCheckoutSession(data).subscribe(
      (response: any) => {
        if (response.status == 200) {
          var redirectURL = response.redirect_url;
          console.log(response.data);
          window.location.replace(redirectURL);
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  updatePriceShowType() {
    if (this.priceType == 'Monthly') {
      this.priceType = 'Yearly';
    } else {
      this.priceType = 'Monthly';
    }
  }

  safeCinema(cinemaLoc) {
    return this.sanitizer.bypassSecurityTrustHtml(cinemaLoc);
  }
}
