import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private _ApiService: ApiService) {}

  getAllRecordsByUserId(searchParam) {
    return this._ApiService.post(
      'api/contacts/getAllRecordsByUserId',
      searchParam
    ); //Done
  }

  getById(id) {
    return this._ApiService.get('api/contacts/singleRecord/' + id); //Done
  }

  getActiveContactsByUserId(userId) {
    return this._ApiService.get(
      'api/contacts/getActiveRecordsByUserId/' + userId
    ); //Done
  }

  getActiveAgentContactsByUserId(userId) {
    return this._ApiService.get(
      'api/contacts/getActiveAgentContactsByUserId/' + userId
    ); //Done
  }

  create(data) {
    return this._ApiService.post('api/contacts/create', data); //Done
  }

  update(id, data) {
    return this._ApiService.put('api/contacts/update/' + id, data); //Done
  }

  delete(id) {
    return this._ApiService.delete('api/contacts/delete/' + id); //Done
  }
}
