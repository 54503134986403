import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../_services/user.service';
import { CommonService } from '../../_services/common.service';
import { AuthService } from '../../_services/auth.service';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../_services/global.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-user-columns',
  templateUrl: './user-columns.component.html',
  styleUrls: ['./user-columns.component.css'],
})
export class UserColumnsComponent implements OnInit {
  updateItemForm: FormGroup;
  editItemForm: FormGroup;
  public environment = environment;
  public user;
  public userColumnsData;
  myProfileform: FormGroup;
  user_columns: FormArray;
  productData: any;
  propertyarr: any = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _AuthService: AuthService,
    private globalservice: GlobalService,
    private _UserService: UserService,
    private _CommonService: CommonService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.getUserColumnsData();
    this.editItemForm = this.formBuilder.group({
      user_id: [this.user.id, Validators.required],
      properties: this.formBuilder.array([]),
    });
  }

  private getUserColumnsData() {
    this._CommonService
      .getPropertyColumnByUser(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.productData = response;
            let propertyarr = [];
            for (let i = 0; i <= this.productData.data.length; i++) {
              propertyarr.push(this.productData.data[i]);
            }
            let control = <FormArray>this.editItemForm.controls['properties'];
            if (this.productData.data.length > 0) {
              for (let i = 0; i < this.productData.data.length; i++) {
                let form = this.formBuilder.group({
                  column_name: [this.productData.data[i].column_name],
                  is_show: [this.productData.data[i].is_show],
                  display_order: [this.productData.data[i].display_order],
                });
                control.push(form);
              }
            }
          } else {
            checkTosterResponse(response.message, 'Error');
            return false;
          }
        },
        (error) => {}
      );
  }

  onSubmitUserColumnsData(form_data) {
    startLoader();
    let searchData = {
      user_id: this.user.id,
      productData: JSON.stringify(form_data['properties']),
    };
    console.log(form_data);
    this._CommonService
      .updateUserColumnsData(searchData)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            checkTosterResponse(response.message, 'Success');
            this.router.navigate(['/properties/list']);
          } else {
            stopLoader();
            checkTosterResponse(response.message, 'Error');
            return false;
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }
  
}
