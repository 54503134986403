import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private _ApiService: ApiService,
    private _AuthService: AuthService
  ) { }

  getTopInvestors(userId) {
    return this._ApiService.get('api/dashboard/getTopInvestors/' + userId); //Done
  }

  getTopDeals(userId) {
    return this._ApiService.get('api/dashboard/getTopDeals/' + userId); //Done
  }

  getInvestorBySource(userId) {
    return this._ApiService.get('api/dashboard/getInvestorBySource/' + userId); //Done
  }

  getDealsByCity(userId) {
    return this._ApiService.get('api/dashboard/getDealsByCity/' + userId); //Done
  }

  getDealsByMonth(userId) {
    return this._ApiService.get('api/dashboard/getDealsByMonth/' + userId); //Done
  }

  getDealPipelines(userId) {
    return this._ApiService.get('api/dashboard/getDealPipelines/' + userId); //Done
  }
}
