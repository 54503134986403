<div class="dashboard_content Properties_AddDashFix">
    <div class="dash-head">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="page-titles">Properties /<span> View Property</span></h1>
            </div>
            <div class="col-auto">
                <div class="left-head">
                    <a [routerLink]="['/properties/add-property-step2', uniqueId]" class="btn-style1"><i
                            class="far fa-pencil me-2"></i> Edit</a>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-container addStep_From">
        <div class="container">
            <app-propertybar [currentSelectedStage]="2" [stageType]="1"></app-propertybar>
            <div class="stepcard">
                <form method="post" id="email-form" [formGroup]="addPropertyStep2Form"
                    (ngSubmit)="onaddPropertyStep2FormSubmit()">
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Currency <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <select disabled class="form-select" formControlName="currency" id=""
                                    (change)="updateCurrencySymbol($event.target.value)">
                                    <option value="">Select Currency</option>
                                    <option value="CAD">CAD</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Sale Price <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                        class="form-control1 ilsign-p" type="number" formControlName="sale_price" id="">
                                    </wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Purchase Price <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                        class="form-control1 ilsign-p" type="number"
                                        (keyup)="calculateDoorPrice($event.target.value)"
                                        formControlName="purchase_price " id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">After Repair Value <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                        class="form-control1 ilsign-p" type="number"
                                        formControlName="after_repair_value" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Door Price <span class="required-error">*</span></label>
                            <div class="flexwrap-box position-relative">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                        class="form-control1 ilsign-p" type="number" formControlName="door_price" id="">
                                    </wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Financing Required? <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <select disabled class="form-select" formControlName="financing_required" id=""
                                    (change)="updateFinancingFields($event.target.value)">
                                    <option value="">Select Financing</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-4 w-100 position-relative min-h-loan" *ngIf="financingField == 'Yes'">
                            <div class="">
                                <div formArrayName="properties" class="repeatedCreate"
                                    *ngFor="let property of addPropertyStep2Form.get('properties')['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="w-100">
                                            <div class="stephead-style2">
                                                <h3>Loan {{i+1}}</h3>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Name of Loan <span class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <input readonly formControlName="loan_name" class="form-control1"
                                                        type="text" name="" id="">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Loan Type <span class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <select disabled formControlName="loan_type" class="form-select"
                                                        name="" id="">
                                                        <option value="">Select Loan Type</option>
                                                        <option value="Interest Only">Interest Only</option>
                                                        <option value="Amortized">Amortized</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group" *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Amortized'">
                                                <label for="">Loan Amount<span
                                                        class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <div class="input-group">
                                                        <wj-input-number readonly disabled="" [isRequired]="false"
                                                            [format]="fmt"
                                                            *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Amortized'"
                                                            (keyup)="calculateLoanAmount($event.target.value, i)"
                                                            formControlName="loan_amount_percentage" type="number"
                                                            class="form-control1"></wj-input-number>

                                                        <wj-input-number readonly disabled="" [isRequired]="false"
                                                            [format]="fmt"
                                                            *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Interest Only'"
                                                            (keyup)="calculateLoanAmount($event.target.value, i)"
                                                            formControlName="loan_amount_percentage" type="number"
                                                            class="form-control1"></wj-input-number>
                                                            <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Loan Amount <span class="required-error">*</span></label>
                                                <div class="flexwrap-box position-relative">
                                                    <div class="input-group">
                                                        <wj-input-number readonly disabled="" [isRequired]="false"
                                                            [format]="fmt"
                                                            *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Amortized'"
                                                            formControlName="loan_amount" type="number"
                                                            class="form-control1 ilsign-p"
                                                            (keyup)="calculateTotalLoanAmount($event.target.value)">
                                                        </wj-input-number>
                                                        <wj-input-number readonly disabled="" [isRequired]="false"
                                                            [format]="fmt"
                                                            *ngIf="addPropertyStep2Form.get('properties')['value'][i]['loan_type'] === 'Interest Only'"
                                                            formControlName="loan_amount" type="number"
                                                            class="form-control1 ilsign-p"
                                                            (keyup)="calculateTotalLoanPercent($event.target.value, i)">
                                                        </wj-input-number>
                                                    </div>
                                                    <span class="il-sign">$</span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Terms in Years <span
                                                        class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <div class="input-group">
                                                                <input readonly
                                                                    (keyup)="calculateMonths($event.target.value, i)"
                                                                    formControlName="terms_in_years" type="number"
                                                                    min="0" class="form-control1">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-7 ms-auto">
                                                            <div class="row">
                                                                <div class="col-md-auto ms-auto">
                                                                    <label for="" class="mt-2 min-w-auto">Terms in
                                                                        Months <span
                                                                            class="required-error">*</span></label>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="input-group">
                                                                        <input disabled
                                                                            formControlName="terms_in_months"
                                                                            type="number" min="0" class="form-control1">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Interest Rate (%) <span
                                                        class="required-error">*</span></label>
                                                <div class="flexwrap-box">
                                                    <div class="input-group">
                                                        <input readonly formControlName="interest_rate" type="number"
                                                            class="form-control1">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="financingField == 'No'">
                            <label for="">Total Funds Available<span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                        class="form-control1 ilsign-p" type="number"
                                        formControlName="total_funds_available" id=""></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="financingField == 'Yes'">
                            <label for="">Total Loan Amount <span class="required-error">*</span></label>
                            <div class="flexwrap-box  position-relative">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" [format]="fmt"
                                        class="form-control1 ilsign-p" disabled type="number"
                                        formControlName="total_loan_amount" id=""></wj-input-number>
                                </div>
                                <span class="il-sign">$</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>