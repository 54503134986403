import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  verifyString: any;

  constructor(
    private _UserService: UserService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.verifyString = this.router.url.split('reset-password/')[1].toString();

    this.resetPasswordForm = this.formBuilder.group({
      validate_string: [this.verifyString, Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    });
  }

  public doresetPassword() {
    let data = this.resetPasswordForm.value;
    if (this.resetPasswordForm.value.password == '') {
      checkTosterResponse('Password is required.', 'Error');
      return;
    }
    if (this.resetPasswordForm.value.confirm_password == '') {
      checkTosterResponse('Confirm Password is required.', 'Error');
      return;
    }
    if (
      this.resetPasswordForm.value.password !=
      this.resetPasswordForm.value.confirm_password
    ) {
      checkTosterResponse(
        "Confirm password didn't matched with the new password.",
        'Error'
      );
      return false;
    }
    startLoader();

    this._UserService.resetPassword(data).subscribe(
      (response: any) => {
        if (response.status == 200) {
          checkTosterResponse(response.message, 'Success');
          this.router.navigate(['/user/login']);
        } else {
          checkTosterResponse(response.message, 'Error');
        }
      },
      (error) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }
}
