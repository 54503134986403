import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-add-property-step3',
  templateUrl: './add-property-step3.component.html',
  styleUrls: ['./add-property-step3.component.css'],
})
export class AddPropertyStep3Component implements OnInit {
  uniqueId: string;
  recordId: string;
  dealTypeId: number;
  public user;
  addPropertyStep3Form: FormGroup;
  details: {};
  public fmt: any = 'n2';
  public acquisitionPercent: any = '';
  public downpaymentAmount: any = 0;
  public purchase_price_amount: any = 0;
  public landTransferTaxAmount: any = 0;
  public legalAmount: any = 0;
  public appraisalAmount: any = 0;
  public inspectionAmount: any = 0;
  public reserveAmount: any = 0;
  public acquisitionAmount: any = 0;
  public roofCostAmount: any = 0;
  public totalPurcahseCostAmount: any = 0;
  public totalRepairRenovationAmount: any = 0;
  public totalInvestmentAmount: any = 0;
  public refinanceInspectionAmount: any = 0;
  public refinanceAppraisalAmount: any = 0;
  public refinanceAttorneyFeesAmount: any = 0;
  public totalRefinanceCostAmount: any = 0;
  purchaseCostItems: any;
  purchaseItemArray: any = [];
  purchaseItems: FormArray;
  renovationCostItems: any;
  renovationItemArray: any = [];
  renovationItems: FormArray;
  refinanceCostItems: any;
  refinanceItemArray: any = [];
  refinanceItems: FormArray;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getAllPurhaseCostsRecordsByUniqueId();
    this.getAllRenovationCostsRecordsByUniqueId();
    this.getAllRefinanceCostsRecordsByUniqueId();
    this.getSingleRecord(this.uniqueId);

    this.addPropertyStep3Form = this.formBuilder.group({
      downpayment: ['', Validators.required],
      land_transfer_tax: ['', Validators.required],
      legal: ['', Validators.required],
      appraisal: ['', Validators.required],
      inspection: ['', Validators.required],
      reserve: ['', Validators.required],
      acquisition_percent: ['', Validators.required],
      acquisition: ['', Validators.required],
      total_purchase_costs: ['', Validators.required],
      roof_cost: ['', Validators.required],
      total_repair_renovation: ['', Validators.required],
      purchaseCostsData: ['', Validators.required],
      purchaseItems: this.formBuilder.array([]),
      renovationCostsData: ['', Validators.required],
      renovationItems: this.formBuilder.array([]),
      refinanceCostsData: ['', Validators.required],
      refinanceItems: this.formBuilder.array([]),
      unique_id: ['', Validators.required],
      user_id: [this.user.id, Validators.required],
      refinance_inspection: ['', Validators.required],
      refinance_appraisal: ['', Validators.required],
      refinance_attorney_fees: ['', Validators.required],
      total_refinance_costs: ['', Validators.required],
      total_investment: ['', Validators.required],
    });
  }

  getAllRenovationCostsRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllItemsRecordsByUniqueId(this.uniqueId, 'Renovation_Costs')
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.renovationCostItems = response;
            let renovationItemArray = [];
            for (let i = 0; i <= this.renovationCostItems.data.length; i++) {
              renovationItemArray.push(this.renovationCostItems.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep3Form.controls['renovationItems']
            );
            if (this.renovationCostItems.data.length > 0) {
              for (let i = 0; i < this.renovationCostItems.data.length; i++) {
                let form = this.formBuilder.group({
                  item_name: [this.renovationCostItems.data[i].item_name],
                  item_amount: [this.renovationCostItems.data[i].item_amount],
                  item_type: [this.renovationCostItems.data[i].item_type],
                });
                control.push(form);
              }
            }
            this.renovationItems = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getAllRefinanceCostsRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllItemsRecordsByUniqueId(this.uniqueId, 'Refinance_Costs')
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.refinanceCostItems = response;
            let refinanceItemArray = [];
            for (let i = 0; i <= this.refinanceCostItems.data.length; i++) {
              refinanceItemArray.push(this.refinanceCostItems.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep3Form.controls['refinanceItems']
            );
            if (this.refinanceCostItems.data.length > 0) {
              for (let i = 0; i < this.refinanceCostItems.data.length; i++) {
                let form = this.formBuilder.group({
                  item_name: [this.refinanceCostItems.data[i].item_name],
                  item_amount: [this.refinanceCostItems.data[i].item_amount],
                  item_type: [this.refinanceCostItems.data[i].item_type],
                });
                control.push(form);
              }
            }
            this.refinanceItems = control;
            this.calculateTotalRefinanceCostAmount();
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getAllPurhaseCostsRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllItemsRecordsByUniqueId(this.uniqueId, 'Purhase_Costs')
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.purchaseCostItems = response;
            let purchaseItemArray = [];
            for (let i = 0; i <= this.purchaseCostItems.data.length; i++) {
              purchaseItemArray.push(this.purchaseCostItems.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep3Form.controls['purchaseItems']
            );
            if (this.purchaseCostItems.data.length > 0) {
              for (let i = 0; i < this.purchaseCostItems.data.length; i++) {
                let form = this.formBuilder.group({
                  item_name: [this.purchaseCostItems.data[i].item_name],
                  item_amount: [this.purchaseCostItems.data[i].item_amount],
                  item_type: [this.purchaseCostItems.data[i].item_type],
                });
                control.push(form);
              }
            }
            this.purchaseItems = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  updateFieldValue(amount, type) {
    var amount = amount.replaceAll(',', '');
    if (type == 'land_transfer_tax') {
      if (amount >= 0) {
        this.landTransferTaxAmount = amount;
      } else {
        this.landTransferTaxAmount = 0;
        this.addPropertyStep3Form.patchValue({
          land_transfer_tax: amount,
        });
      }
    } else if (type == 'legal') {
      if (amount >= 0) {
        this.legalAmount = amount;
      } else {
        this.legalAmount = 0;
        this.addPropertyStep3Form.patchValue({
          legal: amount,
        });
      }
    } else if (type == 'appraisal') {
      if (amount >= 0) {
        this.appraisalAmount = amount;
      } else {
        this.appraisalAmount = 0;
        this.addPropertyStep3Form.patchValue({
          appraisal: amount,
        });
      }
    } else if (type == 'inspection') {
      if (amount >= 0) {
        this.inspectionAmount = amount;
      } else {
        this.inspectionAmount = 0;
        this.addPropertyStep3Form.patchValue({
          inspection: amount,
        });
      }
    } else if (type == 'reserve') {
      if (amount >= 0) {
        this.reserveAmount = amount;
      } else {
        this.reserveAmount = 0;
        this.addPropertyStep3Form.patchValue({
          reserve: amount,
        });
      }
    }
    this.calculateTotalPurchaseCosts();
  }

  updateTotalRepairRenovationAmount(amount, type) {
    var amount = amount.replaceAll(',', '');
    if (type == 'roof_cost') {
      if (amount) {
        if (amount > 0) {
          this.roofCostAmount = amount;
        } else {
          this.roofCostAmount = 0;
          this.addPropertyStep3Form.patchValue({
            roof_cost: '',
          });
        }
      }
    }
    this.calculateTotalRepairRenovationAmount();
  }

  calculateTotalInvestmentAmount() {
    this.totalInvestmentAmount =
      parseFloat(this.totalPurcahseCostAmount) +
      parseFloat(this.totalRepairRenovationAmount);
    this.addPropertyStep3Form.patchValue({
      total_investment: this.totalInvestmentAmount,
    });
  }

  calculateTotalRepairRenovationAmount() {
    this.totalRepairRenovationAmount = parseFloat(this.roofCostAmount);
    let totalRepairRenovationAmountValue = this.totalRepairRenovationAmount;
    var renovationItemsCostData =
      this.addPropertyStep3Form.value.renovationItems;
    for (const singleRecord of renovationItemsCostData) {
      if (singleRecord.item_amount) {
        totalRepairRenovationAmountValue =
          totalRepairRenovationAmountValue +
          parseFloat(singleRecord.item_amount);
      }
    }
    console.log(
      'totalRepairRenovationAmountValue',
      totalRepairRenovationAmountValue
    );
    this.addPropertyStep3Form.patchValue({
      total_repair_renovation: totalRepairRenovationAmountValue,
    });
    this.totalRepairRenovationAmount = totalRepairRenovationAmountValue;
    this.calculateTotalInvestmentAmount();
  }

  calculateTotalPurchaseCosts() {
    this.totalPurcahseCostAmount =
      parseFloat(this.downpaymentAmount) +
      parseFloat(this.landTransferTaxAmount) +
      parseFloat(this.legalAmount) +
      parseFloat(this.appraisalAmount) +
      parseFloat(this.inspectionAmount) +
      parseFloat(this.reserveAmount) +
      parseFloat(this.acquisitionAmount);
    console.log(this.downpaymentAmount);
    console.log('this.landTransferTaxAmount', this.landTransferTaxAmount);
    console.log(this.legalAmount);
    console.log(this.appraisalAmount);
    console.log(this.inspectionAmount);
    console.log(this.reserveAmount);
    console.log(this.acquisitionAmount);
    let totalPurcahseCostAmountValue = this.totalPurcahseCostAmount;
    var purchaseItemsCostData = this.addPropertyStep3Form.value.purchaseItems;
    console.log('purchaseItemsCostData', purchaseItemsCostData);
    for (const singleRecord of purchaseItemsCostData) {
      if (singleRecord.item_amount) {
        totalPurcahseCostAmountValue =
          totalPurcahseCostAmountValue + parseFloat(singleRecord.item_amount);
      }
    }
    this.addPropertyStep3Form.patchValue({
      total_purchase_costs: totalPurcahseCostAmountValue,
    });
    this.totalPurcahseCostAmount = totalPurcahseCostAmountValue;
    this.calculateTotalInvestmentAmount();
  }

  updateTotalRefinanceCostAmount(amount, type) {
    var amount = amount.replaceAll(',', '');
    if (type == 'refinance_inspection') {
      if (amount > 0) {
        this.refinanceInspectionAmount = amount;
      } else {
        this.refinanceInspectionAmount = 0;
        this.addPropertyStep3Form.patchValue({
          refinance_inspection: '',
        });
      }
    } else if (type == 'refinance_appraisal') {
      if (amount > 0) {
        this.refinanceAppraisalAmount = amount;
      } else {
        this.refinanceAppraisalAmount = 0;
        this.addPropertyStep3Form.patchValue({
          refinance_appraisal: '',
        });
      }
    } else if (type == 'refinance_attorney_fees') {
      if (amount > 0) {
        this.refinanceAttorneyFeesAmount = amount;
      } else {
        this.refinanceAttorneyFeesAmount = 0;
        this.addPropertyStep3Form.patchValue({
          refinance_attorney_fees: '',
        });
      }
    }
    this.calculateTotalRefinanceCostAmount();
  }

  calculateTotalRefinanceCostAmount() {
    this.totalRefinanceCostAmount =
      parseFloat(this.refinanceInspectionAmount) +
      parseFloat(this.refinanceAppraisalAmount) +
      parseFloat(this.refinanceAttorneyFeesAmount);
    let totalRefinanceCostAmountValue = this.totalRefinanceCostAmount;
    var refinanceItemsCostData = this.addPropertyStep3Form.value.refinanceItems;
    for (const singleRecord of refinanceItemsCostData) {
      totalRefinanceCostAmountValue =
        totalRefinanceCostAmountValue + parseFloat(singleRecord.item_amount);
    }
    console.log('totalRefinanceCostAmountValue', totalRefinanceCostAmountValue);
    this.addPropertyStep3Form.patchValue({
      total_refinance_costs: totalRefinanceCostAmountValue,
    });
    this.totalRefinanceCostAmount = totalRefinanceCostAmountValue;
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.recordId = response.data.id;
            this.dealTypeId = response.data.deal_type_id;
            this.purchase_price_amount = response.data.purchase_price;
            this.addPropertyStep3Form.patchValue(response.data);
            if (response.data.financing_required == 'Yes') {
              console.log('financing_required');
              var downpaymentValue =
                response.data.purchase_price - response.data.total_loan_amount;
            } else {
              console.log('total_funds_available');
              console.log(response.data.purchase_price);
              console.log(response.data.total_funds_available);
              var downpaymentValue =
                response.data.purchase_price -
                response.data.total_funds_available;
              console.log('downpaymentValue', downpaymentValue);
            }
            console.log('downpaymentValue', downpaymentValue);
            this.addPropertyStep3Form.patchValue({
              downpayment: downpaymentValue,
            });
            this.downpaymentAmount = downpaymentValue;
            this.acquisitionPercent = response.data.acquisition_percent;

            var acquisitionValue =
              (this.acquisitionPercent * response.data.purchase_price) / 100;
            this.acquisitionAmount = acquisitionValue;
            this.addPropertyStep3Form.patchValue({
              acquisition: acquisitionValue,
            });
            this.landTransferTaxAmount = response.data.land_transfer_tax
              ? response.data.land_transfer_tax
              : 0;
            this.legalAmount = response.data.legal ? response.data.legal : 0;
            this.appraisalAmount = response.data.appraisal
              ? response.data.appraisal
              : 0;
            this.inspectionAmount = response.data.inspection
              ? response.data.inspection
              : 0;
            this.reserveAmount = response.data.reserve
              ? response.data.reserve
              : 0;
            this.totalPurcahseCostAmount = response.data.total_purchase_costs
              ? response.data.total_purchase_costs
              : 0;
            // this.calculateTotalPurchaseCosts();

            this.roofCostAmount = response.data.roof_cost
              ? response.data.roof_cost
              : 0;
            this.totalRepairRenovationAmount = response.data
              .total_repair_renovation
              ? response.data.total_repair_renovation
              : 0;
            // this.calculateTotalRepairRenovationAmount();

            this.totalInvestmentAmount = response.data.total_investment;
            // this.calculateTotalInvestmentAmount();

            /* for BRRR Deal Type */
            this.refinanceInspectionAmount = response.data.refinance_inspection;
            this.refinanceAppraisalAmount = response.data.refinance_appraisal;
            this.refinanceAttorneyFeesAmount =
              response.data.refinance_attorney_fees;
            this.totalRefinanceCostAmount = response.data.total_refinance_costs;
            this.calculateTotalRefinanceCostAmount();
            /* for BRRR Deal Type */
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  calculateAcquisition(percent) {
    this.acquisitionPercent = percent.replaceAll(',', '');
    var acquisitionValue =
      (this.acquisitionPercent * this.purchase_price_amount) / 100;
    this.acquisitionAmount = acquisitionValue;
    this.addPropertyStep3Form.patchValue({
      acquisition: acquisitionValue,
    });
    console.log('acquisitionAmount', this.acquisitionAmount);
    this.calculateTotalPurchaseCosts();
  }

  clickToBack() {
    this.onAddPropertyStep3FormSubmit('back');
  }

  onAddPropertyStep3FormSubmit(type: any = '') {
    if (this.addPropertyStep3Form.value.downpayment == '') {
      checkTosterResponse('Please enter Downpayment.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.land_transfer_tax === '') {
      checkTosterResponse('Please enter Land Transfer Tax.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.legal === '') {
      checkTosterResponse('Please enter Legal.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.appraisal === '') {
      checkTosterResponse('Please enter Appraisal.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.inspection === '') {
      checkTosterResponse('Please enter Inspection.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.reserve === '') {
      checkTosterResponse('Please enter Reserve.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.acquisition_percent == '') {
      checkTosterResponse('Please enter Acquisition in %.', 'Error');
      return false;
    }
    if (this.addPropertyStep3Form.value.acquisition == '') {
      checkTosterResponse('Please enter Acquisition.', 'Error');
      return false;
    }
    var purchaseItemsData = this.addPropertyStep3Form.value.purchaseItems;
    if (purchaseItemsData.length >= 1) {
      for (const singleRecord of purchaseItemsData) {
        if (singleRecord.item_type == '') {
          checkTosterResponse('Please enter item type.', 'Error');
          return false;
        }
        if (singleRecord.item_name == '') {
          checkTosterResponse('Please enter item name.', 'Error');
          return false;
        }
        if (singleRecord.item_amount == '') {
          checkTosterResponse('Please enter item amount.', 'Error');
          return false;
        }
      }
    }
    if (this.addPropertyStep3Form.value.total_purchase_costs == '') {
      checkTosterResponse('Please enter total purchase costs.', 'Error');
      return false;
    }
    var renovationItemsData = this.addPropertyStep3Form.value.renovationItems;
    if (renovationItemsData.length >= 1) {
      for (const singleRecord of renovationItemsData) {
        if (singleRecord.item_type == '') {
          checkTosterResponse('Please enter item type.', 'Error');
          return false;
        }
        if (singleRecord.item_name == '') {
          checkTosterResponse('Please enter item name.', 'Error');
          return false;
        }
        if (singleRecord.item_amount == '') {
          checkTosterResponse('Please enter item amount.', 'Error');
          return false;
        }
      }
    }
    //Only for BRRR Deal type
    if (this.dealTypeId == 2) {
      if (this.addPropertyStep3Form.value.refinance_inspection == '') {
        checkTosterResponse('Please enter Inspection.', 'Error');
        return false;
      }
      if (this.addPropertyStep3Form.value.refinance_appraisal == '') {
        checkTosterResponse('Please enter Appraisal.', 'Error');
        return false;
      }
      if (this.addPropertyStep3Form.value.refinance_attorney_fees == '') {
        checkTosterResponse('Please enter Attorney Fees.', 'Error');
        return false;
      }
      if (this.addPropertyStep3Form.value.total_refinance_costs == '') {
        checkTosterResponse('Please enter Total Refinance Costs.', 'Error');
        return false;
      }
      var refinanceItemsData = this.addPropertyStep3Form.value.refinanceItems;
      if (refinanceItemsData.length >= 1) {
        for (const singleRecord of refinanceItemsData) {
          if (singleRecord.item_type == '') {
            checkTosterResponse('Please enter item type.', 'Error');
            return false;
          }
          if (singleRecord.item_name == '') {
            checkTosterResponse('Please enter item name.', 'Error');
            return false;
          }
          if (singleRecord.item_amount == '') {
            checkTosterResponse('Please enter item amount.', 'Error');
            return false;
          }
        }
      }
    }
    //Only for BRRR Deal type
    startLoader();
    console.log(this.addPropertyStep3Form.value);
    var purchaseCostsData = this.addPropertyStep3Form.value.purchaseItems;
    this.addPropertyStep3Form.patchValue({
      purchaseCostsData: JSON.stringify(purchaseCostsData),
    });
    var renovationCostsData = this.addPropertyStep3Form.value.renovationItems;
    this.addPropertyStep3Form.patchValue({
      renovationCostsData: JSON.stringify(renovationCostsData),
    });
    //Only for BRRR Deal type
    if (this.dealTypeId == 2) {
      var refinanceCostsData = this.addPropertyStep3Form.value.refinanceItems;
      this.addPropertyStep3Form.patchValue({
        refinanceCostsData: JSON.stringify(refinanceCostsData),
      });
    }
    //Only for BRRR Deal type
    console.log(this.addPropertyStep3Form.value);
    this._PropertiesService
      .updateRecordStepInvestment(
        this.recordId,
        this.addPropertyStep3Form.value
      )
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            if (type == 'back') {
              this.router.navigate([
                '/properties/add-property-step2/' + this.uniqueId,
              ]);
            } else {
              checkTosterResponse(response.message, 'Success');
              this.router.navigate([
                '/properties/add-property-step4/' + this.uniqueId,
              ]);
            }
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  addProperty(): void {
    this.purchaseItems = this.addPropertyStep3Form.get(
      'purchaseItems'
    ) as FormArray;
    this.purchaseItems.push(this.createProperty());
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      item_type: 'Purhase_Costs',
      item_name: '',
      item_amount: '',
    });
  }

  removeProperty(rowIndex: number) {
    this.purchaseItems.removeAt(rowIndex);
    this.calculateTotalPurchaseCosts();
  }

  addRenovationItem(): void {
    this.renovationItems = this.addPropertyStep3Form.get(
      'renovationItems'
    ) as FormArray;
    this.renovationItems.push(this.createRenovationItem());
  }

  createRenovationItem(): FormGroup {
    return this.formBuilder.group({
      item_type: 'Renovation_Costs',
      item_name: '',
      item_amount: '',
    });
  }

  removeRenovationItem(rowIndex: number) {
    this.renovationItems.removeAt(rowIndex);
    this.calculateTotalRepairRenovationAmount();
  }

  addRefinanceItem(): void {
    this.refinanceItems = this.addPropertyStep3Form.get(
      'refinanceItems'
    ) as FormArray;
    this.refinanceItems.push(this.createRefinanceItem());
  }

  removeRefinanceItem(rowIndex: number) {
    this.refinanceItems.removeAt(rowIndex);
    this.calculateTotalRefinanceCostAmount();
  }

  createRefinanceItem(): FormGroup {
    return this.formBuilder.group({
      item_type: 'Refinance_Costs',
      item_name: '',
      item_amount: '',
    });
  }
}
