import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  constructor(private _ApiService: ApiService) {}

  deleteRecordById(id) {
    return this._ApiService.delete('api/properties/delete/' + id); //Done
  }

  getAllRecordsByUserId(searchParam) {
    return this._ApiService.post(
      'api/properties/getAllRecordsByUserId',
      searchParam
    ); //Done
  }

  generateRecord(data) {
    return this._ApiService.post('api/properties/generateRecord', data); //Done
  }

  singleRecordByUniqueId(uniqueId) {
    return this._ApiService.get(
      'api/properties/singleRecordByUniqueId/' + uniqueId
    ); //Done
  }

  singleRecordOtherDetailsByUniqueId(uniqueId) {
    return this._ApiService.get(
      'api/properties/singleRecordOtherDetailsByUniqueId/' + uniqueId
    ); //Done
  }

  updateRecord(id, data) {
    return this._ApiService.put(
      'api/properties/updateRecordStepOne/' + id,
      data
    ); //Done
  }

  updateRecordStepFinancing(id, data) {
    return this._ApiService.put(
      'api/properties/updateRecordStepFinancing/' + id,
      data
    ); //Done
  }

  updateWholesaleRecordStep2(id, data) {
    return this._ApiService.put(
      'api/properties/updateWholesaleRecordStep2/' + id,
      data
    ); //Done
  }

  updateRecordStepInvestment(id, data) {
    return this._ApiService.put(
      'api/properties/updateRecordStepInvestment/' + id,
      data
    ); //Done
  }

  //Step 4
  updateRecordStepIncome(property_id, data) {
    return this._ApiService.put(
      'api/properties/updateRecordStepIncome/' + property_id,
      data
    ); //Done
  }

  //Step 5
  updateRecordStepExpenses(property_id, data) {
    return this._ApiService.put(
      'api/properties/updateRecordStepExpenses/' + property_id,
      data
    ); //Done
  }

  //Step 7
  updateRecordStepPerformance(property_id, data) {
    return this._ApiService.put(
      'api/properties/updateRecordStepPerformance/' + property_id,
      data
    ); //Done
  }

  //for Step1
  getAllMonthlyRentRecordsByUniqueId(uniqueId) {
    return this._ApiService.get(
      'api/properties/getAllMonthlyRentRecordsByUniqueId/' + uniqueId
    ); //Done
  }

  //Upload New Image
  uploadNewImage(data) {
    return this._ApiService.post('api/propertiesimages/insertNewRecord', data); //Done
  }

  getAllImagesRecordsByUniqueId(uniqueId) {
    return this._ApiService.get(
      'api/propertiesimages/getAllImagesRecordsByUniqueId/' + uniqueId
    ); //Done
  }

  deleteImageById(id) {
    return this._ApiService.delete('api/propertiesimages/delete/' + id); //Done
  }

  //for Step2
  getAllLoansRecordsByUniqueId(uniqueId) {
    return this._ApiService.get(
      'api/properties/getAllLoansRecordsByUniqueId/' + uniqueId
    ); //Done
  }

  //for Step3
  getAllItemsRecordsByUniqueId(uniqueId, itemType) {
    return this._ApiService.get(
      'api/properties/getAllItemsRecordsByUniqueId/' + uniqueId + '/' + itemType
    ); //Done
  }

  //for Step5
  getAllExpensesItemsRecordsByUniqueId(uniqueId, itemType) {
    return this._ApiService.get(
      'api/properties/getAllExpensesItemsRecordsByUniqueId/' +
        uniqueId +
        '/' +
        itemType
    ); //Done
  }

  //for Step4
  getAllIncomeItemsByUniqueId(uniqueId) {
    return this._ApiService.get(
      'api/properties/getAllIncomeItemsByUniqueId/' + uniqueId
    ); //Done
  }

  downloadReportPdf(uniqueId) {
    return this._ApiService.getReportCall('index.php?uniqueId='+uniqueId);
  }
  
  downloadSharingReportPdf(uniqueId) {
    return this._ApiService.getReportCall('sharing.php?uniqueId='+uniqueId);
  }

  getProjectionsReportByUniqueId(uniqueId) {
    return this._ApiService.get('api/common/getProjectionsReportByUniqueId/'+uniqueId); //Done
  }

}
