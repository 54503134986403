import { Component, OnInit, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PropertiesService } from '../../_services/properties.service';
import { environment } from '../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var iniImageMagnify: Function;

@Component({
  selector: 'app-propertybar',
  templateUrl: './propertybar.component.html',
  styleUrls: ['./propertybar.component.scss'],
})
export class PropertybarComponent implements OnInit {
  @Input() currentSelectedStage: number;
  @Input() stageType: string;
  public environment = environment;
  uniqueId: string;
  recordId: string;
  addPropertyStep1Form: FormGroup;
  imageForm: FormGroup;
  details: {};
  completeLevel: any;

  constructor(
    private route: ActivatedRoute,
    private _PropertiesService: PropertiesService
  ) {}

  ngOnInit() {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.details = response.data;
            this.completeLevel = response.data.complete_level;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  showErrorForEditProperty() {
    checkTosterResponse(
      'Please edit the property to complete the step.',
      'Error'
    );
  }
}
