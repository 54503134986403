<div class="dashboard_content">
  <div class="dash-head">
    <div class="row align-items-center">
      <div class="col-6">
        <h1 class="page-titles">Plans</h1>
      </div>
    </div>
  </div>
  <div class="dashboard-container">
    <div class="pricing-wrapper">
      <div class="container">
        <div class="pricing-plans">
          <!-- <div class="plan-check">
            <div class="form-check form-switch p-0">
              <label class="form-check-label" for="flexSwitchCheckDefault">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                  (click)="updatePriceShowType()">
                <span class="paymonth">Pay Monthly</span>
                <span class="payyear">Pay Yearly</span>
              </label>
            </div>
          </div> -->
          <div class="plans-row">
            <div class="row">
              <div class="pricing-block col-lg-4 col-md-6 col-sm-12"
                *ngFor="let singleRecord of allPlansRecords; let i = index">
                <div class="inner-box">

                  <div class="icon-box">
                    <div class="planName">
                      {{singleRecord.plan_name}}
                    </div>
                    <!-- <div class="icon-outer">
                      <i class="fas fa-paper-plane"></i>
                    </div> -->
                  </div>
                  <div class="price-box">
                    <h4 class="price" *ngIf="priceType == 'Monthly'"> <sup>$</sup>{{singleRecord.monthly_price}}<span class="plan_label_price">/Month</span></h4>
                    <h4 class="price" *ngIf="priceType == 'Yearly'"> <sup>$</sup>{{singleRecord.yearly_price}}<span class="plan_label_price">/Year</span></h4>
                    <div class="title"> {{singleRecord.label_title}}</div>
                  </div>
                  <ul class="features list-unstyled">
                    <li class="true">{{singleRecord.contacts}} Contacts</li>
                    <li class="true">{{singleRecord.properties}} Properties</li>
                    <li class="true">{{singleRecord.property_photos}} Property Photos</li>
                    <li class="true" *ngFor="let singleRecordService of singleRecord.selectedServices; let is = index">
                      {{singleRecordService.title}}</li>
                    <li class="false"
                      *ngFor="let singleRecordService of singleRecord.notSelectedServices; let is = index">
                      {{singleRecordService.title}}</li>
                  </ul>
                  <div class="btn-box">
                    <a href="javascript:void(0);" class="signupfree-btn"
                      (click)="clickToSubscription(singleRecord.plan_id)">Upgrade</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>