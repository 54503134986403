import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { AuthService } from '../../_services/auth.service';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

declare var initDealMonthGraph: Function;
declare var initDealMonthCity: Function;
declare var initInvestorBySource: Function;
declare var initDealPipelineGraph: Function;
declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public user;
  public environment = environment;
  public allInvestors;
  public allTopDeals;
  public investorBySource;
  public dealCities;
  public dealCitiesCounts;
  public allMonths;
  public allMonthsData;
  public allDealPipelines;

  constructor(
    private _AuthService: AuthService,
    private _DashboardService: DashboardService
  ) {
    this.user = this._AuthService.getUserInfo();
    console.log(this.user);
  }

  ngOnInit() {
    this.getTopInvestors();
    this.getTopDeals();
    this.getInvestorBySource();
    this.getDealsByCity();
    this.getDealsByMonth();
    this.getDealPipelines();
  }

  private getTopInvestors() {
    startLoader();
    this._DashboardService
      .getTopInvestors(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.allInvestors = response.data;
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  private getTopDeals() {
    startLoader();
    this._DashboardService
      .getTopDeals(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.allTopDeals = response.data;
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  private getInvestorBySource() {
    startLoader();
    this._DashboardService
      .getInvestorBySource(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.investorBySource = response.data;
            initInvestorBySource(response.data);
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  private getDealsByCity() {
    startLoader();
    this._DashboardService
      .getDealsByCity(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.dealCities = response.data;
            console.log("response.data", response.data);
            this.dealCitiesCounts = response.records;
            initDealMonthCity(response.data, response.records);
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  private getDealsByMonth() {
    startLoader();
    this._DashboardService
      .getDealsByMonth(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.allMonths = response.data;
            this.allMonthsData = response.records;
            initDealMonthGraph(response.data, response.records);
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  private getDealPipelines() {
    startLoader();
    this._DashboardService
      .getDealPipelines(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.allDealPipelines = response.data;
            initDealPipelineGraph(response.data);
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }
}
