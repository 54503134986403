<div class="dashboard_content ">
  <div class="dash-head">
    <div class="row align-items-center">
      <div class="col-6">
        <h1 class="page-titles">Memberships</h1>
      </div>
    </div>
  </div>
  <div class="dashboard-container ">
    <div class="pricing-wrapper">
      <div class="container">
          <div class="row">
            <div class="col-12" *ngIf="userSubscription">
              <div id='card' class="subScribCard">
                <div id='upper-side' class="bg-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shield-check" viewBox="0 0 16 16">
                    <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                    <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                  </svg>
                  <h3 id='status'>
                    {{userSubscription?.status}}
                  </h3>
                </div>
                <div id='lower-side'>
                  <p id='message'>
                    Congratulations, your subscription has been activated.
                  </p>
                  <p class="memberId_block mb-3">
                    Your Id: 
                    <strong>
                      {{userSubscription?.subscription_id}}
                    </strong>
                  </p>
                  <div *ngIf="userSubscription?.status == 'Active'"> 
                    <a  (click)="cancelConfirmationPopup('cancel-plan-model')" href="javascript:void(0);" class="btn-style1 d-inline-block">Cancel Subscription</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12" *ngIf="!userSubscription">
              <div id='card' class="subScribCard ">
                <div id='upper-side' class="bg-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                  </svg>
                  <h3 id='status'>
                    Failed
                  </h3>
                </div>
                <div id='lower-side'>
                  <p id='message'>
                    Sorry!, you have not subscribe any plan yet. Please check plans here.
                  </p>
                  <a href="javascript:void(0);" [routerLink]="['/plans']" class="btn-style1 d-inline-block">Plans</a>
                </div>
              </div>
            </div> -->
          </div>
      </div>
    </div>
  </div>
</div>

<div class="modal right fade tire-details show buy_course logout-confirm" id="cancel-plan-model" tabindex="-1" role="dialog"
  aria-labelledby="right_modal_sm" aria-modal="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <a href="javascript:void(0);" (click)="closePopup('cancel-plan-model')" class="close-panel ripplelink">
        <img src="assets/img/close_icon.png">
      </a>
      <div class="top_sec">
        <p class="mb-0 mt-4">Are you sure do you want to Cancel Your Membership? </p>
      </div>
      <div class="bottom_btnsec text-center">
        <a href="javascript:void(0);" (click)="cancelSubscription()" class="btn btnstyle-1 me-2 ">Submit</a>
        <a href="javascript:void(0);" (click)="closePopup('cancel-plan-model')" class="btn btnstyle-1">Cancel</a>
      </div>
    </div>
  </div>
</div>