import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view-property-step2',
  templateUrl: './view-property-step2.component.html',
  styleUrls: ['./view-property-step2.component.css'],
})
export class ViewPropertyStep2Component implements OnInit {
  public fmt: any = 'n2';
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep2Form: FormGroup;
  details: {};
  public currencySymbol = '$';
  public numberOfDoors = 0;
  public purchase_price: number = 0;
  public financingField = 'No';
  productData: any;
  doorPrice: any;
  propertyarr: any = [];
  properties: FormArray;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
    this.getAllLoansRecordsByUniqueId();

    this.addPropertyStep2Form = this.formBuilder.group({
      currency: ['', Validators.required],
      sale_price: ['', Validators.required],
      purchase_price: ['', Validators.required],
      after_repair_value: ['', Validators.required],
      door_price: ['', Validators.required],
      financing_required: ['', Validators.required],
      currency_symbol: ['', Validators.required],
      total_loan_amount: ['0', Validators.required],
      total_funds_available: ['', Validators.required],
      propertiesData: ['', Validators.required],
      unique_id: ['', Validators.required],
      user_id: [this.user.id, Validators.required],
      properties: this.formBuilder.array([]),
    });
  }

  getAllLoansRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllLoansRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.productData = response;
            let propertyarr = [];
            for (let i = 0; i <= this.productData.data.length; i++) {
              propertyarr.push(this.productData.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep2Form.controls['properties']
            );
            if (this.productData.data.length > 0) {
              for (let i = 0; i < this.productData.data.length; i++) {
                let form = this.formBuilder.group({
                  loan_name: [this.productData.data[i].loan_name],
                  loan_type: [this.productData.data[i].loan_type],
                  loan_amount_percentage: [
                    this.productData.data[i].loan_amount_percentage,
                  ],
                  loan_amount: [this.productData.data[i].loan_amount],
                  terms_in_years: [this.productData.data[i].terms_in_years],
                  terms_in_months: [this.productData.data[i].terms_in_months],
                  interest_rate: [this.productData.data[i].interest_rate],
                });
                control.push(form);
              }
            }
            this.properties = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  calculateTotalLoanAmount(amount) {
    console.log('fff---', amount);
    if (this.financingField == 'Yes') {
      let totalAmount = 0;
      var propertiesData = this.addPropertyStep2Form.value.properties;
      console.log('propertiesData', propertiesData);
      for (const singleRecord of propertiesData) {
        console.log(singleRecord.loan_amount);
        totalAmount = totalAmount + singleRecord.loan_amount;
      }
      this.addPropertyStep2Form.patchValue({ total_loan_amount: totalAmount });
      console.log(totalAmount);
    }
  }

  calculateTotalLoanPercent(amount, index) {
    var self = this;
    let propertiesData = this.addPropertyStep2Form.value.properties;
    console.log('propertiesData', propertiesData);
    let loan_amount = parseInt(propertiesData[index]['loan_amount']);
    let loan_type = propertiesData[index]['loan_type'];
    console.log('loan_type', loan_type);
    let purchase_price = this.purchase_price;
    if (loan_type == 'Interest Only') {
      setTimeout(function () {
        var loan_amount_percentage = (loan_amount / purchase_price) * 100;
        propertiesData[index]['loan_amount_percentage'] =
          loan_amount_percentage.toFixed(2);
        console.log(propertiesData);
        console.log(propertiesData[index]['loan_amount_percentage']);
        self.addPropertyStep2Form.patchValue({ properties: propertiesData });
        self.calculateTotalLoanAmount(0);
      }, 2000);
    }
  }

  calculateLoanAmount(amount, index) {
    var self = this;
    let propertiesData = this.addPropertyStep2Form.value.properties;
    console.log('propertiesData', propertiesData);
    let loan_amount_percentage = parseInt(
      propertiesData[index]['loan_amount_percentage']
    );
    let loan_type = propertiesData[index]['loan_type'];
    console.log('loan_type', loan_type);
    let purchase_price = this.purchase_price;
    console.log('loan_amount_percentage', loan_amount_percentage);
    console.log('purchase_price', purchase_price);
    console.log(
      'loan_amount_percentage * purchase_price / 100',
      (loan_amount_percentage * purchase_price) / 100
    );
    if (loan_type == 'Amortized') {
      setTimeout(function () {
        propertiesData[index]['loan_amount'] =
          (loan_amount_percentage * purchase_price) / 100;
        console.log(propertiesData);
        console.log(propertiesData[index]['loan_amount_percentage']);
        self.addPropertyStep2Form.patchValue({ properties: propertiesData });
        self.calculateTotalLoanAmount(0);
      }, 2000);
    }
  }

  calculateMonths(years, index) {
    var self = this;
    if (this.financingField == 'Yes') {
      console.log(index);
      let propertiesData = this.addPropertyStep2Form.value.properties;
      console.log('propertiesData', propertiesData);
      setTimeout(function () {
        propertiesData[index]['terms_in_months'] =
          propertiesData[index]['terms_in_years'] * 12;
        console.log(propertiesData);
        console.log(propertiesData[index]['terms_in_years']);
        self.addPropertyStep2Form.patchValue({ properties: propertiesData });
      }, 2000);
    }
  }

  addProperty(): void {
    this.properties = this.addPropertyStep2Form.get('properties') as FormArray;
    this.properties.push(this.createProperty());
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      loan_name: '',
      loan_amount: '',
      terms_in_years: '',
      interest_rate: '',
      terms_in_months: '',
      loan_amount_percentage: '',
      loan_type: '',
    });
  }

  removeProperty(rowIndex: number) {
    this.properties.removeAt(rowIndex);
    this.calculateTotalLoanAmount(0);
  }

  updateFinancingFields(event) {
    this.financingField = event;
  }

  updateCurrencySymbol(event) {
    this.addPropertyStep2Form.patchValue({ currency_symbol: '$' });
    this.currencySymbol = '$';
    console.log(event);
  }

  calculateDoorPrice(purchase_price) {
    this.purchase_price = purchase_price;
    console.log(this.purchase_price);
    this.doorPrice = purchase_price / this.numberOfDoors;
    console.log(this.doorPrice);
    this.doorPrice = parseFloat(this.doorPrice).toFixed(2);
    console.log(this.doorPrice);
    this.addPropertyStep2Form.patchValue({ door_price: this.doorPrice });
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.recordId = response.data.id;
            this.addPropertyStep2Form.patchValue(response.data);
            this.numberOfDoors = response.data.number_of_doors;
            this.purchase_price = response.data.purchase_price;
            this.financingField = response.data.financing_required;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  onaddPropertyStep2FormSubmit() {}

  removeLoan(rowIndex: number) {
    console.log(this.properties);
    if (rowIndex > 0) {
      this.properties.removeAt(rowIndex);
    }
  }
}
