import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';
import { CommonService } from '../../../_services/common.service';
import { ContactsService } from '../../../_services/contacts.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var iniImageMagnify: Function;
declare var $: Function;

@Component({
  selector: 'app-view-property-step1',
  templateUrl: './view-property-step1.component.html',
  styleUrls: ['./view-property-step1.component.css'],
})
export class ViewPropertyStep1Component implements OnInit {
  public fmt: any = 'n2';
  public environment = environment;
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep1Form: FormGroup;
  imageForm: FormGroup;
  details: {};
  public imagesData;
  public contactsDropdown;
  public contactsAgentDropdown;
  public propertyTypesDropdown;
  productData: any;
  propertyarr: any = [];
  properties: FormArray;
  isMonthlyRent: any = 'Yes';
  // completeLevel: any;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _ContactsService: ContactsService,
    private _CommonService: CommonService,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
    // iniImageMagnify();
  }

  ngOnInit(): void {
    this.getActiveContactsByUserId();
    this.getActiveAgentContactsByUserId();
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
    this.getAllMonthlyRentRecordsByUniqueId(this.uniqueId);
    this.getAllImagesByUniqueId();
    this.getAllActivePropertiesTypes();

    this.addPropertyStep1Form = this.formBuilder.group({
      property_name: ['', Validators.required],
      unique_id: ['', Validators.required],
      number_of_doors: ['', Validators.required],
      building_class: ['', Validators.required],
      listing_id: ['', Validators.required],
      agent_id: ['', Validators.required],
      brokerage_id: ['', Validators.required],
      vacancy: ['', Validators.required],
      depreciation: ['', ''],
      land_value: ['', ''],
      street_address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      description: ['', Validators.required],
      about_city: ['', Validators.required],
      why_city: ['', Validators.required],
      total_monthly_rent: ['', Validators.required],
      is_monthly_rent: ['', Validators.required],
      property_tags: ['', Validators.required],
      notes: ['', Validators.required],
      building_foundation: ['', Validators.required],
      building_style: ['', Validators.required],
      exterior_finish: ['', Validators.required],
      heat_fuel: ['', Validators.required],
      estimated_appreciation: ['', ''],
      estimated_income_increase: ['', ''],
      estimated_expenses_increase: ['', ''],
      expected_selling_costs: ['', ''],
      expected_loan_refinance: ['', ''],
      year_built: ['', Validators.required],
      square_footage: ['', Validators.required],
      ownership_type: ['', Validators.required],
      property_type: ['', Validators.required],
      lot_size: ['', Validators.required],
      property_pitch: ['', Validators.required],
      zoning: ['', Validators.required],
      status: ['Active', Validators.required],
      user_id: [this.user.id, Validators.required],
      deal_type_id: [this.user.id, Validators.required],
      propertiesData: ['', Validators.required],
      properties: this.formBuilder.array([]),
    });

    this.imageForm = this.formBuilder.group(
      {
        property_id: ['', Validators.required],
        unique_id: [this.uniqueId, Validators.required],
        user_id: [this.user.id, Validators.required],
        file: [],
      },
      {}
    );
  }

  getAllActivePropertiesTypes() {
    startLoader();
    this._CommonService.getAllActivePropertiesTypes().subscribe(
      (response: any) => {
        if (response.status == 200) {
          console.log(response.data);
          this.propertyTypesDropdown = response.data;
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  getActiveAgentContactsByUserId() {
    startLoader();
    this._ContactsService
      .getActiveAgentContactsByUserId(this.user.id)
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.contactsAgentDropdown = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (err) => {
          stopLoader();
        },
        () => stopLoader()
      );
  }

  getActiveContactsByUserId() {
    startLoader();
    this._ContactsService.getActiveContactsByUserId(this.user.id).subscribe(
      (response: any) => {
        if (response.status == 200) {
          console.log(response.data);
          this.contactsDropdown = response.data;
        } else {
          checkTosterResponse(response.message, 'Error');
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  updateMonthlyRentField(event) {
    this.isMonthlyRent = event;
  }

  addProperty(): void {
    this.properties = this.addPropertyStep1Form.get('properties') as FormArray;
    this.properties.push(this.createProperty());
  }

  removeProperty(rowIndex: number) {
    this.properties.removeAt(rowIndex);
    this.calculateTotalMonthRent(0);
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      unit: '',
      number_beds: '',
      monthly_rent: '',
    });
  }

  calculateTotalMonthRent(amount) {
    console.log('fff', amount);
    if (this.isMonthlyRent == 'No') {
      let totalMonth = 0;
      var propertiesData = this.addPropertyStep1Form.value.properties;
      for (const singleRecord of propertiesData) {
        console.log(singleRecord.monthly_rent);
        totalMonth = totalMonth + singleRecord.monthly_rent;
      }
      this.addPropertyStep1Form.patchValue({ total_monthly_rent: totalMonth });
      console.log(totalMonth);
    }
  }

  getAllImagesByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllImagesRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.imagesData = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getAllMonthlyRentRecordsByUniqueId(uniqueId) {
    startLoader();
    this._PropertiesService
      .getAllMonthlyRentRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.productData = response;
            let propertyarr = [];
            for (let i = 0; i <= this.productData.data.length; i++) {
              propertyarr.push(this.productData.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep1Form.controls['properties']
            );
            if (this.productData.data.length > 0) {
              for (let i = 0; i < this.productData.data.length; i++) {
                let form = this.formBuilder.group({
                  unit: [this.productData.data[i].unit],
                  monthly_rent: [this.productData.data[i].monthly_rent],
                  number_beds: [this.productData.data[i].number_beds],
                });
                control.push(form);
              }
            }
            this.properties = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.details = response.data;
            this.recordId = response.data.id;
            this.isMonthlyRent = response.data.is_monthly_rent;
            this.addPropertyStep1Form.patchValue(response.data);
            this.imageForm.patchValue({ property_id: response.data.id });
            // this.completeLevel = response.data.complete_level;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  onAddPropertyStep1FormSubmit() {}

  showImagePreview() {
    iniImageMagnify();
  }
}
