<div class="loginsign-wrap">
  <div class="loginform-wrap">
    <div class="loginlogo">
      <img src="assets/img/logo.svg" alt="">
    </div>
    <div class="login-form default-form">
      <div class="form-inner">
        <h3>Forgot password</h3>
        <form
          class="pt-3"
          [formGroup]="forgotPasswordForm"
          (ngSubmit)="doforgotPassword()"
        >
          <div class="form-group">
            <label>Email</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Email"
              formControlName="email"
            />
          </div>

          <div class="form-group mt-4">
            <button class="loginbtn" type="submit">Submit</button>
          </div>
        </form>

        <div class="bottom-box">
          <div class="text">
            Back to
            <a [routerLink]="['/user/login']" class="call-modal signup"
              >Login</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
