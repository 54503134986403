import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view-property-step6',
  templateUrl: './view-property-step6.component.html',
  styleUrls: ['./view-property-step6.component.css'],
})
export class ViewPropertyStep6Component implements OnInit {
  uniqueId: string;
  recordId: string;
  dealTypeId: number;
  public user;
  totalHoldingMonths: string;
  public details;
  public currencySymbol = '$';
  public numberOfDoors = 0;
  public financingField = 'No';
  loanData: any;
  otherDetails: {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
    this.getSingleRecordOtherDetails(this.uniqueId);
    this.getAllLoansRecordsByUniqueId();
  }

  getAllLoansRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllLoansRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.loanData = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  getSingleRecordOtherDetails(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordOtherDetailsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.otherDetails = response.data;
            this.totalHoldingMonths = response.data.total_holding_months;
            stopLoader();
          } else {
            checkTosterResponse(response.message, 'Error');
            stopLoader();
          }
        },
        (error) => {
          //stopLoader();
        }
      );
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.dealTypeId = response.data.deal_type_id;
            this.recordId = response.data.id;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  redirectToNextStep() {
    this.router.navigate(['/properties/add-property-step7/' + this.uniqueId]);
  }
}
