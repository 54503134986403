<div class="dashboard_content Properties_AddDashFix">
    <div class="dash-head">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="page-titles">Properties /<span> View Property</span></h1>
            </div>
            <div class="col-auto">
                <div class="left-head">
                    <a [routerLink]="['/properties/add-property-step4', uniqueId]" class="btn-style1"><i
                            class="far fa-pencil me-2"></i> Edit</a>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-container addStep_From">
        <div class="container">
            <app-propertybar [currentSelectedStage]="4" [stageType]="1"></app-propertybar>
            <div class="">
                <form method="post" id="email-form" [formGroup]="addPropertyStep4Form"
                    (ngSubmit)="onAddPropertyStep4FormSubmit()">
                    <div class="stepcard">
                        <div class="stepform-style1">
                            <div class="form-group d-none d-lg-flex">
                                <label></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="incomehead">
                                                Monthly
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="incomehead">
                                                Yearly
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Gross Rents <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt"  type="text" class="view_proInputNum"
                                                    formControlName="gross_rent_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'gross_rent_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="gross_rent_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Laundry</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum " type="number"
                                                    formControlName="laundry_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'laundry_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum" type="text"
                                                    formControlName="laundry_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Parking</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum " type="number"
                                                    formControlName="parking_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'parking_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum " type="text"
                                                    formControlName="parking_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Storage</label>
                                <div class="flexwrap-box">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" class="view_proInputNum " type="number"
                                                    formControlName="storage_monthly"
                                                    (keyup)="updateMonthlyToYearlyAmount($event.target.value, 'storage_monthly')">
                                                </wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="position-relative proInputNum_bx">
                                                <wj-input-number readonly disabled="" [isRequired]="false"
                                                    [format]="fmt" disabled class="view_proInputNum " type="text"
                                                    formControlName="storage_yearly"></wj-input-number>
                                                <span class="il-sign">$</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group Nofield">
                                <label for="">Additional Income</label>
                                <div class="flexwrap-box repeatedCreateDesign">
                                    <div class="form-group d-none d-lg-flex">
                                        <div class="w-100">
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Name
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Amount Monthly
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="incomehead">
                                                        Item Amount Yearly
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div formArrayName="incomeItems" class="repeatedCreate"
                                        *ngFor="let property of addPropertyStep4Form.get('incomeItems')['controls']; let i = index;">
                                        <div [formGroupName]="i">
                                            <div class="mb-3 position-relative mt-4 mt-lg-0 flotingsquare">
                                                <div class="row">
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Name</div>
                                                        <div class="position-relative">
                                                            <input readonly placeholder="Enter Item Name" value=""
                                                                class="form-control1" type="text"
                                                                formControlName="item_name">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative">
                                                            <wj-input-number readonly disabled="" [isRequired]="false"
                                                                [format]="fmt" placeholder="Enter Item Amount Monthly"
                                                                value="" class="view_proInputNum" type="number"
                                                                formControlName="item_amount_monthly"
                                                                (keyup)="calculateYearlyAmount($event.target.value, i)">
                                                            </wj-input-number>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative">
                                                            <wj-input-number readonly disabled="" [isRequired]="false"
                                                                [format]="fmt" placeholder="Enter Item Amount Yearly"
                                                                value="" class="view_proInputNum" type="number"
                                                                formControlName="item_amount_yearly"></wj-input-number>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="amtblock">
                            <label>Total Income</label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="ttamt">${{totalIncomeMonthlyAmount | number : '1.2-2'}} </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="ttamt">${{totalIncomeYearlyAmount | number : '1.2-2'}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>