import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {UserService} from '../../_services/user.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {

  public signupForm: FormGroup;

  constructor(
    private _UserService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { 
    
  }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public doSignup() {  
    let data = this.signupForm.value;
    if(this.signupForm.value.first_name==''){
      checkTosterResponse("First name is required","Error");
      return false;
    }
    if(this.signupForm.value.last_name==''){
      checkTosterResponse("Last name is required","Error");
      return false;
    }
    if(this.signupForm.value.email==''){
      checkTosterResponse("Email is required","Error");
      return false;
    }
    if(this.signupForm.value.password==''){
      checkTosterResponse("Password is required","Error");
      return false;
    }
    startLoader();
    this._UserService
      .signup(data)
      .subscribe(
        (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message,"Success");
            this.router.navigate(['/user/login']);
          } else {
            checkTosterResponse(response.message,"Error");
            return false;
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }, () => stopLoader()
      );
  }

}
