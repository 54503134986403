<div class="sharedheader">
  <a class="navbar-brand" href="https://www.doorhound.io">
    <img src="assets/img/shared-logo.svg" alt="" />
  </a>
  <a href="https://www.doorhound.io">Try It For Free!</a>
</div>
<div class="dashboard-container" *ngIf="!showReport">
  <div class="container">
    <div class="theme-card themeTabs profile-card">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="editprofile-tab"
            data-bs-toggle="tab"
            data-bs-target="#editprofile"
            type="button"
            role="tab"
            aria-controls="editprofile"
            aria-selected="false"
          >
            View Deal Summary
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <p>Please enter your information to view the deal summary.</p>
        <div
          class="tab-pane fade show active"
          id="editprofile"
          role="tabpanel"
          aria-labelledby="editprofile-tab"
        >
          <form
            class="forms-sample"
            [formGroup]="quoteForm"
            (ngSubmit)="doSubmit()"
          >
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                  />
                  <div *ngIf="submitted && f.name.errors" class="help-inline">
                    <div *ngIf="f.name.errors.required">Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  />
                  <div *ngIf="submitted && f.email.errors" class="help-inline">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.pattern">
                      Please provide a valid email address
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="phone_number"
                    [ngClass]="{
                      'is-invalid': submitted && f.phone_number.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && f.phone_number.errors"
                    class="help-inline"
                  >
                    <div *ngIf="f.phone_number.errors.required">
                      Phone number is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-end">
                <a
                  style="margin-right: 20px"
                  class="btnstyle-1"
                  href="https://www.doorhound.io"
                  >Cancel</a
                >
                <button type="submit" class="btnstyle-1">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dashboard_content_wrap" *ngIf="showReport">
  <div class="dashboard_content">
    <div class="dashboard-container mt-0">
      <div class="container">
        <div class="profile-detail backg-img padding-bottom">
          <div class="">
            <div class="profile-block">
              <div class="row align-items-center g-4 g-lg-5">
                <div class="col-md-4 col-lg-3">
                  <div
                    class="img-wall"
                    style="background-image: url({{
                      environment.apiUrl +
                        '/uploads/users/' +
                        user.company_image
                    }});"
                  >
                    <img
                      src="{{
                        environment.apiUrl +
                          '/uploads/users/' +
                          user.company_image
                      }}"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div class="col pl-3">
                  <div class="profile-info">
                    <div class="">
                      <div class="heading d-flex pb-2 pb-md-0">
                        <h4>Deal Summary Report</h4>
                      </div>
                      <div class="">
                        <div class="text_area">
                          {{ details?.property_name }} -
                          {{ details?.deal_type_name }}
                        </div>
                        <p>
                          {{ details?.street_address }} {{ details?.city }}
                          {{ details?.state }} {{ details?.country }}
                        </p>
                        <span class="blue_hightlightText">
                          $
                          {{
                            details?.purchase_price | number : "1.0-0"
                          }}
                          Purchase Price</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carBody mt-3 mt-md-4">
          <div class="col-md-12 stephead-style1">
            <span>Property Description</span>
          </div>
          <div class="stepform-style1">
            <div class="stepform-body">
              <ul class="list-unstyled mb-0 summaryList removebg rowul">
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Property Name:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.property_name }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Number of Doors:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{
                        details?.number_of_doors | number : "1.0-0"
                      }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Address:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.street_address }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">City:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.city }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">State:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.state }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Country:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.country }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="carBody mt-3 mt-md-5">
          <div class="col-md-12 stephead-style1">
            <span>Building Details</span>
          </div>
          <div class="stepform-style1">
            <div class="stepform-body">
              <ul class="list-unstyled mb-0 summaryList removebg rowul">
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Style:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.building_style }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Foundation:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{
                        details?.building_foundation
                      }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Exterior finish:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.exterior_finish }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Heat fuel:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.heat_fuel }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="carBody mt-3 mt-md-5">
          <div class="col-md-12 stephead-style1">
            <span>Property Features</span>
          </div>
          <div class="stepform-style1">
            <div class="stepform-body">
              <ul class="list-unstyled mb-0 summaryList removebg rowul">
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Year Built:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.year_built }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Square Footage:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.square_footage }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Ownership Type:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.ownership_type }}</span>
                    </div>
                  </div>
                </li>
                <!-- <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Property Type:
                    </div>
                    <div class="summaryamt">
                      <span class="su-amt">{{details?.ownership_type}}</span>
                    </div>
                  </div>
                </li> -->
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Lot Size:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.lot_size }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="summaryList-databox">
                    <div class="summaryHead">Zoning:</div>
                    <div class="summaryamt">
                      <span class="su-amt">{{ details?.zoning }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="carBody mt-3 mt-md-5">
          <div class="col-md-12 stephead-style1">
            <span>PROPERTY LOCATION</span>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
            width="100%"
            height="400"
            frameborder="0"
            style="border: 0"
          ></iframe>
        </div>
        <div class="mt-3 mt-md-5">
          <table cellpadding="0" cellspacing="0" width="100%">
            <tbody>
              <tr>
                <td style="padding: 0 20px; padding-bottom: 40px">
                  <table cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                      <tr>
                        <td valign="top" style="padding: 8px; width: 50%">
                          <table
                            width="100%"
                            style="
                              border: 1px solid #555555;
                              margin-bottom: 25px;
                            "
                            cellpadding="0"
                            cellspacing="0"
                          >
                            <thead>
                              <tr>
                                <th
                                  colspan="2"
                                  style="
                                    text-align: left;
                                    font-weight: 500;
                                    color: #ffffff;
                                    padding: 5px 8px;
                                    background-color: #f76342;
                                  "
                                >
                                  Purchase Information
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Total Number of Units
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  {{
                                    details?.number_of_doors | number : "1.0-0"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Purchase Price
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  $
                                  {{
                                    details?.purchase_price | number : "1.0-0"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Initial Cash Invested
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  $
                                  {{
                                    details?.total_investment | number : "1.0-0"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            width="100%"
                            style="
                              margin-bottom: 25px;
                              border: 1px solid #555555;
                            "
                            cellpadding="0"
                            cellspacing="0"
                          >
                            <thead>
                              <tr>
                                <th
                                  style="
                                    text-align: left;
                                    font-weight: 500;
                                    color: #ffffff;
                                    padding: 5px 8px;
                                    background-color: #f76342;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Income Analysis
                                </th>
                                <th
                                  style="
                                    text-align: left;
                                    font-weight: 500;
                                    color: #ffffff;
                                    padding: 5px 8px;
                                    background-color: #f76342;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Monthly
                                </th>
                                <th
                                  style="
                                    text-align: left;
                                    font-weight: 500;
                                    color: #ffffff;
                                    padding: 5px 8px;
                                    background-color: #f76342;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Annual
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Net Operating
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  $
                                  {{
                                    otherDetails?.net_operating_income_monthly
                                      | number : "1.0-0"
                                  }}
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  $
                                  {{
                                    otherDetails?.net_operating_income_yearly
                                      | number : "1.0-0"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Cash Flow
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  $
                                  {{
                                    otherDetails?.cash_flow_monthly
                                      | number : "1.0-0"
                                  }}
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  $
                                  {{
                                    otherDetails?.cash_flow_yearly
                                      | number : "1.0-0"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            width="100%"
                            border="1px solid #555555"
                            style="margin-bottom: 25px"
                            cellpadding="0"
                            cellspacing="0"
                          >
                            <thead>
                              <tr>
                                <th
                                  colspan="2"
                                  style="
                                    text-align: left;
                                    font-weight: 500;
                                    color: #ffffff;
                                    padding: 5px 8px;
                                    background-color: #f76342;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Financial Metrics
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Cap Rate (Purchase Price)
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  {{
                                    otherDetails?.cap_rate_purchase_price
                                      | number : "1.0-0"
                                  }}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Cash on Cash Return (Year 1)
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  {{
                                    otherDetails?.cash_on_cash_return
                                      | number : "1.0-0"
                                  }}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  Sale Price (Year 25)
                                </td>
                                <td
                                  style="
                                    padding: 5px 8px;
                                    text-align: right;
                                    font-size: 13px;
                                    border: 1px solid #555555;
                                  "
                                >
                                  ${{ details?.sale_price | number : "1.0-0" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3 mt-md-5">
          <h3>Property Report</h3>
          <div class="carBody">
            <div class="stephead-style1">
              <span>Purchase Information</span>
            </div>
            <div class="stepform-style1">
              <div class="row stepform-body">
                <div class="col-md-6">
                  <ul
                    class="list-unstyled mb-0 summaryList left-infoi removebg"
                  >
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Purchase Price:</div>
                        <div class="summaryamt">
                          <span class="su-amt"
                            >${{
                              details?.purchase_price | number : "1.0-0"
                            }}</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Amount Financed:</div>
                        <div class="summaryamt">
                          <span class="susign">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="15"
                              height="15"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M5 11h14v2H5z" />
                            </svg>
                          </span>
                          <span
                            class="su-amt"
                            *ngIf="details?.financing_required == 'Yes'"
                            >${{
                              details?.total_loan_amount | number : "1.0-0"
                            }}</span
                          >
                          <span
                            class="su-amt"
                            *ngIf="details?.financing_required == 'No'"
                            >${{
                              details?.total_funds_available | number : "1.0-0"
                            }}</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox litopborder">
                        <div class="summaryHead themecolor">Down Payment:</div>
                        <div class="summaryamt">
                          <span class="susign themecolor">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              width="15"
                              height="15"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M19 10H5V8h14v2m0 6H5v-2h14v2Z"
                              />
                            </svg>
                          </span>
                          <span class="su-amt themecolor"
                            >${{
                              details?.downpayment | number : "1.0-0"
                            }}</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Purchase Costs:</div>
                        <div class="summaryamt">
                          <span class="susign">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="15"
                              height="15"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                            </svg>
                          </span>
                          <span class="su-amt"
                            >${{
                              details?.total_purchase_costs | number : "1.0-0"
                            }}</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Rehab Costs:</div>
                        <div class="summaryamt">
                          <span class="susign">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="15"
                              height="15"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                            </svg>
                          </span>
                          <span class="su-amt"
                            >${{
                              details?.total_repair_renovation
                                | number : "1.0-0"
                            }}</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox litopborder">
                        <div class="summaryHead themecolor">
                          Total Cash Needed:
                          <i
                            class="fal fa-question-circle"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover focus"
                            title="Purchase Price"
                            data-bs-content="The amount you're paying to purchase a property."
                          ></i>
                        </div>

                        <div class="summaryamt">
                          <span class="susign themecolor">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              width="15"
                              height="15"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M19 10H5V8h14v2m0 6H5v-2h14v2Z"
                              />
                            </svg>
                          </span>
                          <span class="su-amt themecolor"
                            >${{ totalCashNeeded | number : "1.0-0" }}</span
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="min-h-auto stepcard mt-3 mt-md-5">
          <div class="row">
            <div class="stephead-style1">
              <span>Financing (Purchase)</span>
            </div>
            <div
              class="col-md-6 pl-0"
              *ngFor="let singleRecord of loanData; let i = index"
            >
              <div class="stepform-style1">
                <div class="stepform-body">
                  <ul
                    class="list-unstyled mb-0 summaryList left-infoi removebg"
                  >
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Loan Name</div>
                        <div class="summaryamt">
                          <span class="su-amt">{{
                            singleRecord?.loan_name
                          }}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Loan Type</div>
                        <div class="summaryamt">
                          <span class="su-amt">{{
                            singleRecord?.loan_type
                          }}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Loan Amount</div>
                        <div class="summaryamt">
                          <span class="su-amt"
                            >${{
                              singleRecord?.loan_amount | number : "1.0-0"
                            }}</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Loan Term</div>
                        <div class="summaryamt">
                          <span class="su-amt"
                            >{{ singleRecord?.terms_in_years }} Years</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Interest Rate</div>
                        <div class="summaryamt">
                          <span class="su-amt"
                            >{{ singleRecord?.interest_rate }}%</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="summaryList-databox">
                        <div class="summaryHead">Loan Payments</div>
                        <div class="summaryamt">
                          <span class="su-amt"
                            >${{
                              singleRecord?.emi_monthly | number : "1.0-0"
                            }}
                            Per Month</span
                          >
                          <span class="su-amt"
                            >${{
                              singleRecord?.emi_yearly | number : "1.0-0"
                            }}
                            Per Year</span
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carBody mt-3 mt-md-5">
          <div class="stephead-style1">
            <span>Projections</span>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="carBody">
                <div class="shr-text">APPRECIATION</div>
                <div class="shr-dtl">
                  {{ details?.estimated_appreciation }}% Per Year
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="carBody">
                <div class="shr-text">INCOME INCREASE</div>
                <div class="shr-dtl">
                  {{ details?.estimated_income_increase }}% Per Year
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="carBody">
                <div class="shr-text">EXPENSES INCREASES</div>
                <div class="shr-dtl">
                  {{ details?.estimated_expenses_increase }}% Per Year
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="carBody">
                <div class="shr-text">SELLING COSTS</div>
                <div class="shr-dtl">
                  {{ details?.expected_selling_costs }}% Per Year
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Year 1</th>
                  <th scope="col">Year 5</th>
                  <th scope="col">Year 10</th>
                  <th scope="col">Year 20</th>
                  <th scope="col">Year 30</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" class="Tbg_none">INCOME</th>
                </tr>
                <tr
                  *ngFor="let singleRecord of incomeProjections; let i = index"
                >
                  <th scope="row">
                    {{ singleRecord?.name }}
                  </th>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[0] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[4] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[9] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[19] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[29] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                </tr>
                <tr class="topthemeB themecolor">
                  <th scope="row">
                    <div class="summaryHead left-infoi themecolor">
                      Total Income:
                    </div>
                  </th>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalIncomesItems[0] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalIncomesItems[4] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalIncomesItems[9] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalIncomesItems[19] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalIncomesItems[29] | number : "1.0-0" }}</span
                    >
                  </td>
                </tr>
                <tr class="topthemeB themecolor">
                  <th scope="row">
                    <div class="summaryHead left-infoi themecolor">
                      Income Increase:
                    </div>
                  </th>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_income_increase }}%</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_income_increase }}%</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_income_increase }}%</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_income_increase }}%</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_income_increase }}%</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Year 1</th>
                  <th scope="col">Year 5</th>
                  <th scope="col">Year 10</th>
                  <th scope="col">Year 20</th>
                  <th scope="col">Year 30</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" class="Tbg_none">EXPENSES</th>
                </tr>
                <tr
                  *ngFor="
                    let singleRecord of expensesProjections;
                    let i = index
                  "
                >
                  <th scope="row">
                    {{ singleRecord?.name }}
                  </th>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[0] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[4] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[9] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[19] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$
                      {{
                        singleRecord?.calculation[29] | number : "1.0-0"
                      }}</span
                    >
                  </td>
                </tr>
                <tr class="topthemeB themecolor">
                  <th scope="row">
                    <div class="summaryHead left-infoi themecolor">
                      Total Expenses:
                    </div>
                  </th>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalExpensesItems[0] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalExpensesItems[4] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalExpensesItems[9] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalExpensesItems[19] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt"
                      >$ {{ totalExpensesItems[29] | number : "1.0-0" }}</span
                    >
                  </td>
                </tr>
                <tr class="topthemeB themecolor">
                  <th scope="row">
                    <div class="summaryHead left-infoi themecolor">
                      Expense Increase:
                    </div>
                  </th>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_expenses_increase }}%</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_expenses_increase }}%</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_expenses_increase }}%</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_expenses_increase }}%</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >{{ details?.estimated_expenses_increase }}%</span
                    >
                  </td>
                </tr>
                <tr class="topthemeB themecolor">
                  <th scope="row">
                    <div class="summaryHead left-infoi themecolor">
                      Operating Income:
                    </div>
                  </th>
                  <td>
                    <span class="su-amt themecolor"
                      >$ {{ operatingIncomes[0] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >$ {{ operatingIncomes[4] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >$ {{ operatingIncomes[9] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >$ {{ operatingIncomes[19] | number : "1.0-0" }}</span
                    >
                  </td>
                  <td>
                    <span class="su-amt themecolor"
                      >$ {{ operatingIncomes[29] | number : "1.0-0" }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="carBody mt-3 mt-md-5" *ngIf="imagesData">
          <div class="col-md-12 stephead-style1">
            <span>Property Photos</span>
          </div>
          <div class="upload-prop-wall">
            <ul class="list-unstyled">
              <li *ngFor="let singleRecord of imagesData; let i = index">
                <figure>
                  <a
                    (click)="showImagePreview()"
                    class="fancybox-buttons btn-gallery"
                    data-fancybox-group="button"
                    href="javascript:void(0);"
                    ><img
                      src="{{
                        environment.apiUrl +
                          '/uploads/propertiesimages/' +
                          singleRecord.image_name
                      }}"
                      alt=""
                  /></a>
                </figure>
                <div id="gallery-1" class="hidden">
                  <a
                    *ngFor="let singleRecord of imagesData; let i = index"
                    href="{{
                      environment.apiUrl +
                        '/uploads/propertiesimages/' +
                        singleRecord.image_name
                    }}"
                  ></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="deal_summary">
          Deal Summary Report built with DoorHound,
          <a href="javascript:void(0);" href="https://www.doorhound.io"
            >Try it for free!</a
          >
        </div>
        <button
          type="button"
          class="btn btn-primary me-2"
          (click)="downloadReportPdf()"
        >
          Download PDF
        </button>
      </div>
    </div>
  </div>
</div>
