import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { PropertiesService } from '../../../_services/properties.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-add-property-step2',
  templateUrl: './add-property-step2.component.html',
  styleUrls: ['./add-property-step2.component.css'],
})
export class AddPropertyStep2Component implements OnInit {
  uniqueId: string;
  recordId: string;
  public user;
  addPropertyStep2Form: FormGroup;
  details: {};
  public currencySymbol = '$';
  public numberOfDoors = 0;
  public totalLoanAmount = 0;
  public purchase_price: number = 0;
  public financingField = 'No';
  productData: any;
  doorPrice: any;
  propertyarr: any = [];
  properties: FormArray;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _PropertiesService: PropertiesService,
    private _AuthService: AuthService
  ) {
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this.getSingleRecord(this.uniqueId);
    this.getAllLoansRecordsByUniqueId();

    this.addPropertyStep2Form = this.formBuilder.group({
      currency: ['', Validators.required],
      sale_price: ['', Validators.required],
      purchase_price: ['', Validators.required],
      after_repair_value: ['', Validators.required],
      door_price: ['', Validators.required],
      financing_required: ['', Validators.required],
      currency_symbol: ['', Validators.required],
      total_loan_amount: ['0', Validators.required],
      total_funds_available: ['', Validators.required],
      propertiesData: ['', Validators.required],
      unique_id: ['', Validators.required],
      user_id: [this.user.id, Validators.required],
      properties: this.formBuilder.array([]),
    });
  }

  getAllLoansRecordsByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllLoansRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.productData = response;
            let propertyarr = [];
            for (let i = 0; i <= this.productData.data.length; i++) {
              propertyarr.push(this.productData.data[i]);
            }
            let control = <FormArray>(
              this.addPropertyStep2Form.controls['properties']
            );
            if (this.productData.data.length > 0) {
              for (let i = 0; i < this.productData.data.length; i++) {
                let form = this.formBuilder.group({
                  loan_name: [this.productData.data[i].loan_name],
                  loan_type: [this.productData.data[i].loan_type],
                  loan_amount_percentage: [
                    this.productData.data[i].loan_amount_percentage,
                  ],
                  loan_amount: [this.productData.data[i].loan_amount],
                  terms_in_years: [this.productData.data[i].terms_in_years],
                  terms_in_months: [this.productData.data[i].terms_in_months],
                  interest_rate: [this.productData.data[i].interest_rate],
                });
                control.push(form);
              }
            }
            this.properties = control;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  calculateTotalLoanAmount(amount) {
    if (this.financingField == 'Yes') {
      let totalAmount = 0;
      var propertiesData = this.addPropertyStep2Form.value.properties;
      for (const singleRecord of propertiesData) {
        totalAmount = totalAmount + singleRecord.loan_amount;
      }
      this.addPropertyStep2Form.patchValue({ total_loan_amount: totalAmount });
      this.totalLoanAmount = totalAmount;
    }
  }

  calculateTotalLoanPercent(amount, index) {
    var self = this;
    let propertiesData = this.addPropertyStep2Form.value.properties;
    var loan_amount = propertiesData[index]['loan_amount'].toString();
    var loanAmount2 = loan_amount.replaceAll(',', '');
    let loan_type = propertiesData[index]['loan_type'];
    let purchase_price = this.purchase_price;
    if (loan_type == 'Interest Only') {
      setTimeout(function () {
        var loan_amount_percentage = (loanAmount2 / purchase_price) * 100;
        propertiesData[index]['loan_amount_percentage'] =
          loan_amount_percentage;
        self.addPropertyStep2Form.patchValue({ properties: propertiesData });
        self.calculateTotalLoanAmount(0);
      }, 2000);
    }
  }

  calculateLoanAmount(amount, index) {
    var self = this;
    let propertiesData = this.addPropertyStep2Form.value.properties;
    let loan_amount_percentage = parseInt(
      propertiesData[index]['loan_amount_percentage']
    );
    let loan_type = propertiesData[index]['loan_type'];
    let purchase_price = this.purchase_price;
    if (loan_type == 'Amortized') {
      setTimeout(function () {
        propertiesData[index]['loan_amount'] =
          (loan_amount_percentage * purchase_price) / 100;
        console.log(propertiesData);
        console.log(propertiesData[index]['loan_amount_percentage']);
        self.addPropertyStep2Form.patchValue({ properties: propertiesData });
        self.calculateTotalLoanAmount(0);
      }, 2000);
    }
  }

  calculateMonths(years, index) {
    var self = this;
    if (this.financingField == 'Yes') {
      console.log(index);
      let propertiesData = this.addPropertyStep2Form.value.properties;
      console.log('propertiesData', propertiesData);
      setTimeout(function () {
        propertiesData[index]['terms_in_months'] =
          propertiesData[index]['terms_in_years'] * 12;
        console.log(propertiesData);
        console.log(propertiesData[index]['terms_in_years']);
        self.addPropertyStep2Form.patchValue({ properties: propertiesData });
      }, 2000);
    }
  }

  addProperty(): void {
    this.properties = this.addPropertyStep2Form.get('properties') as FormArray;
    this.properties.push(this.createProperty());
  }

  createProperty(): FormGroup {
    return this.formBuilder.group({
      loan_name: '',
      loan_amount: '',
      terms_in_years: '',
      interest_rate: '',
      terms_in_months: '',
      loan_amount_percentage: '',
      loan_type: '',
    });
  }

  removeProperty(rowIndex: number) {
    this.properties.removeAt(rowIndex);
    this.calculateTotalLoanAmount(0);
  }

  updateFinancingFields(event) {
    this.financingField = event;
  }

  updateCurrencySymbol(event) {
    this.addPropertyStep2Form.patchValue({ currency_symbol: '$' });
    this.currencySymbol = '$';
    console.log(event);
  }

  calculateDoorPrice(purchase_price) {
    this.purchase_price = purchase_price.replaceAll(',', '');
    this.doorPrice = this.purchase_price / this.numberOfDoors;
    this.addPropertyStep2Form.patchValue({ door_price: this.doorPrice });
    let propertiesData = this.addPropertyStep2Form.value.properties;
    let index = 0;
    for(const singleRecord of propertiesData) {
      if(singleRecord.loan_type == 'Interest Only') {
        this.calculateTotalLoanPercent("", index);
      } else if(singleRecord.loan_type == 'Amortized') {
        this.calculateLoanAmount("",index);
      }
      console.log("index", index);
      index++;
      console.log("singleRecord", singleRecord);
    }
  }

  getSingleRecord(uniqueId) {
    startLoader();
    this._PropertiesService
      .singleRecordByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            console.log(response.data);
            this.details = response.data;
            this.recordId = response.data.id;
            this.addPropertyStep2Form.patchValue(response.data);
            this.numberOfDoors = response.data.number_of_doors;
            this.purchase_price = response.data.purchase_price;
            this.totalLoanAmount = response.data.total_loan_amount;
            this.financingField = response.data.financing_required;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

  clickToBack() {
    this.onaddPropertyStep2FormSubmit('back');
  }

  onaddPropertyStep2FormSubmit(type: any = "") {
    if (
      this.addPropertyStep2Form.value.currency == '' ||
      this.addPropertyStep2Form.value.currency == 'null'
    ) {
      checkTosterResponse('Please enter Currency.', 'Error');
      return false;
    }
    if (
      this.addPropertyStep2Form.value.sale_price == '' ||
      this.addPropertyStep2Form.value.sale_price == 'null'
    ) {
      checkTosterResponse('Please enter Sale Price.', 'Error');
      return false;
    }
    if (
      this.addPropertyStep2Form.value.purchase_price == '' ||
      this.addPropertyStep2Form.value.purchase_price == 'null'
    ) {
      checkTosterResponse('Please enter Purchase Price.', 'Error');
      return false;
    }
    if (
      this.addPropertyStep2Form.value.after_repair_value == '' ||
      this.addPropertyStep2Form.value.after_repair_value == 'null'
    ) {
      checkTosterResponse('Please enter After Repair Value.', 'Error');
      return false;
    }
    if (
      this.addPropertyStep2Form.value.door_price == '' ||
      this.addPropertyStep2Form.value.door_price == 'null'
    ) {
      checkTosterResponse('Please enter Door Price.', 'Error');
      return false;
    }
    if (
      this.addPropertyStep2Form.value.financing_required == '' ||
      this.addPropertyStep2Form.value.financing_required == 'null'
    ) {
      checkTosterResponse('Please enter Financing Required.', 'Error');
      return false;
    } else {
      if (this.addPropertyStep2Form.value.financing_required == 'Yes') {
        var propertiesData = this.addPropertyStep2Form.value.properties;
        if (propertiesData.length < 1) {
          checkTosterResponse('Please enter loan values.', 'Error');
          return false;
        } else {
          for (const singleRecord of propertiesData) {
            if (singleRecord.loan_name == '') {
              checkTosterResponse('Please enter loan name.', 'Error');
              return false;
            }
            if (singleRecord.loan_type == '') {
              checkTosterResponse('Please enter loan type.', 'Error');
              return false;
            }
            if (singleRecord.loan_amount == '') {
              checkTosterResponse('Please enter loan amount.', 'Error');
              return false;
            }
            if (singleRecord.loan_type == 'Amortized') {
              if (singleRecord.loan_amount_percentage == '') {
                checkTosterResponse(
                  'Please enter loan amount percentage.',
                  'Error'
                );
                return false;
              }
              if (singleRecord.loan_amount_percentage > 100) {
                checkTosterResponse(
                  'Please enter loan amount percentage less than or equal of 100.',
                  'Error'
                );
                return false;
              }
            } else {
              if (singleRecord.loan_amount == '') {
                checkTosterResponse('Please enter loan amount.', 'Error');
                return false;
              }
            }
            if (singleRecord.terms_in_years == '') {
              checkTosterResponse('Please enter terms in years.', 'Error');
              return false;
            }
            if (singleRecord.interest_rate == '') {
              checkTosterResponse('Please enter interest rate.', 'Error');
              return false;
            }
            if (singleRecord.interest_rate > 100) {
              checkTosterResponse(
                'Please enter interest rate less than or equal of 100.',
                'Error'
              );
              return false;
            }
          }
        }
        if (this.addPropertyStep2Form.value.total_loan_amount == '') {
          checkTosterResponse('Please enter total loan amount.', 'Error');
          return false;
        }
      } else {
        if (this.addPropertyStep2Form.value.total_funds_available == '') {
          checkTosterResponse('Please enter total funds available.', 'Error');
          return false;
        }
      }
    }
    if (this.addPropertyStep2Form.value.financing_required == 'Yes') {
      if (
        this.purchase_price < this.addPropertyStep2Form.value.total_loan_amount
      ) {
        checkTosterResponse(
          'Please enter total loan amount less than purchase price .',
          'Error'
        );
        return false;
      }
    } else {
      console.log("???");
      if (
        this.purchase_price <
        this.addPropertyStep2Form.value.total_funds_available
      ) {
        checkTosterResponse(
          'Please enter total funds available less than purchase price .',
          'Error'
        );
        return false;
      }
    }
    console.log("this.purchase_price", typeof this.purchase_price, this.purchase_price);
    console.log("this.total_funds_available", typeof this.addPropertyStep2Form.value.total_funds_available, this.addPropertyStep2Form.value.total_funds_available);
    startLoader();
    var propertiesData = this.addPropertyStep2Form.value.properties;
    this.addPropertyStep2Form.patchValue({
      propertiesData: JSON.stringify(propertiesData),
    });
    this._PropertiesService
      .updateRecordStepFinancing(this.recordId, this.addPropertyStep2Form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            if(type == "back") {
              this.router.navigate([
                '/properties/add-property-step1/' + this.uniqueId,
              ]);
            } else {
              checkTosterResponse(response.message, 'Success');
              this.router.navigate([
                '/properties/add-property-step3/' + this.uniqueId,
              ]);
            }
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        error: (error) => {
          stopLoader();
        },
      });
  }

  removeLoan(rowIndex: number) {
    console.log(this.properties);
    if (rowIndex > 0) {
      this.properties.removeAt(rowIndex);
    }
  }
}
