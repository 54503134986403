<div class="dashboard_content">
    <div class="dash-head">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="page-titles">Properties /<span> Add Property</span></h1>
            </div>
            <div class="col-auto">
                <div class="left-head">
                    <a href="#!" class="btn-style1"><i class="far fa-arrow-left me-2"></i> Back</a>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-container">
        <div class="container">
            <div class="customtabs_btns position-relative">
                <ul class="list-unstyled mb-10">
                    <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">1</span>
                            Property
                        </a>
                    </li>
                    <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">2</span>
                            Financing
                        </a>
                    </li>
                    <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">3</span>
                            Comparables
                        </a>
                    </li>
                    <li class="active">
                        <a href="javascript:void(0);">
                            <span class="tabcount">4</span>
                            Summary
                        </a>
                    </li>
                </ul>
            </div>
            <div class="stepaccordCard">
                <div class="accordion" id="accordionStep">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"  data-bs-target="#purchaseAccord" aria-expanded="false" aria-controls="purchaseAccord">
                                Wholesale Metrics
                            </button>
                        </h2>
                        <div id="purchaseAccord" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionStep">
                            <div class="accordion-body">
                                <ul class="list-unstyled mb-0 summaryList">
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Wholesale Price
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{details?.wholesale_price}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Acquisition Price
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{details?.acquisition_price}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Wholesale Costs
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{details?.wholesale_costs}}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="summaryList-databox">
                                            <div class="summaryHead">
                                                Wholesale Results
                                            </div>
                                            <div class="summaryamt">
                                                <span class="su-amt">${{details?.wholesale_results}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>