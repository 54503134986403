import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { DashboardComponent } from './_components/_dashboard/dashboard.component';
import { SharedComponent } from './_components/_shared/shared.component';
import { LoginComponent } from './user-pages/login/login.component';
import { EmailVerificationComponent } from './user-pages/email-verification/email-verification.component';
import { SignupComponent } from './user-pages/signup/signup.component';
import { ForgotPasswordComponent } from './user-pages/forgot-password/forgot-password.component';
import { MyProfileComponent } from './_components/_my-profile/my-profile.component';
import { ContactsListComponent } from './_components/_contacts/contacts-list/contacts-list.component';
import { AddContactsComponent } from './_components/_contacts/add-contacts/add-contacts.component';
import { UpdateContactsComponent } from './_components/_contacts/update-contacts/update-contacts.component';
import { ViewContactsComponent } from './_components/_contacts/view-contacts/view-contacts.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ResetPasswordComponent } from './user-pages/reset-password/reset-password.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { UserColumnsComponent } from './_components/_user-columns/user-columns.component';
import { PlansComponent } from './_components/_plans/plans.component';

import { AddPropertyComponent } from './_components/_properties/add-property/add-property.component';
import { PropertyListingComponent } from './_components/_properties/property-listing/property-listing.component';
import { AddPropertyStep1Component } from './_components/_properties/add-property-step1/add-property-step1.component';
import { AddPropertyStep2Component } from './_components/_properties/add-property-step2/add-property-step2.component';
import { AddPropertyStep3Component } from './_components/_properties/add-property-step3/add-property-step3.component';
import { AddPropertyStep4Component } from './_components/_properties/add-property-step4/add-property-step4.component';

import { AddPropertyStep5Component } from './_components/_properties/add-property-step5/add-property-step5.component';

import { AddPropertyStep6Component } from './_components/_properties/add-property-step6/add-property-step6.component';
import { AddPropertyStep7Component } from './_components/_properties/add-property-step7/add-property-step7.component';
import { AddPropertyStep8Component } from './_components/_properties/add-property-step8/add-property-step8.component';

import { WholesalePropertyStep1Component } from './_components/_wholesale/wholesale-property-step1/wholesale-property-step1.component';
import { WholesalePropertyStep2Component } from './_components/_wholesale/wholesale-property-step2/wholesale-property-step2.component';
import { WholesalePropertyStep3Component } from './_components/_wholesale/wholesale-property-step3/wholesale-property-step3.component';
import { WholesalePropertyStep4Component } from './_components/_wholesale/wholesale-property-step4/wholesale-property-step4.component';
import { PaymentSuccessComponent } from './_components/_payment-success/payment-success.component';
import { PaymentCancelComponent } from './_components/_payment-cancel/payment-cancel.component';
import { MembershipsComponent } from './_components/_memberships/memberships.component';
import { ViewPropertyStep1Component } from './_components/_view-properties/view-property-step1/view-property-step1.component';
import { ViewPropertyStep2Component } from './_components/_view-properties/view-property-step2/view-property-step2.component';
import { ViewPropertyStep3Component } from './_components/_view-properties/view-property-step3/view-property-step3.component';
import { ViewPropertyStep4Component } from './_components/_view-properties/view-property-step4/view-property-step4.component';
import { ViewPropertyStep5Component } from './_components/_view-properties/view-property-step5/view-property-step5.component';
import { ViewPropertyStep6Component } from './_components/_view-properties/view-property-step6/view-property-step6.component';
import { ViewPropertyStep7Component } from './_components/_view-properties/view-property-step7/view-property-step7.component';
import { ViewPropertyStep8Component } from './_components/_view-properties/view-property-step8/view-property-step8.component';
import { DatePipe } from '@angular/common';
import { PropertybarComponent } from './shared/propertybar/propertybar.component';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { MakePositivePipe } from './_pipes/make-positive.pipe';

@NgModule({
  declarations: [
    AppComponent,
    MakePositivePipe,
    ViewPropertyStep1Component,
    ViewPropertyStep2Component,
    ViewPropertyStep3Component,
    ViewPropertyStep4Component,
    ViewPropertyStep5Component,
    ViewPropertyStep6Component,
    ViewPropertyStep7Component,
    ViewPropertyStep8Component,
    PaymentSuccessComponent,
    MembershipsComponent,
    PaymentCancelComponent,
    SidebarComponent,
    PropertybarComponent,
    FooterComponent,
    PlansComponent,
    EmailVerificationComponent,
    ContentAnimateDirective,
    DashboardComponent,
    ViewContactsComponent,
    UserColumnsComponent,
    LoginComponent,
    SharedComponent,
    AddPropertyStep2Component,
    AddPropertyStep3Component,
    AddPropertyStep4Component,
    AddPropertyStep5Component,
    AddPropertyStep6Component,
    AddPropertyStep7Component,
    AddPropertyStep8Component,
    WholesalePropertyStep3Component,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    WholesalePropertyStep1Component,
    WholesalePropertyStep2Component,
    WholesalePropertyStep4Component,
    SignupComponent,
    MyProfileComponent,
    ContactsListComponent,
    AddContactsComponent,
    UpdateContactsComponent,
    AddPropertyStep1Component,
    AddPropertyComponent,
    PropertyListingComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    WjInputModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    CKEditorModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    AutocompleteLibModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
