import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private _ApiService: ApiService,
    private _AuthService: AuthService
  ) {}

  login(data) {
    return this._ApiService.post('api/users/login', data); //Done
  }

  logout() {
    return this._AuthService.removeAuth(); //Done
  }

  signup(data) {
    return this._ApiService.post('api/users/signup', data); //Done
  }

  getUserProfile(userId) {
    return this._ApiService.get('api/users/singleRecord/' + userId); //Done
  }

  changePassword(data) {
    return this._ApiService.put('api/users/change-password', data); //Done
  }

  updateProfile(data) {
    return this._ApiService.put('api/users/update-profile', data); //Done
  }

  updateCompanyProfile(data) {
    return this._ApiService.put('api/users/update-company-profile', data); //Done
  }

  forgotPassword(data) {
    return this._ApiService.post('api/users/forgot-password', data); //Done
  }

  resetPassword(data) {
    return this._ApiService.post('api/users/reset-password', data); //Done
  }
  
  updateAcquisitionImage(data) {
    return this._ApiService.post('api/users/update-acquisition-image', data); //Done
  }

  checkEmailVerification(verificationCode) {
    return this._ApiService.get(
      'api/users/email-verification/' + verificationCode
    ); //Done
  }
}
