<div class="dashboard_content Properties_AddDashFix">
    <form method="post" id="email-form" [formGroup]="addPropertyStep3Form" (ngSubmit)="onAddPropertyStep3FormSubmit()"
        class="addStep_From">
        <div class="dash-head">
            <div class="row align-items-center">
                <div class="col">
                    <h1 class="page-titles">Properties /<span> Add Property</span></h1>
                </div>
                <div class="col-auto">
                    <div class="left-head">
                        <a (click)="clickToBack()" class="btn-style1 me-2"><i
                                class="far fa-arrow-left me-2"></i> Back</a>
                        <button type="submit" class="btn-style1">Save & Next</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard-container">
            <div class="container">
                <div class="customtabs_btns position-relative">
                    <ul class="list-unstyled mb-10">
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step1', uniqueId]">
                                <span class="tabcount">1</span>
                                Property
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step2', uniqueId]">
                                <span class="tabcount">2</span>
                                Financing
                            </a>
                        </li>
                        <li class="active">
                            <a [routerLink]="['/properties/add-property-step3', uniqueId]">
                                <span class="tabcount">3</span>
                                Investment
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">4</span>
                                Income
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">5</span>
                                Expenses
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">6</span>
                                Payback
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">7</span>
                                Performance
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <span class="tabcount">8</span>
                                Summary
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="stepcard">
                    <div class="stephead-style1">
                        <span>Purchase Costs</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Downpayment <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                        class="form-control1 ilsign-p" type="number" formControlName="downpayment"
                                        id=""></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Land transfer tax <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false"
                                        (keyup)="updateFieldValue($event.target.value, 'land_transfer_tax')"
                                        class="form-control1 ilsign-p" [format]="fmt"
                                        formControlName="land_transfer_tax"></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Legal <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1  ilsign-p"
                                        (keyup)="updateFieldValue($event.target.value, 'legal')" type="number"
                                        formControlName="legal" id=""></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Appraisal <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1  ilsign-p"
                                        (keyup)="updateFieldValue($event.target.value, 'appraisal')" type="number"
                                        formControlName="appraisal" id=""></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Inspection <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1  ilsign-p"
                                        (keyup)="updateFieldValue($event.target.value, 'inspection')" type="number"
                                        formControlName="inspection" id=""></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Reserve <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1 ilsign-p"
                                        (keyup)="updateFieldValue($event.target.value, 'reserve')" type="number"
                                        formControlName="reserve" id=""></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Acquisition <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="input-group">
                                            <input min="0" class="form-control1" type="number" (keyup)="calculateAcquisition($event.target.value)" formControlName="acquisition_percent" id="" onkeypress="return(event.charCode ==189 || event.charCode ==0 || (event.charCode >= 46 && event.charCode <= 57))">
                                            <!-- <wj-input-number [isRequired]="false" format="n2"
                                                class="form-control1" type="number"
                                                formControlName="acquisition_percent" id=""></wj-input-number> -->
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group">
                                            <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                                class="form-control1 ilsign-p" type="number"
                                                formControlName="acquisition" id=""></wj-input-number>
                                            <span class="il-sign">$</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group Nofield">
                            <label for="">Additional Costs</label>
                            <div class="flexwrap-box repeatedCreateDesign">
                                <div class="form-group d-none d-lg-flex">
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="incomehead">
                                                    Item Name
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="incomehead">
                                                    Item Amount
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div formArrayName="purchaseItems" class="repeatedCreate"
                                    *ngFor="let property of addPropertyStep3Form.get('purchaseItems')['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="mb-3 position-relative mt-4 mt-lg-0 flotingsquare">
                                            <div class="row">
                                                <input placeholder="Enter Item Type" value="" class="form-control1"
                                                    type="hidden" formControlName="item_type">
                                                <div class="col-12 col-md-6 mb-2 mb-md-0">
                                                    <div class="incomehead d-lg-none">Item Name</div>
                                                    <div class="position-relative">
                                                        <input placeholder="Enter Item Name" value=""
                                                            class="form-control1" type="text"
                                                            formControlName="item_name">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2 mb-md-0">
                                                    <div class="incomehead d-lg-none">Item Amount</div>
                                                    <div class="position-relative">
                                                        <wj-input-number [isRequired]="false" format="n2"
                                                            placeholder="Enter Item Amount" value=""
                                                            class="view_proInputNum withOut_space" type="number"
                                                            formControlName="item_amount"
                                                            (keyup)="calculateTotalPurchaseCosts()"></wj-input-number>
                                                    </div>
                                                </div>
                                                <a (click)="removeProperty(i)"
                                                    class="squarebtn removeBlock btn btn-danger">
                                                    <i class="far fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="addProperty()" class="createAddBtn addOnBtn">
                                    <i class="far fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Total purchase costs <span class="required-error">*</span></label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number readonly disabled="" [isRequired]="false" format="n2"
                                        class="form-control1 ilsign-p" disabled="" type="number"
                                        formControlName="total_purchase_costs" id=""></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stephead-style1">
                        <span>Repair/Renovation Costs</span>
                    </div>
                    <div class="stepform-style1">
                        <div class="form-group">
                            <label for="">Roof </label>
                            <div class="flexwrap-box">
                                <div class="input-group">
                                    <wj-input-number [isRequired]="false" format="n2" class="form-control1  ilsign-p"
                                        (keyup)="updateTotalRepairRenovationAmount($event.target.value, 'roof_cost')"
                                        type="number" formControlName="roof_cost" id=""></wj-input-number>
                                    <span class="il-sign">$</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group Nofield">
                            <label for="">Additional Costs</label>
                            <div class="flexwrap-box repeatedCreateDesign">
                                <div class="form-group d-none d-lg-flex">
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="incomehead">
                                                    Item Name
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="incomehead">
                                                    Item Amount
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div formArrayName="renovationItems" class="repeatedCreate"
                                    *ngFor="let property of addPropertyStep3Form.get('renovationItems')['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="mb-3 position-relative mt-4 mt-lg-0 flotingsquare">
                                            <div class="row">
                                                <input placeholder="Enter Item Type" value="" class="form-control1"
                                                    type="hidden" formControlName="item_type">
                                                <div class="col-12 col-md-6 mb-2 mb-md-0">
                                                    <div class="incomehead d-lg-none">Item Name</div>
                                                    <div class="position-relative">
                                                        <input placeholder="Enter Item Name" value=""
                                                            class="form-control1" type="text"
                                                            formControlName="item_name">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2 mb-md-0">
                                                    <div class="incomehead d-lg-none">Item Amount</div>
                                                    <div class="position-relative">
                                                        <wj-input-number [isRequired]="false" format="n2"
                                                            placeholder="Enter Item Amount" value=""
                                                            class="view_proInputNum withOut_space" type="number"
                                                            formControlName="item_amount"
                                                            (keyup)="calculateTotalRepairRenovationAmount()">
                                                        </wj-input-number>
                                                    </div>
                                                </div>
                                                <a (click)="removeRenovationItem(i)"
                                                    class="squarebtn removeBlock btn btn-danger">
                                                    <i class="far fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="addRenovationItem()" class="createAddBtn addOnBtn">
                                    <i class="far fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="sub-amtblock">
                        <label>Total Repair/Renovation</label>
                        <div class="sub-ttamt" *ngIf="totalRepairRenovationAmount">${{totalRepairRenovationAmount |
                            number : '1.2-2'}} </div>
                    </div>
                    <div class="amtblock">
                        <label>Total Investment <span class="required-error">*</span></label>
                        <div class="ttamt" *ngIf="totalInvestmentAmount">${{totalInvestmentAmount | number : '1.2-2'}}
                        </div>
                    </div>
                    <!-- using only for BRRR Deal Type -->
                    <div *ngIf="dealTypeId === 2">
                        <div class="stephead-style1">
                            <span>Refinance Costs <span class="required-error">*</span></span>
                        </div>
                        <div class="stepform-style1">
                            <div class="form-group">
                                <label for="">Inspection <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="input-group">
                                        <wj-input-number [isRequired]="false" format="n2"
                                        class="form-control1 ilsign-p" formControlName="refinance_inspection" id=""
                                            (keyup)="updateTotalRefinanceCostAmount($event.target.value, 'refinance_inspection')"></wj-input-number>
                                        <span class="il-sign">$</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Appraisal <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="input-group">
                                        <wj-input-number [isRequired]="false" format="n2"
                                        class="form-control1 ilsign-p" formControlName="refinance_appraisal" id=""
                                            (keyup)="updateTotalRefinanceCostAmount($event.target.value, 'refinance_appraisal')"></wj-input-number>
                                        <span class="il-sign">$</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Attorney Fees <span class="required-error">*</span></label>
                                <div class="flexwrap-box">
                                    <div class="input-group">
                                        <wj-input-number [isRequired]="false" format="n2"
                                        class="form-control1 ilsign-p"
                                            formControlName="refinance_attorney_fees" id=""
                                            (keyup)="updateTotalRefinanceCostAmount($event.target.value, 'refinance_attorney_fees')"></wj-input-number>
                                        <span class="il-sign">$</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Additional Costs</label>
                                <div class="flexwrap-box repeatedCreateDesign">
                                    <div class="form-group d-none d-lg-flex">
                                        <div class="w-100">
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="incomehead">
                                                        Item Name
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="incomehead">
                                                        Item Amount
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div formArrayName="refinanceItems" class="repeatedCreate"
                                        *ngFor="let property of addPropertyStep3Form.get('refinanceItems')['controls']; let i = index;">
                                        <div [formGroupName]="i">
                                            <div class="mb-3 position-relative mt-4 mt-lg-0 flotingsquare">
                                                <div class="row">
                                                    <input placeholder="Enter Item Type" value=""
                                                        class="form-control1 ilsign-pl" type="hidden"
                                                        formControlName="item_type">
                                                        <div class="col-12 col-md-6 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Name </div>
                                                        <div class="position-relative">
                                                            <input placeholder="Enter Item Name" value=""
                                                                class="form-control1 ilsign-pl" type="text"
                                                                formControlName="item_name">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2 mb-md-0">
                                                        <div class="incomehead d-lg-none">Item Amount</div>
                                                        <div class="position-relative">
                                                            <wj-input-number [isRequired]="false" format="n2" placeholder="Enter Item Amount" value=""
                                                                class="view_proInputNum withOut_space" type="number"
                                                                formControlName="item_amount"
                                                                (keyup)="calculateTotalRefinanceCostAmount()"></wj-input-number>
                                                        </div>
                                                    </div>
                                                    <a (click)="removeRefinanceItem(i)"
                                                        class="squarebtn removeBlock btn btn-danger">
                                                        <i class="far fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" (click)="addRefinanceItem()" class="createAddBtn addOnBtn">
                                        <i class="far fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="sub-amtblock">
                            <label>Total Refinance Costs <span class="required-error">*</span></label>
                            <div class="sub-ttamt">${{totalRefinanceCostAmount | number : '1.2-2'}} </div>
                        </div>
                    </div>
                    <!-- End code for BRRR Deal Type -->
                </div>
            </div>
        </div>
    </form>
</div>