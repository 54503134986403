import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../_services/auth.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../_services/user.service';
import { CommonService } from '../../_services/common.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../_services/global.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

declare var showLogoutPopup: Function;
declare var hideLogoutPopup: Function;
declare var startLoader: Function;
declare var stopLoader: Function;
declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig],
})
export class NavbarComponent implements OnInit {
  SearchShow: boolean = false;
  menuShow: boolean = false;
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  public user;
  public environment = environment;
  subscription: Subscription;
  keyword = 'name';
  searchData: any = '';
  trial_start_date = '';
  trial_end_date = '';
  differenceDate: any = '';

  constructor(
    private _UserService: UserService,
    private globalservice: GlobalService,
    private _CommonService: CommonService,
    private _AuthService: AuthService,
    private datePipe: DatePipe,
    private router: Router,

    config: NgbDropdownConfig
  ) {
    config.placement = 'bottom-right';
    this.user = this._AuthService.getUserInfo();
    this.subscription = this.globalservice.refreshUserData.subscribe((res) => {
      this.user = this._AuthService.getUserInfo();
    });
  }

  ngOnInit() {
    this.getUserProfileDetails();
    console.log('this.user', this.user);
    if (this.user) {
      this.getsearch();
    }
  }

  async getUserProfileDetails() {
    var self = this;
    console.log('getUserProfileDetails');
    this._UserService
      .getUserProfile(this.user.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          console.log('response', response);
          if (response.status == 200) {
            let todayDate = new Date().toISOString().split('T')[0];
            console.log('todayDate', todayDate);
            // this.submitLastDate = this.datePipe.transform(this.submitLastDate, 'yyyy-MM-dd');
            // this.trial_start_date = this.convertDateFormat(response.data.trial_start_date);
            this.trial_end_date = this.convertDateFormat(
              response.data.trial_end_date
            );
            // console.log("trial_start_date", this.trial_start_date);
            console.log('trial_end_date', this.trial_end_date);
            if (todayDate && this.trial_end_date) {
              const a = new Date(todayDate),
                b = new Date(this.trial_end_date),
                difference = this.dateDiffInDays(a, b);
              console.log(difference + ' days');
              if (difference > 0) {
                self.differenceDate = difference;
              } else {
                self.differenceDate = 0;
              }
            }
          } else {
            checkTosterResponse(response.message, 'Error');
            return false;
          }
        },
        (error) => {}
      );
  }

  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  convertDateFormat(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  openLogoutPopup() {
    showLogoutPopup();
  }
  
  closeLogoutPopUp() {
    hideLogoutPopup();
  }

  public logout() {
    startLoader();
    this._UserService.logout();
    this.globalservice.refreshUserAuth.next(true);
    this.router.navigate(['/user/login']);
    stopLoader();
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if (
      !body.classList.contains('sidebar-toggle-display') &&
      !body.classList.contains('sidebar-absolute')
    ) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  SearchToggle() {
    this.SearchShow = !this.SearchShow;
  }

  menuToggle() {
    this.menuShow = !this.menuShow;
    let bodyParm = document.getElementsByTagName('body')[0];
    if (this.menuShow == true) {
      bodyParm.classList.add('openMenu');
    } else {
      bodyParm.classList.remove('openMenu');
    }
  }

  selectEvent(item) {
    console.log(item);
  }

  onChangeSearch(search: string) {
    this.getsearch();
  }

  getsearch() {
    let searchData = {
      user_id: this.user?.id,
    };
    this._CommonService.getSearchFilterData(searchData).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.searchData = response.data;
        }
      },
      (err) => {},
      () => ''
    );
  }

  onFocused(e) {
    //console.log(e);
  }
}
